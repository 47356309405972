import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./Account.scss";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import UpdateUserModal from "./UpdateUserModal";
import Swal from "sweetalert2";
import PurchaseHistory from "./PurchaseHistory";
import { useNavigate } from 'react-router-dom';


const Account = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("jwtTokenApi1");
  // console.log('isloggedin is ', isLoggedIn)
  const { transacationDetails, sendVerifyEmailLink } = useModalContext();
  const [userData, setUserData] = useState(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const handleOpenUpdateUserModal = () => {
    setUpdateUserModal(true);
  };
  if (!isLoggedIn) {
    // If not logged in, navigate to the sign-in page
    navigate('/signin');
  }
  // const getUserProfile = async () => {
  //   try {
  //     if (!userData) {
  //       const response = await axios.get(
  //         "https://backend.ir4u.info/api/v1/user/profile",
  //         {
  //           headers: {
  //             Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       setUserData(response.data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // useEffect(() => {
  //   console.log(userData);
  // }, [userData]);
  // useEffect(() => {
  //   (async () => {
  //     await getUserProfile();
  //   })();
  // }, [updateUserModal]);

  function formatUTCtoLocalTime(utcDateString) {
    const utcDate = new Date(utcDateString);

    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return localDate.toLocaleString("en-US", options);
  }

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const response = await axios.get(
          "https://backend.ir4u.info/api/v1/user/profile",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        console.log("User profile from my account", response.data);
        setUserData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUserProfile();
  }, [updateUserModal]);
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  return (
    <>
      <Header />
      <div className="ir-ws-gray-bg ir-ws-account-main-container">
        <div className="ir-ws-padding-lr-132 ir-ws-acc-title-container">
          <h2 className="ir-ws-heading-default-color ir-ws-account-title">
            My Profile
          </h2>
        </div>

        <div className="ir-ws-padding-lr-132 ir-ws-account-details-main-container">
          <h3 className="ir-ws-heading-default-color ir-ws-account-details-heading">
            Profile Details
          </h3>
          <div></div>
          <div className="ir-ws-account-details-inner-container">
            <div className="ir-ws-account-details-container">
              <p className="ir-default-color ir-ws-account-details-title">
                Username
              </p>
              <p className="ir-ws-heading-default-color ir-ws-account-details-content">
                {userData?.username}
              </p>
            </div>
            <div className="ir-ws-account-details-container">
              <p className="ir-default-color ir-ws-account-details-title">
                Name
              </p>
              <p className="ir-ws-heading-default-color ir-ws-account-details-content">
                {userData?.firstname} {userData?.lastname}
              </p>
            </div>
            <div className="ir-ws-account-details-container">
              <p className="ir-default-color ir-ws-account-details-title">
                Email
              </p>
              <p className="ir-ws-heading-default-color ir-ws-account-details-content">
                {userData?.email}
              </p>
            </div>
            {!isAdmin && userData !== null && (
              <div className="ir-ws-webinar-update-verify-container">
                <button
                  className="ir-ws-user-account-update-button"
                  onClick={handleOpenUpdateUserModal}
                >
                  Update Profile
                </button>

                {userData.isEmailVerified ? (
                  <p>Email Verified</p>
                ) : (
                  <button
                    className="ir-ws-app-color ir-ws-verify-email-text"
                    onClick={() => {
                      sendVerifyEmailLink(userData?.username);
                    }}
                  >
                    Verify Email
                  </button>
                )}
              </div>
            )}
          </div>
          <PurchaseHistory />
        </div>
      </div>

      <Footer />
      {updateUserModal && (
        <UpdateUserModal
          updateUserModal={updateUserModal}
          setUpdateUserModal={setUpdateUserModal}
        />
      )}
    </>
  );
};

export default Account;

import React, { useContext, useState, useRef, useEffect } from "react";
import "./ChatProfile.scss";
import { RootStore } from "../../../store/store";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
import ImageEditorModal from "./ImageEditorModal";
import axios from "../../../api/axios";
import AllVoters from "./AllVoters";
// import AllVoters from "./AllVoters";


const ChatProfile = ({ activeChatRoom, updateChatRoom, getChatHistory, chatData }) => {
  const [store, setStore] = useContext(RootStore);
  const { chatStore } = store;
  const { groups } = chatStore;
  const [storeChatHistory, setStoreChatHistory] = useState([])
  const [groupData, setGroupData] = useState({});
  const [openImageGalleryModal, setOpenImageGalleryModal] = useState(false);
  const [activeCaseName, setActiveCaseName] = useState("");
  const [nextButtonDisable, setNextButtonDisable] = useState(false);
  const [prevButtonDisable, setPreButtonDisable] = useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  const [groupUsersLoading, setGroupUsersLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const { isSearchOverlayOpen, toggleSearchOverlay, messageRefs, searchTerm, setSearchTerm, setHighlightedMessageId, setChatImageEditURL, setIsImageEditorOpen, toggleGroupInfoOverlay,
    isGroupInfoOverlayOpen, setIsGroupInfoOverlayOpen, isAllMediaOverlayOpen, setIsAllMediaOverlayOpen, toggleAllMediaOverlay, closeAllMediaOverlay, showAllVoters, setShowAllVoters, currentPollId, closeAllVoters, setCurrentPollId  } = useModalContext();
//   const [searchTerm, setSearchTerm] = useState("");
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [mediaMessages, setMediaMessages] = useState([]);
  const [documentMessages, setDocumentMessages] = useState([]);

  const [activeTab, setActiveTab] = useState("media");

  const [isAllMediaImageGalleryOpen, setIsAllMediaImageGalleryOpen] = useState(false);
  const [activeMediaIndex, setActiveMediaIndex] = useState(null);



//   const [isEditorOpen, setIsEditorOpen] = useState(false);



  const ref = useRef();


//   useEffect(() => {

//     const fetchChatHistory = async () => {
//       const history = await getChatHistory(groupData.id);
//       setChatHistory(history);
//       console.log("chat history from profile", history);
//     };

//     fetchChatHistory();
//   }, []);

useEffect(() => {
    setStoreChatHistory(chatStore.chatHistory[activeChatRoom])
}, [activeChatRoom, chatStore])

// console.log("storeChatHistory", storeChatHistory);


const handleMessageClick = (msgId) => {
    const messageRef = messageRefs[msgId];
    // console.log("Clicked");
    setHighlightedMessageId(msgId);
    if (messageRef && messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    setTimeout(() => setHighlightedMessageId(null), 3000);
  };


  const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
  const onCarouselMenuItemclick = (carouselActiveIndex) => {
    setCarouselActiveIndex(carouselActiveIndex);
  };

  const showLargeImage = () => {
    setOpenImageGalleryModal(true);
    console.log("chatprofile");
  };

  const closeImageGalleryModal = () => {
    setOpenImageGalleryModal(false);
  };

  const leftCarouselIndex = () => {
    if (carouselActiveIndex > 0) {
      setCarouselActiveIndex(carouselActiveIndex - 1);
    }
  };
  const rightCarouselIndex = () => {
    // const allImages = chatHistory[props.activeChatRoom].filter(i => i.images !== "").map(e => e.images)
    const allImages = groupData?.image?.map((e) => e.image);
    console.log("all images erwe", allImages);
    if (carouselActiveIndex < allImages.length - 1) {
      setCarouselActiveIndex(carouselActiveIndex + 1);
      // setNextButtonDisable(true);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);

  useEffect(() => {
    if (groups.length > 0) {
      let group = groups.find((e) => e.id === parseInt(activeChatRoom));
      setGroupData(group);
      console.log(" ", group)
      setActiveCaseName(group?.name);
    }
  }, [activeChatRoom, chatStore.groups]);

  useEffect(() => {
    let isPreviousDiabled = carouselActiveIndex <= 0 ? true : false;
    const allImages = groupData?.image?.map((e) => e.image) || [];
    let isNextDiabled =
      carouselActiveIndex >= allImages.length - 1 ? true : false;

    setPreButtonDisable(isPreviousDiabled);
    setNextButtonDisable(isNextDiabled);
  }, [carouselActiveIndex]);

  useEffect(() => {
    // Check if carouselActiveIndex is at the beginning or end to disable/enable buttons.
    setPreButtonDisable(carouselActiveIndex <= 0);
    setNextButtonDisable(
      carouselActiveIndex >= (groupData?.image?.length || 0) - 1
    );
  }, [carouselActiveIndex, groupData]);

  useEffect(() => {
    if (groupData?.id && chatData) {
        // console.log("chat data in profile:",groupData, chatData[activeChatRoom]);
      // Extract the messages for the active chat room from chatData
      const history = chatData[groupData.id] || [];
      console.log("Chat history from chatData for room ID", groupData.id, ":", history);

      setChatHistory(history);
    }
  }, [groupData, chatData]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  useEffect(() => {
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const filtered = chatHistory.filter(msg =>
        msg.message.toLowerCase().includes(lowerSearchTerm)
      );
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages([]);
    }
  }, [searchTerm, chatHistory]);

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;

    let highlighted = false; // Flag to check if the term has been highlighted
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));

    return parts.map((part, index) => {
      if (!highlighted && part.toLowerCase() === searchTerm.toLowerCase()) {
        highlighted = true; // Mark that we’ve highlighted once
        return (
          <span key={index} className="highlight">
            {part}
          </span>
        );
      }
      return part;
    });
  };

  useEffect(() => {
    const fetchGroupUsers = async () => {
        setGroupUsersLoading(true)
      try {
        const response = await axios({
          url: `/group/${activeChatRoom}/users`,
          method: "get",
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        });

        console.log("response", response.data);
        setGroupUsers(response.data);

        setGroupUsersLoading(false)

        if(groupUsers){
            console.log("response1",groupUsers);
        }
      } catch (error) {
        console.error("Error fetching group users:", error);
      }
    };

    if (isGroupInfoOverlayOpen && activeChatRoom) {
      fetchGroupUsers();
    }
  }, [isGroupInfoOverlayOpen, activeChatRoom]);


const openAllMediaImageGallery = (index) => {
    // console.log("Opening gallery for index:", index);
    setActiveMediaIndex(index);
    setIsAllMediaImageGalleryOpen(true);
    // console.log("Gallery Open State:", isAllMediaImageGalleryOpen);
    // console.log("Active Media Index:", activeMediaIndex);
  };

    const closeAllMediaImageGallery = () => {

        // Pause all videos
        const videoElements = document.querySelectorAll(".ir-car-img video");
        videoElements.forEach((video) => video.pause());

        setIsAllMediaImageGalleryOpen(false);
        setActiveMediaIndex(null);

        // console.log("Gallery Open State:", isAllMediaImageGalleryOpen);
    };


const prevMediaIndex = () => {
    // Pause current video
    const currentVideo = document.querySelector(
      `.iks-carousel-active video`
    );
    if (currentVideo) currentVideo.pause();

    setActiveMediaIndex((prev) =>
      prev === 0 ? mediaMessages.length - 1 : prev - 1
    );
  };

  const nextMediaIndex = () => {
    // Pause current video
    const currentVideo = document.querySelector(
      `.iks-carousel-active video`
    );
    if (currentVideo) currentVideo.pause();

    setActiveMediaIndex((prev) =>
      prev === mediaMessages.length - 1 ? 0 : prev + 1
    );
  };



//   const handleEditClick = (imageURL) => {
//     setChatImageEditURL(imageURL);
//     setIsImageEditorOpen(true);
//   };
//   const handleSaveEditedImage = (editedImageURL) => {
//     console.log("Edited image URL:", editedImageURL);
//     // Here you can add logic to send this edited image URL back to the chat
//   };

useEffect(() => {
    if (chatData && chatData[activeChatRoom]) {
      const activeRoomChats = chatData[activeChatRoom];

      // Filter for messages with media (images or videos)
      const media = activeRoomChats
      .filter(
        chat =>
          (chat.images && chat.images.trim() !== "") ||
          (chat.videos && chat.videos.trim() !== "")
      )
      .reverse();

      // Filter for messages with documents
      const documents = activeRoomChats
      .filter(chat => chat.documents && chat.documents.trim() !== "")
      .reverse();

      // Set states
      setMediaMessages(media);
      setDocumentMessages(documents);
      if(mediaMessages && documentMessages){
        // console.log("mediaMessages and documentMessages", mediaMessages, documentMessages);

      }
    }
  }, [chatData, activeChatRoom]);

  return (
    <div
      className="ir-chat-profile-main-container tab-content-scroll ps ps--active-y"
      ref={ref}
    >
         {isAllMediaImageGalleryOpen && (
        <div className="iks-mint-cust-modal ir-chat-inside-modal-container ir-image-preview-main-modal">
          <div className="iks-mint-modal-container">
            <div className="ir-thumb-image-inner-container">
              <div className="ir-image-large-thumb-title-container">
                <h3>Media Gallery</h3>
                <div className="ir-image-head-thumb-right">
                  <button onClick={closeAllMediaImageGallery}>
                    <i className="bi bi-x-lg"></i>
                  </button>
                </div>
              </div>
              <div className="ir-modal-thumb-container-large-image">
                {mediaMessages?.map((media, id) => (
                  <div
                    key={id}
                    className={`iks-cine-content iks-cine-content-${id} ${
                      id === activeMediaIndex
                        ? "iks-carousel-active"
                        : "iks-carousel-inactive"
                    }`}
                  >
                    {media?.images ? (
                      <img
                        className="ir-car-img"
                        src={media?.images}
                        alt={`Media ${id + 1}`}
                      />
                    ) : (
                      <video
                        className="ir-car-img"
                        src={media?.videos}
                        controls
                        // autoPlay
                        loop={false}
                      />
                    )}
                  </div>
                ))}

                <button
                  onClick={prevMediaIndex}
                  disabled={mediaMessages.length === 1}
                  className={`ir-left-car-btn ir-carousel-action-btns`}
                >
                  <i className="bi bi-chevron-left"></i>
                </button>
                <button
                  onClick={nextMediaIndex}
                  disabled={mediaMessages.length === 1}
                  className={`ir-right-car-btn ir-carousel-action-btns`}
                >
                  <i className="bi bi-chevron-right"></i>
                </button>
              </div>
              <div className="ir-thumbanil-modal-image">
                {mediaMessages?.map((media, id) => (
                  <div
                    key={id}
                    onClick={() => setActiveMediaIndex(id)}
                    className={`ir-car-item-container ${
                      id === activeMediaIndex
                        ? "iks-carousel-item-active"
                        : "iks-carousel-item-inactive"
                    }`}
                  >
                    {media?.images ? (
                      <img
                        className="ir-car-img"
                        src={media?.images}
                        alt={`Media ${id + 1}`}
                      />
                    ) : (
                      <video
                        className="ir-car-img"
                        src={media?.videos}
                        muted
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}


       {
        showAllVoters ? (
            <AllVoters chatHistory={storeChatHistory} selectedPollId={currentPollId} onClose={() => {
                setCurrentPollId(null);
                setShowAllVoters(false)
            }}
            />
          ):
       isGroupInfoOverlayOpen ? (
         <div className="group-info-overlay">
            <button type="button" class="close" aria-label="Close"  onClick={toggleGroupInfoOverlay}>
                    <span aria-hidden="true" className="close-button ">&times;</span>
                </button>
                <h4 className="groupInfo-title">Group info</h4>
            {/* Group Header with Name and Image */}
            <div className="group-header">
            <img
                src={groupData?.image[0] || require("../../../assets/images/chat-images/no-image.png")}
                alt={groupData?.name}
                className="group-header-image"
            />
            <h1 className="group-header-name">{groupData?.name}</h1>
            <p className="group-header-des">{groupData?.description}</p>
            </div>
{/*
            {
            mediaMessages && mediaMessages.length > 0 && (
                <div className="media-thumbnails-container">
                <h4>Media</h4>
                 <div className="media-content-wrapper">
                 <div className="thumbnails">
                    {mediaMessages.slice(0, 3).map((media, index) => (
                    <div key={index} className="thumbnail">
                        {media.images ? (
                        <img
                            src={media.images}
                            alt={`Media ${index + 1}`}
                            className="media-thumbnail"
                        />
                        ) : (
                        <video
                            src={media.videos}
                            className="media-thumbnail"
                            // controls
                        />
                        )}
                    </div>
                    ))}
                </div>
                <button
                    className="btn view-more-btn"
                    onClick={toggleAllMediaOverlay}
                >
                    View More ({mediaMessages.length})
                </button>
                 </div>
                </div>
            )
            } */}

<span>
        {groupUsersLoading ? (
          <div className="shimmer shimmer-text"></div>
        ) : (
          `${groupUsers?.userCount || 0} members`
        )}
      </span>
      {/* User List */}
      <div className="group-users-list">
        {groupUsersLoading ? (
          Array.from({ length: 5 }).map((_, i) => (
            // <div key={i} className="shimmer shimmer-user-item"></div>
            <div key={i} className="shimmer-user-item">
              <div className="shimmer shimmer-avatar"></div>
              <div className="shimmer shimmer-name"></div>
            </div>
          ))
        ) : groupUsers?.users?.length > 0 ? (
          groupUsers.users.map((user, i) => (
            <div key={i} className="user-item">
              <img
                src={
                  user?.userImage ||
                  `https://ui-avatars.com/api/?name=${encodeURIComponent(
                    user?.userName || "User"
                  )}&background=47bc94&color=FFFFFF&font-weight=800`
                }
                alt={user?.userName}
                className="user-avatar"
              />
              <span className="user-name">
                {user?.userName ? user?.userName : "Deleted user"}
              </span>
            </div>
          ))
        ) : (
          <p className="no-users">No users found in this group.</p>
        )}
      </div>
        </div>
          ):
          isAllMediaOverlayOpen ? (
        //* Working
        <div className="ir-md-overlay">
        <div className="ir-md-overlay-content">
          <button
            className="ir-md-close-btn"
            // onClick={() => setIsAllMediaOverlayOpen(false)}
            onClick={(e) => {
                e.stopPropagation();
                setIsAllMediaOverlayOpen(false);
              }}
            // onClick={closeAllMediaOverlay}
          >
            &times;
          </button>
          <div className="ir-md-tabs">
            <button
              className={`ir-md-tab-btn ${
                activeTab === 'media' ? 'ir-md-active-tab' : ''
              }`}
              onClick={() => setActiveTab('media')}
            >
              Media
            </button>
            <button
              className={`ir-md-tab-btn ${
                activeTab === 'documents' ? 'ir-md-active-tab' : ''
              }`}
              onClick={() => setActiveTab('documents')}
            >
              Documents
            </button>
          </div>
          <div className="ir-md-tab-content">
            {activeTab === 'media' && (
              <div className="ir-md-media-content">
                {mediaMessages?.length > 0 ? (
                  <div className="ir-md-media-thumbnails-container">
                    {mediaMessages?.map((media, index) => (
                      <div key={index} className="ir-md-thumbnail"
                      onClick={(e) => {
                        e.stopPropagation();
                        openAllMediaImageGallery(index);
                        // alert("clicked", index)
                        // console.log("clicked active image:", media.images || media.videos);
                      }}
                      >
                        {media?.images ? (
                          <img
                            src={media.images}
                            alt={`Media ${index + 1}`}
                            className="ir-md-media-thumbnail"
                            // onClick={() => openAllMediaImageGallery(index)}
                          />
                        ) : (
                          <div className="ir-md-video-thumbnail">
                            <video
                              src={media?.videos}
                              className="ir-md-media-thumbnail"
                            //   muted
                            //   loop
                            //   onClick={() => openAllMediaImageGallery(index)}
                            />
                            <div className="ir-md-play-icon">
                              <i className="bi bi-play-circle-fill"></i>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No media available</p>
                )}
              </div>
            )}
            {activeTab === 'documents' && (
              <div className="ir-md-document-content">
                {documentMessages?.length > 0 ? (
                  documentMessages?.map((doc, index) => (
                    <div key={index} className="ir-md-document-item">
                      <a href={doc.documents} download>
                        {doc.documents
                          ? doc.documents.split('/').pop()
                          : 'Unknown Document'}
                        <i className="bi bi-download"></i>
                      </a>
                    </div>
                  ))
                ) : (
                  <p>No documents available</p>
                )}
              </div>
            )}
          </div>
        </div>
       </div>

          ):(
        <>
        <div className="ir-car-update-btn-container" title="Update Case">
        <button
          className="btn btn-primary btn-lg btn-block btn-round-update"
          id="newChat"
          onClick={() => updateChatRoom(true)}
        >
          <i className="bi bi-pencil"></i>
        </button>
      </div>
      <div className="ir-chat-profile-inner-container">

        {groupData?.image?.map((image, id) => (
          <div
            key={id}
            className={`iks-cine-content iks-cine-content-${id} ${
              id === carouselActiveIndex
                ? "iks-carousel-active"
                : "iks-carousel-inactive"
            }`}
          >
            {/* <button className="chat-img-edit-btn" onClick={() => handleEditClick(image)}>Edit</button> */}
            <img
              className="ir-car-img"
              src={image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
              }}
              alt={"chat group"}
              onClick={() => showLargeImage(image)}
            />
          </div>
        ))}

{/* {isEditorOpen && (
        <ImageEditorModal
          isOpen={isEditorOpen}
          onClose={() => setIsEditorOpen(false)}
          onSave={handleSaveEditedImage}
        />
      )} */}

{/* {isEditorOpen && (
                <ImageEditorModal
                    isOpen={isEditorOpen}
                    onClose={() => setIsEditorOpen(false)}
                    onSave={handleSaveEditedImage}
                />
            )} */}

        <div className="ir-car-item-main-container">
          {groupData?.image?.map((image, id) => (
            <div key={id} className="ir-car-item-container">
              <img
                onClick={() => onCarouselMenuItemclick(id)}
                className={`ir-car-item-img ${
                  carouselActiveIndex === id ? "active-image-border" : ""
                }`}
                src={image}
                alt={"chat group"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
                }}
              />
            </div>
          ))}
        </div>
      </div>

      {openImageGalleryModal && (
        <div className="iks-mint-cust-modal ir-chat-inside-modal-container ir-image-preview-main-modal">
          <div className="iks-mint-modal-container">
            <div className="ir-thumb-image-inner-container">
              <div className="ir-image-large-thumb-title-container">
                <h3>{activeCaseName}</h3>
                <div className="ir-image-head-thumb-right">
                  <button onClick={closeImageGalleryModal}>
                    <i className="bi bi-x-lg"></i>
                  </button>
                </div>
              </div>
              <div className="ir-modal-thumb-container-large-image">
                {groupData?.image?.map((image, id) => (
                  <div
                    key={id}
                    className={`iks-cine-content iks-cine-content-${id} ${
                      id === carouselActiveIndex
                        ? "iks-carousel-active"
                        : "iks-carousel-inactive"
                    }`}
                  >
                    <img
                      className="ir-car-img"
                      src={image}
                      alt={"chat group"}
                    />
                  </div>
                ))}

                <button
                  onClick={leftCarouselIndex}
                  disabled={prevButtonDisable}
                  className={`ir-left-car-btn ir-carousel-action-btns`}
                >
                  <i className="bi bi-chevron-left"></i>
                </button>
                <button
                  onClick={rightCarouselIndex}
                  disabled={nextButtonDisable}
                  className={`ir-right-car-btn ir-carousel-action-btns`}
                >
                  <i className="bi bi-chevron-right"></i>
                </button>
              </div>
              <div className="ir-thumbanil-modal-image">
                {groupData?.image?.map((image, id) => (
                  <div
                    key={id}
                    onClick={() => onCarouselMenuItemclick(id)}
                    className={`ir-car-item-container ${
                      id === carouselActiveIndex
                        ? "iks-carousel-item-active"
                        : "iks-carousel-item-inactive"
                    }`}
                  >
                    <img
                      className="ir-car-img"
                      src={image}
                      alt={"chat group"}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

        </>
        )}



    </div>
  );
};

export default ChatProfile;

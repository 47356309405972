import React, { useState } from "react";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import ConfirmationModal from "../../Courses/AdminCreateCourseTab/ConfirmationModal";
import { useDispatch } from "react-redux";
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  addAllSections,
  addFormData,
  addSection,
  removeSection,
} from "../Store/CourseSlice";

const AdminCourseList = () => {
  const {
    courses,
    deleteCourseFunction,
    selectedCourseIds,
    setSelectedCourseIds,
    handleCreateCourse,
    setActiveTabCourse,
    handleCreatecourspage,
    createCourseOpen,
    isUpdateCourse,
    courseUpdateId,
    allCourseTransaction,
  } = useModalContext();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [filterTransaction, setFilteredTransactions] = useState([]);
  const [contentVisibleCourseId, setContentVisibleCourseId] = useState(null);
  const dispatch = useDispatch();

  const toggleConfirmationModal = (id) => {
    setCourseId(id);
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const handleDeleteConfirmation = async () => {
    if (courseId) {
      await deleteCourseFunction(courseId);
      setCourseId("");
      setOpenConfirmationModal(false);
    }
  };

  const toggleSelection = (courseId) => {
    if (selectedCourseIds.includes(courseId)) {
      setSelectedCourseIds(selectedCourseIds.filter((id) => id !== courseId));
    } else {
      setSelectedCourseIds([...selectedCourseIds, courseId]);
    }
  };

    const FIXED_SECRET_KEY =
      "0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef";
  
    const SECRET_KEY = CryptoJS.enc.Hex.parse(FIXED_SECRET_KEY);
    const ALGORITHM = "aes-256-cbc";
  
    const dataDecrypt = (data) => {
      console.log("this is data in func", data);
      const [iv, encrypted] = data.split(":");
      const ivBytes = CryptoJS.enc.Hex.parse(iv);
      const encryptedBytes = CryptoJS.enc.Hex.parse(encrypted);
      const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: encryptedBytes },
        SECRET_KEY,
        { iv: ivBytes }
      );
      const decryptedString = CryptoJS.enc.Utf8.stringify(decrypted);
      console.log("Decrypted string:", decryptedString);
      return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted));
    };

  const handleUpdateCourse = (courseId) => {
    setActiveTabCourse(1);
    handleCreatecourspage();
    isUpdateCourse(courseId);
    const url = `https://courses.ir4u.info/api/course/${courseId}`;

    axios?.get(url).then((res) => {
      const encryptedData = res.data.data;
      console.log("this is the encrypt data", encryptedData);
      const decryptedCourseData = dataDecrypt(encryptedData);
      const additionalData = {
        selectedImageName: "prevImage.png",
        selectedVideoName: "prevVidoe.mp4",
      }; 
      const formData = decryptedCourseData.formData;

      console.log('formdata', formData);

      const updatedFormData = { ...formData, ...additionalData };
      console.log('this is the updated from data', updatedFormData);
      dispatch(addFormData(updatedFormData));
      const sectionData = formData.sections;

      dispatch(removeSection());
      sectionData.forEach((section) => {
        dispatch(addAllSections(section));
      });
    });
  };

  const accordianListButton = [
    "Username",
    "Card",
    "Status of Transaction",
    "Tax Invoice Number",
    "Invoice",
  ];

  const toggleTransactionDropdown = (courseId) => {
    if (contentVisibleCourseId === courseId) {
      setContentVisibleCourseId(null);
      setFilteredTransactions([]);
    } else {
      const filteredTransactions = allCourseTransaction.filter(
        (transaction) => transaction.productID === courseId
      );
      const successTransactions = filteredTransactions.filter(
        (transaction) => transaction.status === "success"
      );
      console.log(successTransactions);
      setFilteredTransactions(successTransactions);
      setContentVisibleCourseId(courseId);
    }
  };
  console.log(courses);
  return (
    <>
      <div>
        {courses.map((course, index) => (
          <div className="ir-ws-webinar-list-tab" key={index}>
            <div className="ir-ws-admin-webinar-content">
              <div className="ir-ws-admin-webinar-title-container">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id={`checkbox1${course._id}`}
                    className="checkbox__input"
                    onChange={() => toggleSelection(course._id)}
                    checked={selectedCourseIds.includes(course._id)}
                  />
                  <label
                    htmlFor={`checkbox1${course._id}`}
                    className="checkbox__label"
                  ></label>
                </div>
                <img
                  src={course.formData.imagePreview}
                  className="ir-ws-course-list-image"
                  alt="course"
                />
                <p className="ir-ws-webinar-title-text">
                  {course.formData.title}
                </p>
              </div>
              <div className="ir-ws-admin-list-button-container">
                <button
                  onClick={() => handleUpdateCourse(course._id)}
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                >
                  Update
                </button>
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => toggleConfirmationModal(course._id)}
                >
                  Delete
                </button>
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => toggleTransactionDropdown(course._id)}
                >
                  Transactions
                  <span style={{ marginLeft: "4px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="ir-ws-admin-drop-down-icon"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            {contentVisibleCourseId === course._id && (
              <div>
                {filterTransaction.length !== 0 && (
                  <ul className="ir-ws-webinar-list-accordian-tab">
                    {accordianListButton.map((item, index) => (
                      <li className="ir-ws-list-item-box" key={index}>
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
                <div>
                  {filterTransaction.length === 0 ? (
                    <ul className="ir-ws-webinar-list-data">
                      <li>No purchase history</li>
                    </ul>
                  ) : (
                    filterTransaction.map((item, index) => (
                      <ul className="ir-ws-webinar-list-data" key={index}>
                        <li>{item?.username}</li>
                        <li>{item.productPaymentDetails?.paymentMode}</li>
                        <li>{item?.status}</li>
                        <li>{item?.transactionID}</li>
                        <li>
                          <a
                            href={item?.invoiceUrl}
                            className="ir-ws-webinar-inovice-icon"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="ir-ws-webinar-download-icon"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
        {openConfirmationModal && (
          <ConfirmationModal
            toggleModal={toggleConfirmationModal}
            confirmationFunc={handleDeleteConfirmation}
            title="delete"
          />
        )}
      </div>
    </>
  );
};

export default AdminCourseList;

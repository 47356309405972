import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Header.scss";
import axios from "axios";

const Header = ({ isDarkMode }) => {
  const [scrollPage, setScrollPage] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [loggedInUsername, setLoggedInUsername] = useState("");
  const [registeredUsername, setRegisteredUsername] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [showDropDown, setshowDropDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [mobileNavMenu, setMobileNavMenu] = useState(false);

  const dropdownRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  // Check if the current path matches "/case/:id"
  const caseIdPattern = /^\/case\/[a-zA-Z0-9]+$/;
  const isCaseUrl = caseIdPattern.test(location.pathname);

  const menuItems = [
    {
      id: 1,
      menuItem: "Home",
      link: "/",
    },
    {
      id: 2,
      menuItem: "Chat",
      link: "/chat-room",
    },
    {
      id: 3,
      menuItem: "Courses",
      link: "/courses",
    },
    {
      id: 4,
      menuItem: "Webinars",
      link: "/webinar",
    },
    {
      id: 5,
      menuItem: "Cases",
      link: "/cases",
    },
  ];

  // const location = useLocation();

  const showDropDownClick = () => {
    console.log("showDropdown", showDropDown);
    setshowDropDown(!showDropDown);
  };

  const navigateToAccount = () => {
    navigate("/myprofile");
  };

  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        console.log("User profile from header", response);
        if (response.data?.isEmailVerified) {
          setIsEmailVerified(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    userProfileDetails();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollPage(window.scrollY > 50);
    });

    const jwtToken = localStorage.getItem("jwtTokenApi1");
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    const storedIsRegistered = localStorage.getItem("isRegistered");

    if (jwtToken) {
      setIsLoggedIn(true);
    }

    const storedLoggedInUsername = localStorage.getItem("loggedInUsername");
    const storedRegisteredUsername = localStorage.getItem("registeredUsername");

    if (storedLoggedInUsername) {
      setLoggedInUsername(storedLoggedInUsername);
    }

    if (storedRegisteredUsername) {
      setRegisteredUsername(storedRegisteredUsername);
    }

    if (storedIsLoggedIn === "true") {
      setIsLoggedIn(true);
    }
    if (storedIsRegistered === "true") {
      setIsRegistered(true);
    }
  }, []);

  const handleLogout = () => {
    //* Remove the token
    localStorage.removeItem("jwtTokenApi1");
    localStorage.removeItem("loggedInUsername");
    localStorage.removeItem("registeredUsername");
    setIsLoggedIn(false);
    setIsRegistered(false);
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("isRegistered", false);
    localStorage.removeItem("prevURL");
    navigate("/signin");
  };

  const navMenuMobileHandle = () => {
    setMobileNavMenu(!mobileNavMenu);
  };

  const navigateLogin = () => {
    navigate("/signin");
  };

  const navigateRegister = () => {
    navigate("/signup");
  };

  const navigateToAdmin = () => {
    const baseUrl = window.location.origin;
    const adminUrl = baseUrl + "/admin";
    window.location.replace(adminUrl);
  };
  const navigateToProfile = () => {
    const baseUrl = window.location.origin;
    const myprofileUrl = baseUrl + "/myprofile";
    window.location.replace(myprofileUrl);
  };

  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  return (
    <div
      className={`ir-ws-position-fixed ir-ws-width-100 ir-ws-nav-main-container ${
        scrollPage ? "ir-bg-white ir-ws-nav-bg" : "ir-bg-white"
      } ${isDarkMode && isCaseUrl ? "dark-mode" : ""}`}
    >
      <div className="ir-ws-flex ir-ws-align-center ir-ws-padding-lr-132 ir-ws-justify-space-btw ir-ws-nav-inner-container">
        <div className="ir-ws-logo-container">
          <a href="/">
            <img src={require("../../assets/images/ir4u2.png")} alt="logo" />
          </a>
        </div>
        <div
          className={`ir-ws-nav-check ${
            mobileNavMenu ? "ir-ws-nav-check-label" : ""
          }`}
          onClick={navMenuMobileHandle}
        >
          <span
            className={`${mobileNavMenu ? "ir-ws-nav-check-label-span" : ""}`}
          ></span>
          <span
            className={`${mobileNavMenu ? "ir-ws-nav-check-label-span" : ""}`}
          ></span>
          <span
            className={`${mobileNavMenu ? "ir-ws-nav-check-label-span" : ""}`}
          ></span>
        </div>
        <div
          className={`ir-ws-nav-menu-list-container ${
            mobileNavMenu
              ? "ir-ws-nav-menu-mobile-block ir-ws-nav-menu-mobile-list-container"
              : ""
          }`}
        >
          <ul
            className={`ir-margin-0 ir-ws-flex ir-ws-align-center ir-ws-list-style-none ir-ws-nav-menu-ul`}
          >
            {menuItems.map((item, id) => {
              const isActive =
                location.pathname === item.link ||
                (item.link !== "/" &&
                  location.pathname.startsWith(item.link)) ||
                (item.link === "/cases" &&
                  location.pathname.startsWith("/case"));

              return (
                <li
                  key={id}
                  className={`ir-ws-position-relative ir-ws-nav-menu-list-item`}
                >
                  <Link
                    to={item.link}
                    className={`ir-ws-position-relative ir-txt-decrtn-none ir-default-color ir-ws-nav-menu-link ${
                      isActive ? "active" : ""
                    }`}
                  >
                    {item.menuItem}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div
          className={`ir-ws-nav-sign-buttons ${
            mobileNavMenu
              ? "ir-ws-nav-menu-mobile-block ir-ws-nav-menu-mobile-sign-buttons"
              : ""
          }`}
        >
          <ul className="ir-margin-0 ir-ws-flex ir-ws-align-center ir-ws-list-style-none ir-ws-nav-btn-ul">
            {isLoggedIn || isRegistered ? (
              <li>
                <div className="ir-ws-flex ir-ws-align-center ir-default-color ir-ws-user-profile">
                  <div className="ir-ws-verify-container">
                    {!isEmailVerified && !isAdmin && !isLoading && (
                      <span class="material-symbols-outlined">exclamation</span>
                    )}
                    <i className="bi bi-person-circle"></i>
                  </div>
                  <h4 className="ir-ws-logged-username">
                    {loggedInUsername || registeredUsername}
                  </h4>
                  <div className="ir-ws-dropdown" onClick={showDropDownClick}>
                    <i className="bi bi-caret-down-fill"></i>
                  </div>
                </div>
                {/* {showDropDown && ( */}
                <div
                  ref={dropdownRef}
                  className={`ir-ws-dropdown-content ${
                    showDropDown ? "ir-ws-show-dropdown-menu" : ""
                  }`}
                >
                  {!isAdmin ? (
                    <div
                      className="ir-ws-flex ir-ws-align-center ir-ws-dropdown-item"
                      onClick={() => {
                        navigateToProfile();
                      }}
                    >
                      <span className="bi bi-person"></span>
                      My Profile
                    </div>
                  ) : (
                    <div
                      className="ir-ws-flex ir-ws-align-center ir-ws-dropdown-item"
                      onClick={() => {
                        // navigate("/admin");
                        // window.location.reload();
                        navigateToAdmin();
                      }}
                    >
                      <span class="material-symbols-outlined">browse</span>
                      Dashboard
                    </div>
                  )}
                  <div
                    className="ir-ws-flex ir-ws-align-center ir-ws-dropdown-item"
                    onClick={handleLogout}
                  >
                    <span className="material-symbols-outlined">logout</span>
                    Logout
                  </div>
                </div>
                {/* )} */}
              </li>
            ) : (
              <>
                <li>
                  <button
                    className="ir-ws-btn ir-ws-no-bg ir-ws-no-border ir-ws-app-color ir-login-btn"
                    onClick={navigateLogin}
                  >
                    Login
                  </button>
                </li>
                <li>
                  <button
                    className="ir-ws-btn ir-ws-app-bg ir-ws-no-border ir-color-white ir-ws-join-btn"
                    onClick={navigateRegister}
                  >
                    Join Us
                    <img
                      src={
                        require("../../assets/images/right-arrow.svg").default
                      }
                      alt="login-arrow"
                    />
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      {mobileNavMenu && (
        <div className={`ir-ws-nav-menu-mobile-block`}>
          <div className="ir-ws-nav-menu-mobile-black-bg">
            <ul className="ir-margin-0 ir-ws-list-style-none ir-ws-nav-btn-ul">
              {isLoggedIn || isRegistered ? (
                <li>
                  <div className="ir-ws-flex ir-ws-align-center ir-default-color ir-ws-user-profile">
                    <i className="bi bi-person-circle"></i>
                    <h4 className="ir-ws-logged-username">
                      {loggedInUsername || registeredUsername}
                    </h4>
                    <div className="ir-ws-dropdown" onClick={showDropDownClick}>
                      <i className="bi bi-caret-down-fill"></i>
                    </div>
                  </div>
                  {/* {showDropDown && ( */}
                  <div
                    ref={dropdownRef}
                    className={`ir-ws-dropdown-content ${
                      showDropDown ? "ir-ws-show-dropdown-menu" : ""
                    }`}
                  >
                    {!isAdmin ? (
                      <div
                        className="ir-ws-flex ir-ws-align-center ir-ws-dropdown-item"
                        onClick={() => {
                          navigateToProfile();
                        }}
                      >
                        <span className="bi bi-person"></span>
                        My Profile
                      </div>
                    ) : (
                      <div
                        className="ir-ws-flex ir-ws-align-center ir-ws-dropdown-item"
                        onClick={() => {
                          // navigate("/admin");
                          // window.location.reload();
                          navigateToAdmin();
                        }}
                      >
                        <span class="material-symbols-outlined">browse</span>
                        Dashboard
                      </div>
                    )}
                    <div
                      className="ir-ws-flex ir-ws-align-center ir-ws-dropdown-item"
                      onClick={handleLogout}
                    >
                      <span className="material-symbols-outlined">logout</span>
                      Logout
                    </div>
                  </div>
                  {/* )} */}
                </li>
              ) : (
                <>
                  <li>
                    <button
                      className="ir-ws-btn ir-ws-no-bg ir-ws-no-border ir-ws-app-color ir-login-btn"
                      onClick={navigateLogin}
                    >
                      Login
                    </button>
                  </li>
                  <li>
                    <button
                      className="ir-ws-btn ir-ws-app-bg ir-ws-no-border ir-color-white ir-ws-join-btn"
                      onClick={navigateRegister}
                    >
                      Join Us
                      <img
                        src={
                          require("../../assets/images/right-arrow.svg").default
                        }
                        alt="login-arrow"
                      />
                    </button>
                  </li>
                </>
              )}
            </ul>
            <ul
              className={`ir-margin-0 ir-ws-list-style-none ir-ws-nav-menu-ul`}
            >
              {menuItems.map((item, id) => {
                return (
                  <li
                    key={id}
                    className={`ir-ws-position-relative ir-ws-nav-menu-list-item`}
                  >
                    <a
                      href={item.link}
                      className="ir-ws-position-relative ir-txt-decrtn-none ir-default-color ir-ws-nav-menu-link"
                    >
                      {item.menuItem}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./VideoProcessingLoader.scss";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Header from "../../../../components/Header/Header";
import { useContext } from "react";
import { useModalContext } from "../../../../components/WebinarAdminCreateWebinarTab/ModalContext";

const VideoProcessingLoader = ({ uploadingProgress, processedVideo }) => {
  const allSections = useSelector((state) => state.course.sections);
  const [updateStatus, setUpdateStatus] = useState("video is processing..")
  // console.log('all sections are', allSections)
  const allformdata = useSelector((state) => state.course.formData);
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const { courseUpdateId } = useModalContext()
  const formattedFormData = {
    formData: {
      title: allformdata.title,
      subtitle: allformdata.subtitle,
      description: allformdata.description,
      coursePrice: allformdata.coursePrice,
      taught: allformdata.taught,
      totalDuration: allformdata.totalDuration,
      basicInfo: {
        language: allformdata.basicInfo.language,
        level: allformdata.basicInfo.level,
        category: allformdata.basicInfo.category,
      },
      learnPointArray: allformdata.learnPointArray.map((item) => item),
      courseIncludesArray: allformdata.courseIncludesArray.map(
        (item) => item
      ),
      requirementsArray: allformdata.requirementsArray.map((item) => item),
      completeDescription: allformdata.completeDescription,
      instructorName: allformdata.instructorName,
      instructorProfile: allformdata.instructorProfile,
      imagePreview: allformdata.imageprevUrl,
      videoPreview: allformdata.videoprevUrl,
      sections: allSections.map((section) => ({
        id: section.id,
        sectionNumber: section.sectionNumber,
        content: section.content,
        lectures: section.lectures.map((lecture) => ({
          id: lecture.id,
          content: lecture.content,
          lectureNumber: lecture.lectureNumber,
          video: {
            videoName: lecture.video.videoName,
            videoUrl: lecture.video.videoUrl,
            duration: lecture.video.duration
          },
          document: {
            docName: lecture.document.docName,
            docUrl: lecture.document.docUrl,
          },
        })),
      })),
    },
  };
  const handleUploadCourse = () => {
    if(courseUpdateId){
      console.log('the course is updating');
      const updateUrl = `https://courses.ir4u.info/api/course/update/${courseUpdateId}`
      if (uploadingProgress === 100 && processedVideo === 100) {
        axios.put(updateUrl, formattedFormData)
        .then((res)=>{
          console.log(res);
          navigate(`/course/${courseUpdateId}`);
        })
      }
    }else{

    console.log('we are creating new course');
    const postUrl = "https://courses.ir4u.info/api/course/add";
    if (uploadingProgress === 100 && processedVideo === 100) {
      console.log("stated posting");
      axios
        .post(postUrl, formattedFormData)
        .then((response) => {
          console.log("Response:", response);
          const id = response.data.id;
          console.log(id);
          setId(id);
          navigate(`/course/${id}`);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }
  }
  useEffect(()=>{
  if(uploadingProgress === 100){
    setUpdateStatus("It's done!")
  }
  },[uploadingProgress])
  // useEffect(() => {
  //   const postUrl = "https://courses.ir4u.info/api/course/add";
  //   // const postUrl = 'http://localhost:3000/api/course/add'
  //   if (uploadingProgress === 100 && processedVideo === 100) {
  //     console.log("stated posting");
  //     //  console.log(formattedFormData)
  //     axios
  //       .post(postUrl, formattedFormData)
  //       .then((response) => {
  //         console.log("Response:", response);
  //         const id = response.data.id;
  //         console.log(id);
  //         setId(id);
  //         // navigate(`/course/${id}`);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   }
  // }, [uploadingProgress, processedVideo]);

  const uploadBorderColors = {
    borderTopColor: uploadingProgress >= 10 ? "#6842ef" : "#ffff",
    borderRightColor: uploadingProgress >= 40 ? "#6842ef" : "#ffff",
    borderBottomColor: uploadingProgress >= 70 ? "#6842ef" : "#ffff",
    borderLeftColor: uploadingProgress >= 100 ? "#6842ef" : "#ffff",
    transform: "rotate(45deg)",
  };

  const processingBorderColors = {
    borderTopColor: processedVideo >= 10 ? "#6842ef" : "#ffff",
    borderRightColor: processedVideo >= 40 ? "#6842ef" : "#ffff",
    borderBottomColor: processedVideo >= 70 ? "#6842ef" : "#ffff",
    borderLeftColor: processedVideo >= 100 ? "#6842ef" : "#ffff",
    transform: "rotate(45deg)",
  };

  return (
    <div>
      <Header />
      <section className="ir-course-preview-page-section ir-course-preview-loader-section">
        {uploadingProgress < 100 ? (
          <div className="ir-course-preview-loader-container">
            <p className="ir-course-preview-loader-status-text">Status</p>
            <p className="ir-course-preview-loader-converting-text">
              Uploading
            </p>
            <div
              className="ir-course-preview-loader-box"
              style={uploadBorderColors}
            >
              <p
                className="ir-course-preview-upload-status-text"
                style={{ transform: "rotate(315deg)" }}
              >
                {uploadingProgress}%
              </p>
            </div>

            <p className="ir-course-preview-loader-time-remaining-text">
              {updateStatus}
            </p>
            <div className="ir-ws-text-center ir-ws-default-btn-container">
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                style={
                  processedVideo !== 100 || uploadingProgress !== 100
                    ? { pointerEvents: "none" }
                    : null
                }
                disabled={processedVideo !== 100 || uploadingProgress !== 100}
              >
                <span>Go to courses</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="ir-course-preview-loader-container">
            <p className="ir-course-preview-loader-status-text">Status</p>
            <p className="ir-course-preview-loader-converting-text">
              Processing
            </p>
            <div
              className="ir-course-preview-loader-box"
              style={processingBorderColors}
            >
              <p
                className="ir-course-preview-upload-status-text"
                style={{ transform: "rotate(315deg)" }}
              >
                {processedVideo}%
              </p>
            </div>
            <p className="ir-course-preview-loader-time-remaining-text">
             {updateStatus}
            </p>
            <div
              className="ir-ws-text-center ir-ws-default-btn-container "
              onClick={handleUploadCourse}
              // onClick={() => {
              //   console.log(courseUpdateId)
              //   console.log(formattedFormData)
              // }}

            >
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                disabled={uploadingProgress !== 100}
              >
                <span>Go to courses</span>
              </button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default VideoProcessingLoader;

import React, { useState, useContext, useRef, useEffect } from "react";

import RecordRTC from "recordrtc";
import EmojiPicker from "emoji-picker-react";

import { RootStore } from "../../../store/store";
import { usePaymentContext } from "../../../context/PaymentContext";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { useDropzone } from "react-dropzone";
import { fetchFile } from "@ffmpeg/util";
import Nouislider from "nouislider-react";
import "nouislider/dist/nouislider.css";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import "./ChatContent.scss";
import moment from "moment";
import { useParams } from "react-router-dom";
import AssetRender from "./assetRender";
import axios, { BaseURL } from "../../../api/axios";
import WhiteBoard from "./WhiteBoard";
import NoImage from "../../../assets/images/chat-images/no-image.png";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import LoadingImage from "../../../assets/images/chat-images/loading.svg";
import DeleteConfirmation from "./DeleteConfirmation";
import Progressbar from "./Progressbar";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
import ImageEditorModal from "../ChatProfile/ImageEditorModal";
import WebSocketService from "../../../webSocketService";
import Swal from "sweetalert2";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import MiniAssetRender from "./MiniAssetRender";

const mimeType = "video/webm";

const ChatContent = (props) => {
  const routeParams = useParams();
  const [store, setStore] = useContext(RootStore);
  const { chatStore, userStore, socketConnection } = store;
  const { chatHistory, groups } = chatStore;
  const { profile } = userStore;

  //   console.log("profile",profile);

  const {
    connectToWebSocket,
    sendMessage,
    sendReactionToMessage,
    deleteMessage,
    closeWebSocket,
  } = WebSocketService();

  const {
    isSearchOverlayOpen,
    setIsSearchOverlayOpen,
    toggleSearchOverlay,
    setMessageRefs,
    highlightedMessageId,
    chatImageEditURL,
    isImageEditorOpen,
    setIsImageEditorOpen,
    chatImageEditedResult,
    setChatImageEditURL,
    toggleGroupInfoOverlay,
    isGroupInfoOverlayOpen,
    setIsGroupInfoOverlayOpen,
    toggleAllMediaOverlay,
    searchTerm,
    setSearchTerm,
    messageRefs,
    setHighlightedMessageId,
    closeAllVoters,
    isScrollToBottom,
    setIsScrollToBottom,
  } = useModalContext();

  const [activeChatGroupData, setActiveChatGroupData] = useState(null);
  const { connection, ioState } = socketConnection;
  const [tooltipText, setTooltipText] = useState(false);
  const [progress, setProgress] = useState(0);
  const [shareModal, setShareModal] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [addAttachActive, setAddAttachActive] = useState(false);
  const [openCamModal, setOpenCamModal] = useState(false);
  const [openWhiteBoardModal, setOpenWhiteBoardModal] = useState(false);
  const [activeCaseName, setActiveCaseName] = useState("");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openAttachDocsModal, setOpenAttachDocsModal] = useState(false);
  const [openImageGalleryModal, setOpenImageGalleryModal] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [selectedImage, setSelectedImage] = useState([] || {});
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [sendingImg, setSendingImg] = useState(false);
  // const [isTyping, setIsTyping] = useState(false);
  // const [typingMessage, setTypingMessage] = useState({});
  const [permission, setPermission] = useState(false);
  const [nextButtonDisable, setNextButtonDisable] = useState(true);
  const [prevButtonDisable, setPreButtonDisable] = useState(true);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
  const [disableUploadBtn, setDisableUploadBtn] = useState(false);
  const [convertedVideoURL, setConvertedVideoURL] = useState(null);

  const [uploaded, setUploaded] = useState(null);
  const mediaRecorder = useRef(null);
  const liveVideoFeedRef = useRef(null);

  const [activeRooms, setActiveRooms] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [onlyAchive, setOnlyAchive] = useState(false);

  const [textareaHeight, setTextareaHeight] = useState(50);
  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [isTrimming, setIsTrimming] = useState(false);
  const [trimRange, setTrimRange] = useState([0, 10]);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isTrimmedVideoConverted, setIsTrimmedVideoConverted] = useState(null);

  const [audioPermission, setAudioPermission] = useState(false);
  const [audioRecordingStatus, setAudioRecordingStatus] = useState("inactive");
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAudioModal, setOpenAudioModal] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([""]);
  const [duplicateError, setDuplicateError] = useState(null);
  const [isViewMoreMenuOpen, setIsViewMoreMenuOpen] = useState(false);
  const [isSearchMessageByUsernamePopop, setIsSearchMessageByUsernamePopop] =
    useState(false);
  const [groupUsers, setGroupUsers] = useState([]);
  const [filteredUser, setFilteredUser] = useState(null);
  const [filterByQuestion, setFilterByQuestion] = useState(false);
  const [savedEditedImage, setSavedEditedImage] = useState(null);
  const [showSavedEditedImage, setShowSavedEditedImage] = useState(false);

  const [clinicalNote, setClinicalNote] = useState("");

  const menuRef = useRef(null);

  const mediaRecorderRef = useRef(null);
  const liveAudioFeedRef = useRef(null);
  const audioChunks = useRef([]);

  const [filteredMessages, setFilteredMessages] = useState([]);

  const [showMessageMenu, setShowMessageMenu] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(null);

  const [showReactionsPopup, setShowReactionsPopup] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState("All");
  const [reactionsData, setReactionsData] = useState([]);
  const [currentPopupChatId, setCurrentPopupChatId] = useState(null);

  const [isQuestionPopupVisible, setIsQuestionPopupVisible] = useState(false);

  const [pinnedPrimary, setPinnedPrimary] = useState(null);
  const [primaryMessages, setPrimaryMessages] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [isDetailsVisible, setDetailsVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 1;

  const [synopsisData, setSynopsisData] = useState(null);
  const [isSynopsisVisible, setIsSynopsisVisible] = useState(false);

  const pinnedMessageRef = useRef({});

  const [replyingMessage, setReplyingMessage] = useState(null);
  const inputRef = useRef(null);
  const handleReplyClick = (message) => {
    console.log("clicked", message);
    setReplyingMessage(message);
    inputRef.current?.focus();
    toggleMessageMenu(message.chatId)
  };


  const cancelReply = () => {
    setReplyingMessage(null);
  };


  // console.log("this is a primary message:", primaryMessages);
  // console.log("this is a pinned primary message:", pinnedPrimary);

  const [questionDetails, setQuestionDetails] = useState({
    questionText: "",
    questionImage: null,
    questionImagePreview: null,
    isPrimary: false,
  });

  const toggleQuestionPopupVisibility = () => {
    setIsQuestionPopupVisible(!isQuestionPopupVisible);
    setQuestionDetails({
      questionText: "",
      questionImage: null,
      isPrimary: false,
    });
  };

  const handleQuestionInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setQuestionDetails({
      ...questionDetails,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleQuestionImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setQuestionDetails({
        ...questionDetails,
        // Store the actual file object
        questionImage: file,
        // Store the preview URL
        questionImagePreview: imageURL,
      });
    }
  };

  const handleImageRemove = () => {
    setQuestionDetails({
      ...questionDetails,
      questionImage: null,
      questionImagePreview: null,
    });
  };

  const handleQuestionSubmit = async () => {
    if (!questionDetails.questionText.trim()) {
      alert("Question text is required!");
      return;
    }

    // Check if there is an image to upload
    let uploadedImageURL = "";
    if (questionDetails.questionImage) {
      try {
        const fd = new FormData();
        const uniqueName = `image_${Date.now()}_${Math.floor(
          Math.random() * 1000000
        )}.jpg`;
        fd.append("uploadFile", questionDetails.questionImage, uniqueName);
        fd.append("location", "questions");

        const response = await axios({
          url: "uploadfile",
          method: "post",
          data: fd,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        });

        if (response.data.data && response.data.data[0]) {
          uploadedImageURL = response.data.data[0];
        } else {
          alert("Failed to upload the image!");
          return;
        }
      } catch (error) {
        console.error("Image upload failed:", error);
        alert("Image upload failed!");
        return;
      }
    }

    // Construct the question data object
    const questionData = {
      message: questionDetails.questionText.trim(),
      // options: ["Option 1", "Option 2"],
      images: uploadedImageURL ? [uploadedImageURL] : [],
      isPrimary: questionDetails.isPrimary,
    };

    console.log("questionData:", questionData);

    // Construct the WebSocket payload
    const messagePayload = {
      userName: profile?.username || "",
      firstName: profile?.firstname || "",
      lastName: profile?.lastname || "",
      MessageType: "question",
      question: questionData,
      userImage: profile?.userImage || "",
    };

    console.log("Question Message Payload:", messagePayload);

    if (profile?.id) {
      sendMessage(messagePayload);
      setIsScrollToBottom(true);
      toggleQuestionPopupVisibility();
      setQuestionDetails({
        questionText: "",
        questionImage: null,
        isPrimary: false,
      });
    }
  };

  useEffect(() => {
    const chatRoomHistory = chatHistory?.[props?.activeChatRoom] || [];

    // Filter for primary questions
    const primaryList = chatRoomHistory
      .filter(
        (item) => item.messageType === "question" && item?.question?.isPrimary
      )
      .map((item) => ({
        ...item,
        displayMessage: item?.question?.message || item?.message || "",
      }));

    setPrimaryMessages(primaryList);

    if (primaryList.length > 0) {
      // Pin the latest primary question
      const latestPrimary = primaryList.reduce((latest, current) =>
        new Date(current?.createdOn) > new Date(latest?.createdOn)
          ? current
          : latest
      );
      setPinnedPrimary(latestPrimary);
    } else {
      // No primary questions, pin the latest non-primary question
      const latestNonPrimary = chatRoomHistory
        .filter(
          (item) =>
            item.messageType === "question" && !item?.question?.isPrimary
        ) // Non-primary questions
        .reduce(
          (latest, current) =>
            !latest ||
            new Date(current?.createdOn) > new Date(latest?.createdOn)
              ? current
              : latest,
          null
        );

      if (latestNonPrimary) {
        setPinnedPrimary({
          ...latestNonPrimary,
          displayMessage:
            latestNonPrimary?.question?.message ||
            latestNonPrimary?.message ||
            "",
        });
      } else {
        setPinnedPrimary(null); // No questions to pin
      }
    }
  }, [chatHistory, props.activeChatRoom]);

  useEffect(() => {
    const chatRoomHistory = chatHistory?.[props?.activeChatRoom] || [];

    // Sort and filter primary messages
    const sortedPrimaryMessages = primaryMessages
      .slice()
      .sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn));

    // Get the latest 5 primary questions
    const latestPrimaryMessages = sortedPrimaryMessages.slice(0, 5);

    // Fill remaining slots with non-primary questions
    let additionalMessages = [];
    if (latestPrimaryMessages.length < 5) {
      const remainingCount = 5 - latestPrimaryMessages.length;
      additionalMessages = chatRoomHistory
        .filter(
          (item) =>
            item.messageType === "question" && !item?.question?.isPrimary
        ) // Non-primary questions
        .sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn))
        .slice(0, remainingCount)
        .map((item) => ({
          ...item,
          displayMessage: item?.question?.message || item?.message || "",
        }));
    }

    // Combine primary and additional non-primary messages
    setAllQuestions([...latestPrimaryMessages, ...additionalMessages]);

    if (primaryMessages.length === 0) {
      // No primary messages, fill with top 5 questions of any type
      const latestQuestions = chatRoomHistory
        .filter((item) => item.messageType === "question") // Include all questions
        .sort((a, b) => new Date(b?.createdOn) - new Date(a?.createdOn))
        .slice(0, 5)
        .map((item) => ({
          ...item,
          displayMessage: item?.question?.message || item?.message || "",
        }));

      setAllQuestions(latestQuestions);
    }
  }, [primaryMessages, chatHistory, props.activeChatRoom]);

  useEffect(() => {
    const newPrimary = chatHistory?.[props?.activeChatRoom]?.find(
      (msg) =>
        msg.messageType === "question" &&
        msg?.question?.isPrimary &&
        new Date(msg?.createdOn) > new Date(pinnedPrimary?.createdOn || 0)
    );

    if (newPrimary) {
      setPinnedPrimary({
        ...newPrimary,
        displayMessage:
          newPrimary?.question?.message || newPrimary?.message || "",
      });
    }
  }, [chatHistory, props.activeChatRoom, pinnedPrimary]);

  // Calculate current questions for pagination
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = allQuestions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(allQuestions.length / questionsPerPage);
      i++
    ) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-item ${
            currentPage === i ? "pagination-item--active" : ""
          }`}
        >
          {i}
        </button>
      );
    }
    return <div className="pagination-container">{pageNumbers}</div>;
  };
  // console.log("primary Questions", primaryMessages);
  // console.log("all Questions", allQuestions);
  // console.log("currentQuestions", currentQuestions);

  useEffect(() => {
    // Reset the details visibility and remove the 'expanded' class when activeCase changes
    setDetailsVisible(false);
    const container = document.querySelector(".pinned-primary-container");
    if (container && container.classList.contains("expanded")) {
      container.classList.remove("expanded");
    }
  }, [chatHistory, props.activeChatRoom]);

  const handleScrollToPinnedMessage = () => {
    const messageElement = pinnedMessageRef.current?.[pinnedPrimary.chatId];
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      console.error("Pinned message element not found:", pinnedPrimary.chatId);
    }
  };

  const toggleDetailsVisibility = () => {
    setDetailsVisible((prev) => !prev);
    const container = pinnedMessageRef.current;
    if (container) container.classList.toggle("expanded");
  };

  const fetchSynopsis = async (roomId) => {
    setIsSynopsisVisible(true);
    try {
      const response = await axios({
        url: `get/synopsis/${roomId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
        },
      });

      if (response) {
        setSynopsisData(response.data.synopsis);
        setIsSynopsisVisible(true);
      } else {
        Swal.fire({
          title: "No Synopsis Found",
          text: "This room does not have a synopsis available.",
          icon: "info",
        });
      }
    } catch (error) {
      console.error("Error fetching synopsis:", error);
      Swal.fire({
        title: "Error",
        text: "Failed to fetch synopsis data.",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    setIsSynopsisVisible(false);
    setSynopsisData(null);
  }, [props?.activeChatRoom]);

  const openReactionsPopup = (chatId, reactions) => {
    if (currentPopupChatId === chatId && showReactionsPopup) {
      closeReactionsPopup();
    } else {
      if (Array.isArray(reactions)) {
        setReactionsData(reactions);
      } else {
        console.error("Reactions data is not an array:", reactions);
        setReactionsData([]); // Fallback to an empty array
      }
      setSelectedEmoji("All"); // Show all reactions by default
      setCurrentPopupChatId(chatId); // Track the chatId
      setShowReactionsPopup(true);
    }
  };

  const closeReactionsPopup = () => {
    setShowReactionsPopup(false);
    setCurrentPopupChatId(null); // Reset chatId when closing
  };

  const uniqueEmojis = [
    ...new Set(reactionsData?.map((reaction) => reaction.emoji)),
  ];

  const filteredReactions = selectedEmoji
    ? reactionsData.filter((reaction) => reaction.emoji === selectedEmoji)
    : reactionsData;

  // Toggle message menu

  const toggleMessageMenu = (chatId) => {
    setShowMessageMenu((prev) => (prev === chatId ? null : chatId));
    setShowEmojiPicker(null);
  };

  // Toggle emoji picker
  const toggleEmojiPicker = (chatId) => {
    setShowEmojiPicker((prev) => (prev === chatId ? null : chatId));
    setShowMessageMenu(null);
  };

  // Handle emoji selection
  const handleEmojiSelect = (item, emojiObject, event) => {
    const userProfilePic = userStore?.profile?.userImage
      ? userStore?.profile?.userImage
      : "";

    sendReactionToMessage(
      String(item?.chatId),
      event.emoji,
      userStore.profile.username,
      userProfilePic
    );
    setIsScrollToBottom(false);
    setShowEmojiPicker(null);
  };

  const chatMessageRefs = useRef({});

  // Update `messageRefs` when `chatHistory` changes
  useEffect(() => {
    closeAllVoters();
    const refs = {};
    chatHistory?.[props?.activeChatRoom]?.forEach((msg) => {
      refs[msg.chatId] = React.createRef();
    });
    messageRefs.current = refs;
  }, [chatHistory, props.activeChatRoom]);

  useEffect(() => {
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const filtered = chatHistory[props?.activeChatRoom]?.filter((msg) =>
        msg.message.toLowerCase().includes(lowerSearchTerm)
      );
      setFilteredMessages(filtered);
    } else {
      setFilteredMessages([]);
    }
  }, [searchTerm, chatHistory]);

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;

    let highlighted = false; // Flag to check if the term has been highlighted
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));

    return parts.map((part, index) => {
      if (!highlighted && part.toLowerCase() === searchTerm.toLowerCase()) {
        highlighted = true; // Mark that we’ve highlighted once
        return (
          <span key={index} className="highlight">
            {part}
          </span>
        );
      }
      return part;
    });
  };

  const handleMessageClick = (msgId) => {
    const messageRef = chatMessageRefs.current[msgId];
    setHighlightedMessageId(msgId);
    setIsSearchActive(false);
    toggleSearchOverlay();
    if (messageRef && messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    // Clear highlight after 3 seconds
    setTimeout(() => setHighlightedMessageId(null), 3000);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleSaveEditedImage = (editedImageURL) => {
    console.log("Edited the image ");
    // console.log("Edited image URL:", editedImageURL);
    // setChatImageEditeResult(editedImageURL)
  };

  const toggleViewMoreMenu = () => {
    console.log("clicked more menu");
    setIsViewMoreMenuOpen((prev) => !prev);
  };

  const closeMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsViewMoreMenuOpen(false);
      setIsSearchActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMenu);
    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, []);

  const toggleSearchMessagesByUserPopup = () => {
    setIsSearchMessageByUsernamePopop((prev) => !prev);
  };

  useEffect(() => {
    if (props.activeChatRoom) {
      connectToWebSocket(props.activeChatRoom);
    }

    // Cleanup on component unmount
    return () => {
      closeWebSocket();
    };
  }, [props.activeChatRoom]);

  // console.log("active chat room:", activeChatGroupData);

  const unFreezeRoom = async (id) => {
    const response = await axios({
      url: `chat-group/${id}/freeze`,
      method: "put",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    // console.log("Room is successfully unfreezed:", response);

    // let chatgroups = response.data.data;
    let chatStore = { ...store.chatStore };
    let responseData = { ...response.data };

    // Find and update the group by ID
    let groups = chatStore.groups.map((group) => {
      if (group.id === responseData.groupId) {
        // Update only the freeze status
        return { ...group, freeze: responseData.newFreezeStatus };
      }
      // Keep other groups unchanged
      return group;
    });

    // Update the store with the modified groups
    setStore({
      ...store,
      chatStore: { ...chatStore, groups },
    });

    // console.log("Updated chat groups:", groups);
    if (response) {
      Swal.fire({
        title: "Success!",
        text: "Room is successfully unfreezed",
        icon: "success",
      });
    }
  };

  useEffect(() => {
    if (props.activeChatRoom) {
      const activeChatRoom = chatStore?.groups?.find(
        (group) => group.id === props.activeChatRoom
      );
      setActiveChatGroupData(activeChatRoom);
    }
  }, [props.activeChatRoom, unFreezeRoom]);

  // console.log("activeChatGroupData", activeChatGroupData);

  // Toggle popup visibility
  const togglePollPopup = () => {
    setIsPopupOpen(!isPopupOpen);
    resetPollForm();
  };

  // Reset form inputs
  const resetPollForm = () => {
    setQuestion("");
    setOptions([""]);
    setDuplicateError(null);
  };

  // Handle question input
  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;

    // Check for duplicates excluding the current index
    const isDuplicate = updatedOptions.some(
      (opt, idx) => opt.trim() === value.trim() && idx !== index
    );

    if (isDuplicate) {
      setDuplicateError(index); // Set the index of the duplicate
    } else {
      setDuplicateError(null); // Clear error
    }

    setOptions(updatedOptions);

    // Prevent adding a new field if there's an error
    if (
      !isDuplicate &&
      value.trim() &&
      index === options.length - 1 &&
      options.length < 4
    ) {
      setOptions([...updatedOptions, ""]);
    }
  };

  let draggedItemIndex = null;

  const handleDragStart = (e, index) => {
    // Save the index of the item being dragged
    draggedItemIndex = index;
  };

  const handleDrop = (e, dropIndex) => {
    // Rearrange the options array
    const updatedOptions = [...options];
    // Remove dragged item
    const [draggedItem] = updatedOptions.splice(draggedItemIndex, 1);
    // Insert it at the new position
    updatedOptions.splice(dropIndex, 0, draggedItem);

    setOptions(updatedOptions);
  };

  const handlePollCreationSubmit = () => {
    if (!question.trim()) {
      alert("Poll question is required!");
      return;
    }

    const filledOptions = options.filter((opt) => opt.trim());
    if (filledOptions.length < 2) {
      alert("At least two options are required!");
      return;
    }

    // Construct poll data object
    const pollData = {
      pollText: question,
      pollOptions: filledOptions.map((option) => ({ text: option.trim() })),
    };

    const message = {
      userID: profile?.id || 0,
      groupID: props.activeChatRoom,
      userName: profile?.username,
      MessageType: "poll",
      Poll: pollData,
      userImage: profile?.userImage || "",
    };

    console.log("Poll Message:", message);

    if (profile?.id) {
      sendMessage(message);
      setIsScrollToBottom(true);
      setChatMessage("");
      setTextareaHeight(50);
      togglePollPopup();
      setQuestion("");
      setOptions(["", "", ""]);
    }
  };

  useEffect(() => {
    if (chatImageEditURL) {
      console.log("Selected image URL for editing:", chatImageEditURL);
    }
  }, [chatImageEditURL]);

  const searchRef = useRef(null);
  const overlayRef = useRef(null);

  const handleSearchClick = () => {
    setIsSearchActive((prev) => !prev);
    toggleSearchOverlay();
  };

  const handleClickOutside = (event) => {
    if (
      searchRef.current &&
      !searchRef.current.contains(event.target) &&
      overlayRef.current &&
      !overlayRef.current.contains(event.target) &&
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      setIsSearchActive(false);
      setIsSearchOverlayOpen(false);
      setIsViewMoreMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleGroupInfoClick = () => {
    toggleGroupInfoOverlay();
  };

  const [isSearchActive, setIsSearchActive] = useState(false);

  //   const handleSearchClick = () => {
  //     setIsSearchActive(true);
  //   };

  const handleInputBlur = () => {
    setIsSearchActive(false);
  };

  const textareaStyle = {
    height: `${textareaHeight}px`,
    minHeight: "50px",
    maxHeight: "100px",
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true, // Prevents click-to-open file dialog
    onDrop: async (acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        const fileType = file.type;

        if (fileType.startsWith("image/") || fileType.startsWith("video/")) {
          // Create a fake event object for images/videos
          const event = {
            preventDefault: () => {},
            target: {
              files: [file], // Wrap file in an array
            },
          };
          await sendAttachImageHandle(event); // Call the image/video handler
        } else if (
          fileType === "application/pdf" ||
          fileType === "application/msword" || // For .doc files
          fileType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" // For .docx files
        ) {
          // Create a fake event object for documents
          const event = {
            target: {
              files: [file], // Wrap file in an array
            },
          };
          sendAttachDocsHandle(event); // Call the document handler
        }
      });
    },
  });

  useEffect(() => {
    let isPreviousDiabled = carouselActiveIndex <= 0 ? true : false;
    const allImages =
      chatHistory[props.activeChatRoom]
        ?.filter((i) => i.images !== "")
        .map((e) => e.images) || [];
    let isNextDiabled =
      carouselActiveIndex >= allImages.length - 1 ? true : false;

    setPreButtonDisable(isPreviousDiabled);
    setNextButtonDisable(isNextDiabled);
  }, [carouselActiveIndex]);

  const scrollTarget = useRef(null);
  // const videoRef = useRef();
  /**
   * Socket connection and reconnection logic handeled here
   */

  const updateChatTimes = (arr) => {
    if (arr.length > 0) {
      arr.map((item) => {
        const now = moment(new Date());
        const end = moment(item.createdOn);
        const duration = moment.duration(now.diff(end));
        const seconds = duration.asSeconds();

        let secondsValue = Math.floor(seconds);
        item["displayTime"] =
          secondsValue > 1 ? secondsValue + " second ago" : "Second ago";

        // minutes
        if (seconds > 60) {
          let minutesValue = Math.floor(seconds / 60);
          item["displayTime"] =
            minutesValue > 1 ? minutesValue + " minutes ago" : "Minute ago";
        }

        // hours
        if (seconds > 60 * 60) {
          let hoursValue = Math.floor(seconds / (60 * 60));
          item["displayTime"] =
            hoursValue > 1 ? hoursValue + " hours ago" : "An hour ago";
        }

        // days
        if (seconds > 60 * 60 * 24) {
          let dayValue = Math.floor(seconds / (60 * 60 * 24));
          item["displayTime"] =
            dayValue > 1 ? dayValue + " days ago" : "Day ago";
        }
        return item;
      });
    }
    return arr;
  };

  const addAttachHandle = () => {
    setAddAttachActive(!addAttachActive);
  };

  if (addAttachActive === true) {
    const concernedElement = document.querySelector(
      ".ir-ws-add-attachment-main-container"
    );
    document.addEventListener("mousedown", (event) => {
      if (!concernedElement.contains(event.target)) {
        setAddAttachActive(false);
      }
    });
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollTarget?.current?.scrollIntoView({ behavior: "instant" });
    }, 400);
  };

  const showShareModal = () => {
    setShareModal(true);
  };
  const shareCloseModalHandle = () => {
    setShareModal(false);
  };
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     // build chat object
//     const processedChatMessage = chatMessage.replace(/'/g, "''");

//     const message = {
//       userID: profile?.id || 0,
//       message: processedChatMessage,
//       groupID: props.activeChatRoom,
//       userName: profile?.username,
//       MessageType: "message",
//       userImage: profile?.userImage || "",
//       // images: "",
//       // videos: "",
//       // documents: "",
//       // createdOn: new Date().toISOString()
//     };

//     console.log("Message is being sent", message);

//     // console.log("At ChatContent socket", props.socket);
//     if (profile?.id) {
//       //   await props.socket.emit("chat_message", obj);
//       sendMessage(message);
//       setIsScrollToBottom(true);
//       setChatMessage("");
//       setTextareaHeight(50);
//       // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Build the chat message payload
//     const processedChatMessage = chatMessage.replace(/'/g, "''");

//     const message = {
//       message: processedChatMessage,
//       userName: profile?.username || "",
//       userId: profile?.id || "",
//     //   groupId: props?.activeChatRoom || "",
//       MessageType: "message",
//       lastName: profile?.lastName || "",
//       firstName: profile?.firstName || "",
//       isReply: !!replyingMessage,
//       replyTo: replyingMessage
//         ? {
//             chatId: replyingMessage.chatId,
//             userName: replyingMessage.userName,
//             userImage: replyingMessage.userImage,
//             message: replyingMessage.message,
//             messageType: replyingMessage.messageType,
//             // videoUrl: replyingMessage.videoUrl || "",
//             // videoThumbnail: replyingMessage.videoThumbnail || "",
//             // audioUrl: replyingMessage.audioUrl || "",
//             // documentUrl: replyingMessage.documentUrl || "",
//           }
//         : null,
//     };

//     console.log("Message is being sent", message);

//     if (profile?.id) {
//       sendMessage(message);
//       setIsScrollToBottom(true);
//       setChatMessage("");
//       setTextareaHeight(50);
//       setReplyingMessage(null);
//     }
//   };

const handleSubmit = async (event) => {
    event.preventDefault();

    // Build the chat message payload
    const processedChatMessage = chatMessage.replace(/'/g, "''");

    const message = {
      userID: profile?.id || 0,
      message: processedChatMessage,
      groupID: props.activeChatRoom,
      userName: profile?.username,
      MessageType: "message",
      userImage: profile?.userImage || "",
      isReply: !!replyingMessage, // Indicate if this is a reply
      replyTo: replyingMessage
        ? {
            chatId: replyingMessage.chatId || 0,
            userName: replyingMessage.userName || "",
            userImage: replyingMessage.userImage || "",
            message: replyingMessage.message || "",
            messageType: replyingMessage.messageType || "message",
            imageUrl: replyingMessage.imageUrl || "",
            videoUrl: replyingMessage.videoUrl || "",
            videoThumbnail: replyingMessage.videoThumbnail || "",
            audioUrl: replyingMessage.audioUrl || "",
            documentUrl: replyingMessage.documentUrl || "",
          }
        : null,
    };

    console.log("Message is being sent", message);

    // Send the message if the user ID exists
    if (profile?.id) {
      sendMessage(message);
      setIsScrollToBottom(true);
      setChatMessage("");
      setTextareaHeight(50);
      setReplyingMessage(null); // Clear the reply state after sending
    }
  };



  const handleChatSendMessage = (event) => {
    const newChatMessage = event.target.value;
    setChatMessage(newChatMessage);
    if (newChatMessage === "") {
      // Reset textarea height to the default (50px) when content is empty
      setTextareaHeight(50);
    } else {
      // Calculate and update the textarea's height
      const newHeight = Math.min(100, Math.max(50, event.target.scrollHeight));
      setTextareaHeight(newHeight);
    }
    // const newHeight = Math.min(100, Math.max(50, event.target.scrollHeight));
    // setTextareaHeight(newHeight);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Prevent the default Enter key behavior (usually creating a new line)
      event.preventDefault();
      // Trigger form submission
      handleSubmit(event);
    }
  };

  //   const voiceCloseModalHandle = () => {
  //     setOpenVoiceModal(false);
  //   };

  const openCanvasModal = () => {
    setAddAttachActive(false);
    setOpenWhiteBoardModal(true);
  };

  const whiteBoardCloseModalHandle = () => {
    setOpenWhiteBoardModal(false);
  };

  const imageCloseModalHandle = () => {
    setOpenImageModal(false);
    setConvertedVideoURL(null);
    setProgress(0);
    setSelectedImage([] || {});
    setTrimRange([0, 0]);
    setIsTrimming(false);
    setIsTrimmedVideoConverted(true);
    setClinicalNote("");
    setOpenVideoModal(false);
    setShowSavedEditedImage(false);
    setSavedEditedImage(null);
  };

  const attachDocsCloseModalHandle = () => {
    setOpenAttachDocsModal(false);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Helper function to capture the first frame of a video
  // const captureVideoThumbnail = async (videoFile) => {
  //     return new Promise((resolve, reject) => {
  //       const videoElement = document.createElement("video");
  //       const canvas = document.createElement("canvas");

  //       videoElement.src = URL.createObjectURL(videoFile);
  //       videoElement.muted = true;

  //       videoElement.onloadeddata = () => {
  //         // Set canvas dimensions to match video frame size
  //         canvas.width = videoElement.videoWidth;
  //         canvas.height = videoElement.videoHeight;

  //         // Draw the first frame onto the canvas
  //         const ctx = canvas.getContext("2d");
  //         ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

  //         // Convert the canvas to a Blob or Base64 string
  //         canvas.toBlob((blob) => {
  //           resolve(blob);
  //         }, "image/jpeg", 0.9); // Adjust quality if needed
  //       };

  //       videoElement.onerror = (error) => {
  //         reject(new Error("Error loading video for thumbnail extraction: " + error));
  //       };

  //       videoElement.load();
  //     });
  //   };

  //   const sendAttachImageHandle = async (event) => {
  //     try {
  //       setAddAttachActive(false);
  //       if (event.target.files && event.target.files.length > 0) {
  //         setSelectedImage(event.target.files);
  //         setUploaded(null);

  //         // Desired bitrate threshold in kbps
  //         const BITRATE_THRESHOLD = 500;

  //         for (let i = 0; i < event.target.files.length; i++) {
  //           const file = event.target.files[i];

  //           // Check if file is a video
  //           if (file.type.startsWith("video/")) {
  //             // setOpenImageModal(true);
  //             setOpenVideoModal(true);
  //             setProgress(1);

  //              // Capture thumbnail before proceeding
  //              try {
  //                 const videoThumbnail = await captureVideoThumbnail(file);
  //                 console.log("videoThumbnail: " + videoThumbnail);

  //              } catch (error) {
  //                  console.log("error in capturing video thumbnail:", error);
  //              }

  //             // Create a video element to load metadata
  //             const videoElement = document.createElement("video");
  //             videoElement.src = URL.createObjectURL(file);

  //             let metadataLoaded = false;

  //             videoElement.onloadedmetadata = async () => {
  //               metadataLoaded = true; // Set flag when metadata is loaded
  //               const durationInSeconds = videoElement.duration;
  //               let bitrateKbps = null;

  //               if (durationInSeconds && file.size) {
  //                 // Calculate bitrate if metadata is available
  //                 const fileSizeInBits = file.size * 8;
  //                 bitrateKbps = (
  //                   fileSizeInBits /
  //                   durationInSeconds /
  //                   1000
  //                 ).toFixed(2);
  //                 console.log("Extracted bitrate:", bitrateKbps, "kbps");
  //               } else {
  //                 console.log("Bitrate or duration metadata is missing.");
  //               }

  //               // Convert if bitrate is low, missing, or below threshold
  //               if (!bitrateKbps || parseFloat(bitrateKbps) < BITRATE_THRESHOLD) {
  //                 await convertVideo(file);
  //               } else {
  //                 // Skip conversion if bitrate is sufficient
  //                 console.log("Bitrate is sufficient; skipping conversion.");
  //                 setConvertedVideoURL(URL.createObjectURL(file));
  //                 setIsTrimming(true);
  //                 setDisableUploadBtn(false);
  //                 setProgress(100);
  //               }
  //             };

  //             // Fallback for videos with no metadata (after 2 seconds)
  //             setTimeout(async () => {
  //               if (!metadataLoaded) {
  //                 console.log(
  //                   "Metadata not available, converting video by default."
  //                 );
  //                 await convertVideo(file);
  //               }
  //             }, 2000);

  //             // Trigger onloadedmetadata event
  //             videoElement.load();
  //             return; // Stop processing after the first video
  //           }
  //         }

  //         // If it's an image, open the modal directly
  //         setOpenImageModal(true);
  //         setDisableUploadBtn(false);
  //       }
  //     } catch (error) {
  //       console.error("Error uploading video or image:", error);
  //     }
  //   };

  // const captureVideoThumbnail = async (videoFile) => {
  //     return new Promise((resolve, reject) => {
  //       const videoElement = document.createElement("video");
  //       const canvas = document.createElement("canvas");

  //       videoElement.src = URL.createObjectURL(videoFile);
  //       videoElement.muted = true;

  //       videoElement.onloadeddata = () => {
  //         // Set canvas dimensions to match video frame size
  //         canvas.width = videoElement.videoWidth;
  //         canvas.height = videoElement.videoHeight;

  //         // Draw the first frame onto the canvas
  //         const ctx = canvas.getContext("2d");
  //         ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

  //         // Convert the canvas to a Blob
  //         canvas.toBlob(
  //           (blob) => {
  //             if (blob) {
  //               const thumbnailURL = URL.createObjectURL(blob); // Create a URL for the blob
  //               console.log("Thumbnail URL:", thumbnailURL); // Log the URL
  //               resolve(thumbnailURL); // Resolve with the URL
  //             } else {
  //               reject(new Error("Failed to generate thumbnail blob."));
  //             }
  //           },
  //           "image/jpeg",
  //           0.9 // Adjust quality if needed
  //         );
  //       };

  //       videoElement.onerror = (error) => {
  //         reject(new Error("Error loading video for thumbnail extraction: " + error));
  //       };

  //       videoElement.load();
  //     });
  //   };

  const captureVideoThumbnail = async (videoFile, captureTime = 1) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      const canvas = document.createElement("canvas");

      videoElement.src = URL.createObjectURL(videoFile);
      videoElement.muted = true;

      videoElement.onloadeddata = () => {
        // Seek to the desired time
        videoElement.currentTime = captureTime;

        videoElement.ontimeupdate = () => {
          if (videoElement.currentTime >= captureTime) {
            // Set canvas dimensions to match video frame size
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;

            // Draw the frame onto the canvas
            const ctx = canvas.getContext("2d");
            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            // Convert the canvas to a Blob
            canvas.toBlob(
              (blob) => {
                if (blob) {
                  const thumbnailURL = URL.createObjectURL(blob); // Create a URL for the blob
                  console.log("Thumbnail URL:", thumbnailURL); // Log the URL
                  resolve(thumbnailURL); // Resolve with the URL
                } else {
                  reject(new Error("Failed to generate thumbnail blob."));
                }
              },
              "image/jpeg",
              0.9 // Adjust quality if needed
            );

            // Stop further updates
            videoElement.ontimeupdate = null;
          }
        };
      };

      videoElement.onerror = (error) => {
        reject(
          new Error("Error loading video for thumbnail extraction: " + error)
        );
      };

      videoElement.load();
    });
  };

  const sendAttachImageHandle = async (event) => {
    try {
      setAddAttachActive(false);
      if (event.target.files && event.target.files.length > 0) {
        setSelectedImage(event.target.files);
        setUploaded(null);

        // Desired bitrate threshold in kbps
        const BITRATE_THRESHOLD = 500;

        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];

          // Check if file is a video
          if (file.type.startsWith("video/")) {
            setOpenVideoModal(true);
            setProgress(1);

            // Create a video element to load metadata
            const videoElement = document.createElement("video");
            videoElement.src = URL.createObjectURL(file);

            let metadataLoaded = false;

            videoElement.onloadedmetadata = async () => {
              metadataLoaded = true; // Set flag when metadata is loaded
              const durationInSeconds = videoElement.duration;
              let bitrateKbps = null;

              if (durationInSeconds && file.size) {
                // Calculate bitrate if metadata is available
                const fileSizeInBits = file.size * 8;
                bitrateKbps = (
                  fileSizeInBits /
                  durationInSeconds /
                  1000
                ).toFixed(2);
                console.log("Extracted bitrate:", bitrateKbps, "kbps");
              } else {
                console.log("Bitrate or duration metadata is missing.");
              }

              // Convert if bitrate is low, missing, or below threshold
              if (!bitrateKbps || parseFloat(bitrateKbps) < BITRATE_THRESHOLD) {
                await convertVideo(file);
              } else {
                // Capture thumbnail before proceeding
                try {
                  const videoThumbnail = await captureVideoThumbnail(file, 1);
                  setVideoThumbnail(videoThumbnail);
                  console.log("Video thumbnail URL at 1 sec:", videoThumbnail);
                } catch (error) {
                  console.error("Error in capturing video thumbnail:", error);
                }

                // Skip conversion if bitrate is sufficient
                console.log("Bitrate is sufficient; skipping conversion.");
                setConvertedVideoURL(URL.createObjectURL(file));
                setIsTrimming(true);
                setDisableUploadBtn(false);
                setProgress(100);
              }
            };

            // Fallback for videos with no metadata (after 2 seconds)
            setTimeout(async () => {
              if (!metadataLoaded) {
                console.log(
                  "Metadata not available, converting video by default."
                );
                await convertVideo(file);
              }
            }, 2000);

            // Trigger onloadedmetadata event
            videoElement.load();
            return; // Stop processing after the first video
          }
        }

        // If it's an image, open the modal directly
        setOpenImageModal(true);

        setDisableUploadBtn(false);
      }
    } catch (error) {
      console.error("Error uploading video or image:", error);
    }
  };

  // Helper function for video conversion
  const convertVideo = async (file) => {
    console.log("Starting video conversion...");
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();
    setProgress(25);

    await ffmpeg.writeFile("input.mp4", await fetchFile(file));
    await ffmpeg.exec([
      "-i",
      "input.mp4",
      "-b:v",
      "500k",
      "converted_output.mp4",
    ]);
    setProgress(50);

    const convertedData = await ffmpeg.readFile("converted_output.mp4");
    const convertedBlob = new Blob([convertedData.buffer], {
      type: "video/mp4",
    });
    const videoURL = URL.createObjectURL(convertedBlob);

    setConvertedVideoURL(videoURL);
    setIsTrimming(true);
    setDisableUploadBtn(false);
    setProgress(100);
    console.log("Video conversion completed.");

    // Capture thumbnail before proceeding
    try {
      const videoThumbnail = await captureVideoThumbnail(convertedBlob, 1);
      setVideoThumbnail(videoThumbnail);
      console.log("Video thumbnail URL at 1 sec:", videoThumbnail);
    } catch (error) {
      console.error("Error in capturing video thumbnail:", error);
    }
  };

  const trimVideo = async () => {
    try {
      console.log("Trimming and conversion process started.");

      setIsTrimmedVideoConverted(false);
      setIsTrimming(true);
      // const ffmpeg = new FFmpeg();
      const ffmpeg = new FFmpeg({
        corePath:
          "https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.12.13/dist/umd/ffmpeg.min.js", // Test with CDN
      });
      await ffmpeg.load();
      setProgress(1); // Start progress

      const file = selectedImage[0];
      const start = trimRange[0].toFixed(2);
      const end = trimRange[1].toFixed(2);

      // Step 1: Trim the video
      console.log(
        "Trimming video from",
        formatTime(start),
        "to",
        formatTime(end)
      );
      await ffmpeg.writeFile("input.mp4", await fetchFile(file));
      await ffmpeg.exec([
        "-i",
        "input.mp4",
        "-ss",
        start,
        "-to",
        end,
        "-c",
        "copy",
        "output_trimmed.mp4",
      ]);
      setProgress(50); // Progress halfway for trimming
      console.log("Trimming completed.");

      // Step 2: Check bitrate and convert if necessary
      console.log("Checking trimmed video's bitrate.");
      const trimmedData = await ffmpeg.readFile("output_trimmed.mp4");
      const trimmedBlob = new Blob([trimmedData.buffer], { type: "video/mp4" });
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(trimmedBlob);

      // Desired bitrate threshold in kbps
      const BITRATE_THRESHOLD = 500;
      let metadataLoaded = false;

      videoElement.onloadedmetadata = async () => {
        metadataLoaded = true;
        const durationInSeconds = videoElement.duration;
        let bitrateKbps = null;

        if (durationInSeconds && trimmedBlob.size) {
          const fileSizeInBits = trimmedBlob.size * 8;
          bitrateKbps = (fileSizeInBits / durationInSeconds / 1000).toFixed(2);
          console.log("Extracted bitrate:", bitrateKbps, "kbps");
        } else {
          console.log("Bitrate or duration metadata is missing.");
        }

        // Convert if bitrate is low or metadata is missing
        if (!bitrateKbps || parseFloat(bitrateKbps) < BITRATE_THRESHOLD) {
          await convertTrimmedVideo(trimmedBlob);
        } else {
          console.log("Bitrate is sufficient; skipping conversion.");
          setConvertedVideoURL(URL.createObjectURL(trimmedBlob));
          setIsTrimming(false);
          setDisableUploadBtn(false);
          setProgress(100);
          setIsTrimmedVideoConverted(true);
        }
      };

      // Fallback if metadata is not loaded within 2 seconds
      setTimeout(async () => {
        if (!metadataLoaded) {
          console.log(
            "Metadata not available, converting trimmed video by default."
          );
          await convertTrimmedVideo(trimmedBlob);
        }
      }, 2000);

      videoElement.load();
    } catch (error) {
      console.error("Error trimming and converting video:", error);
    }
  };

  // Helper function for converting trimmed video
  const convertTrimmedVideo = async (trimmedBlob) => {
    console.log("Starting trimmed video conversion...");
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();
    setProgress(75); // Start conversion progress

    await ffmpeg.writeFile(
      "trimmed_input.mp4",
      new Uint8Array(await trimmedBlob.arrayBuffer())
    );
    await ffmpeg.exec([
      "-i",
      "trimmed_input.mp4",
      "-b:v",
      "500k",
      "trimmed_output_high_bitrate.mp4",
    ]);

    const convertedData = await ffmpeg.readFile(
      "trimmed_output_high_bitrate.mp4"
    );
    const highBitrateBlob = new Blob([convertedData.buffer], {
      type: "video/mp4",
    });
    setConvertedVideoURL(URL.createObjectURL(highBitrateBlob));

    setIsTrimming(false);
    setDisableUploadBtn(false);
    setProgress(100); // Conversion complete
    setIsTrimmedVideoConverted(true);
    console.log("Trimmed video conversion completed.");
  };

  const updateVideoPreview = (startTime, endTime) => {
    const videoElement = document.getElementById("videoElement");

    if (videoElement) {
      videoElement.currentTime = startTime;
      videoElement.pause(); // Start playback

      const checkTime = () => {
        // Stop playback if the end time is reached
        if (videoElement.currentTime >= endTime) {
          videoElement.pause();
        } else {
          requestAnimationFrame(checkTime); // Continue checking time
        }
      };

      // Start checking
      requestAnimationFrame(checkTime);
    }
  };

  const sendAttachDocsHandle = (event) => {
    setAddAttachActive(false);
    if (event.target.files && event.target.files.length > 0) {
      setSelectedDoc(event.target.files[0]);
      setOpenAttachDocsModal(true);
    }
  };

//   const uploadDocument = async (e) => {
//     let fd = new FormData();
//     fd.append("uploadFile", selectedDoc);
//     fd.append("location", "home");
//     const response = await axios({
//       url: "uploadfile",
//       method: "post",
//       data: fd,
//       withCredentials: false,
//       headers: {
//         "Content-Type": "multiplart/form-data",
//         Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
//       },
//     });

//     if (response.data.data) {
//       const message = {
//         userID: profile?.id || 0,
//         groupID: props.activeChatRoom,
//         userName: profile?.username,
//         MessageType: "document",
//         document: response.data.data[0],
//         userImage: profile?.userImage || "",
//       };
//       console.log("Document is being sent", message);

//       if (profile?.id) {
//         // props.socket.emit("chat_message", obj);
//         sendMessage(message);
//         setIsScrollToBottom(true);
//         setChatMessage("");
//         setTextareaHeight(50);
//         setSelectedDoc([]);
//         // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
//       }
//       setOpenAttachDocsModal(false);
//     } else {
//       /// todo : handle error here....
//       console.log("Unsupported file format!!!");
//     }
//   };


const uploadDocument = async (e) => {
    let fd = new FormData();
    fd.append("uploadFile", selectedDoc);
    fd.append("location", "home");

    try {
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        withCredentials: false,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      if (response.data.data) {
        const message = {
          userID: profile?.id || 0,
          groupID: props.activeChatRoom,
          userName: profile?.username,
          MessageType: "document",
          document: response.data.data[0],
          userImage: profile?.userImage || "",
          isReply: !!replyingMessage, // Indicate if this is a reply
          replyTo: replyingMessage
            ? {
                chatId: replyingMessage.chatId || 0,
                userName: replyingMessage.userName || "",
                userImage: replyingMessage.userImage || "",
                message: replyingMessage.message || "",
                messageType: replyingMessage.messageType || "message",
                imageUrl: replyingMessage.imageUrl || "",
                videoUrl: replyingMessage.videoUrl || "",
                videoThumbnail: replyingMessage.videoThumbnail || "",
                audioUrl: replyingMessage.audioUrl || "",
                documentUrl: replyingMessage.documentUrl || "",
              }
            : null,
        };

        console.log("Document is being sent", message);

        if (profile?.id) {
          sendMessage(message);
          setIsScrollToBottom(true);
          setChatMessage("");
          setTextareaHeight(50);
          setSelectedDoc([]);
          setReplyingMessage(null);
        }
        setOpenAttachDocsModal(false);
      } else {
        console.error("Unsupported file format!!!");
      }
    } catch (error) {
      console.error("Error uploading document:", error);
      // Handle additional error cases here
    }
  };


//   const whiteBoardSubmit = async (file) => {
//     let fd = new FormData();
//     fd.append("uploadFile", file);
//     fd.append("location", "home");

//     const response = await axios({
//       url: "uploadfile",
//       method: "post",
//       data: fd,
//       withCredentials: false,
//       headers: {
//         "Content-Type": "multiplart/form-data",
//         Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
//       },
//     });

//     if (response.data.data) {
//       const message = {
//         userID: profile?.id || 0,
//         groupID: props.activeChatRoom,
//         userName: profile?.username,
//         MessageType: "whiteboard",
//         image: response.data.data[0],
//         userImage: profile?.userImage || "",
//       };
//       console.log("white Board is being sent", message);

//       if (profile?.id) {
//         // props.socket.emit("chat_message", obj);
//         sendMessage(message);
//         setIsScrollToBottom(true);
//         setChatMessage("");
//         setTextareaHeight(50);
//         setSelectedImage([]);

//         // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
//       }
//     } else {
//       /// todo : handle error here....
//       console.log("Unsupported file format!!!");
//     }
//   };

const whiteBoardSubmit = async (file) => {
    let fd = new FormData();
    fd.append("uploadFile", file);
    fd.append("location", "home");

    try {
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        withCredentials: false,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      if (response.data.data) {
        const message = {
          userID: profile?.id || 0,
          groupID: props.activeChatRoom,
          userName: profile?.username,
          MessageType: "whiteboard",
          image: response.data.data[0],
          userImage: profile?.userImage || "",
          isReply: !!replyingMessage, // Indicate if this is a reply
          replyTo: replyingMessage
            ? {
                chatId: replyingMessage.chatId || 0,
                userName: replyingMessage.userName || "",
                userImage: replyingMessage.userImage || "",
                message: replyingMessage.message || "",
                messageType: replyingMessage.messageType || "message",
                imageUrl: replyingMessage.imageUrl || "",
                videoUrl: replyingMessage.videoUrl || "",
                videoThumbnail: replyingMessage.videoThumbnail || "",
                audioUrl: replyingMessage.audioUrl || "",
                documentUrl: replyingMessage.documentUrl || "",
              }
            : null,
        };

        console.log("Whiteboard is being sent", message);

        if (profile?.id) {
          sendMessage(message);
          setIsScrollToBottom(true);
          setChatMessage("");
          setTextareaHeight(50);
          setSelectedImage([]);
          setReplyingMessage(null); // Reset replying message after sending
        }
      } else {
        console.error("Unsupported file format!!!");
      }
    } catch (error) {
      console.error("Error uploading whiteboard content:", error);
      // Handle additional error cases here
    }
  };


  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onImageSave = async (editedImageObject, designState) => {
    const imageCropFile = await dataURLtoFile(
      editedImageObject.imageBase64,
      editedImageObject.fullName
    );
    console.log("saved", imageCropFile);
    submitImage(imageCropFile);
    return true;
  };

  const sendEditedImgMsg = () => {
    setSendingImg(true);
    const imageCropFile = dataURLtoFile(
      savedEditedImage.imageBase64,
      savedEditedImage.fullName // Use a fixed name or the name from `savedEditedImage.fullName`
    );

    submitImage(imageCropFile); // Pass the edited image file
  };

//   const submitImage = async (editedImage) => {
//     let fd = new FormData();
//     let videoThumbnailS3Url = null;

//     if (convertedVideoURL) {
//       console.log("Converted files");
//       const blob = await fetch(convertedVideoURL).then((res) => res.blob());
//       const uniqueName = `video_${Date.now()}_${Math.floor(
//         Math.random() * 1000000
//       )}.mp4`;
//       fd.append("uploadFile", blob, uniqueName);
//       fd.append("location", "home");
//     } else if (editedImage) {
//       // Use the edited image file here
//       console.log("Sending edited image file");
//       fd.append("uploadFile", editedImage);
//       fd.append("location", "home");
//     } else {
//       return false; // No files to upload
//     }
//     setUploaded(null);

//     const response = await axios({
//       url: "uploadfile",
//       method: "post",
//       data: fd,
//       withCredentials: false,
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
//       },
//       onUploadProgress: (data) => {
//         setUploaded(Math.round((data.loaded / data.total) * 100));
//         setDisableUploadBtn(true);
//       },
//     });
//     console.log(response);
//     if (response.data.data) {
//       const mediaType = convertedVideoURL ? "video" : "image";

//       console.log("This is media type: " + mediaType);

//       // Upload Video Thumbnail (if applicable)
//       if (convertedVideoURL && videoThumbnail) {
//         console.log("Uploading captured video thumbnail...");

//         // Convert the thumbnail URL to a Blob
//         const thumbnailBlob = await fetch(videoThumbnail).then((res) =>
//           res.blob()
//         );
//         const thumbnailFile = new File(
//           [thumbnailBlob],
//           `thumbnail_${Date.now()}.jpg`,
//           { type: "image/jpeg" }
//         );

//         const thumbnailFormData = new FormData();
//         thumbnailFormData.append("uploadFile", thumbnailFile);
//         thumbnailFormData.append("location", "home");

//         const thumbnailResponse = await axios({
//           url: "uploadfile",
//           method: "post",
//           data: thumbnailFormData,
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
//           },
//         });

//         if (thumbnailResponse.data.data) {
//           videoThumbnailS3Url = thumbnailResponse.data.data[0];
//           console.log(
//             "Thumbnail uploaded successfully. URL:",
//             videoThumbnailS3Url
//           );
//         } else {
//           console.error("Thumbnail upload failed.");
//         }
//       }

//       const message = {
//         userID: profile?.id || 0,
//         groupID: props.activeChatRoom,
//         userName: profile?.username,
//         clinicalNotes: clinicalNote,
//         // image: response.data.data[0],
//         MessageType: mediaType,
//         [mediaType]: response.data.data[0],
//         videoThumbnail: videoThumbnailS3Url || "",
//         userImage: profile?.userImage || "",
//       };
//       console.log(mediaType + " is being sent", message);

//       if (profile?.id) {
//         // props.socket.emit("chat_message", obj);
//         sendMessage(message);
//         setIsScrollToBottom(true);
//         setChatMessage("");
//         setTextareaHeight(50);
//         imageCloseModalHandle();
//         setSelectedImage([]);
//         setConvertedVideoURL(null);
//         setProgress(0);
//         setSendingImg(false);
//         setVideoThumbnail(null);
//       }
//     } else {
//       console.log("Unsupported file format!!!");
//     }
//   };

// const submitImage = async (editedImage) => {
//     let fd = new FormData();
//     let videoThumbnailS3Url = null;

//     if (convertedVideoURL) {
//       console.log("Converted files");
//       const blob = await fetch(convertedVideoURL).then((res) => res.blob());
//       const uniqueName = `video_${Date.now()}_${Math.floor(
//         Math.random() * 1000000
//       )}.mp4`;
//       fd.append("uploadFile", blob, uniqueName);
//       fd.append("location", "home");
//     } else if (editedImage) {
//       console.log("Sending edited image file");
//       fd.append("uploadFile", editedImage);
//       fd.append("location", "home");
//     } else {
//       return false; // No files to upload
//     }
//     setUploaded(null);

//     try {
//       const response = await axios({
//         url: "uploadfile",
//         method: "post",
//         data: fd,
//         withCredentials: false,
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
//         },
//         onUploadProgress: (data) => {
//           setUploaded(Math.round((data.loaded / data.total) * 100));
//           setDisableUploadBtn(true);
//         },
//       });

//       console.log(response);

//       if (response.data.data) {
//         const mediaType = convertedVideoURL ? "video" : "image";

//         console.log("This is media type: " + mediaType);

//         // Upload Video Thumbnail (if applicable)
//         if (convertedVideoURL && videoThumbnail) {
//           console.log("Uploading captured video thumbnail...");

//           const thumbnailBlob = await fetch(videoThumbnail).then((res) =>
//             res.blob()
//           );
//           const thumbnailFile = new File(
//             [thumbnailBlob],
//             `thumbnail_${Date.now()}.jpg`,
//             { type: "image/jpeg" }
//           );

//           const thumbnailFormData = new FormData();
//           thumbnailFormData.append("uploadFile", thumbnailFile);
//           thumbnailFormData.append("location", "home");

//           const thumbnailResponse = await axios({
//             url: "uploadfile",
//             method: "post",
//             data: thumbnailFormData,
//             headers: {
//               "Content-Type": "multipart/form-data",
//               Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
//             },
//           });

//           if (thumbnailResponse.data.data) {
//             videoThumbnailS3Url = thumbnailResponse.data.data[0];
//             console.log(
//               "Thumbnail uploaded successfully. URL:",
//               videoThumbnailS3Url
//             );
//           } else {
//             console.error("Thumbnail upload failed.");
//           }
//         }

//         const message = {
//           userID: profile?.id || 0,
//           groupID: props.activeChatRoom,
//           userName: profile?.username,
//           clinicalNotes: clinicalNote,
//           MessageType: mediaType,
//           [mediaType]: response.data.data[0],
//           videoThumbnail: videoThumbnailS3Url || "",
//           userImage: profile?.userImage || "",
//           isReply: !!replyingMessage, // Indicate if this is a reply
//           replyTo: replyingMessage
//             ? {
//                 // chatId: replyingMessage.chatId || 0,
//                 // userName: replyingMessage.userName || "",
//                 // userImage: replyingMessage.userImage || "",
//                 // message: replyingMessage.message || "",
//                 // messageType: replyingMessage.messageType || "message",
//                 // imageUrl: replyingMessage.imageUrl || "",
//                 // videoUrl: replyingMessage.videoUrl || "",
//                 // videoThumbnail: replyingMessage.videoThumbnail || "",
//                 // audioUrl: replyingMessage.audioUrl || "",
//                 // documentUrl: replyingMessage.documentUrl || "",
//                 chatId: replyingMessage.chatId || 0,
//                 userName: replyingMessage.userName || "",
//                 userImage: replyingMessage.userImage || "",
//                 message: replyingMessage.message || "",
//                 messageType: replyingMessage.messageType || "message",
//                 videoUrl: replyingMessage.videos || "",
//                 videoThumbnail: replyingMessage.videoThumbnail || "",
//                 audioUrl: replyingMessage.audio || "",
//                 documentUrl: replyingMessage.documents || "",
//               }
//             : null,
//         };

//         console.log(mediaType + " is being sent", message);

//         if (profile?.id) {
//           sendMessage(message);
//           setIsScrollToBottom(true);
//           setChatMessage("");
//           setTextareaHeight(50);
//           imageCloseModalHandle();
//           setSelectedImage([]);
//           setConvertedVideoURL(null);
//           setProgress(0);
//           setSendingImg(false);
//           setVideoThumbnail(null);
//           setReplyingMessage(null);
//         }
//       } else {
//         console.log("Unsupported file format!!!");
//       }
//     } catch (error) {
//       console.error("Error uploading file:", error);
//     }
//   };


const submitImage = async (editedImage) => {
    let fd = new FormData();
    let videoThumbnailS3Url = null;

    // Prepare file for upload
    if (convertedVideoURL) {
      const blob = await fetch(convertedVideoURL).then((res) => res.blob());
      const uniqueName = `video_${Date.now()}_${Math.floor(
        Math.random() * 1000000
      )}.mp4`;
      fd.append("uploadFile", blob, uniqueName);
      fd.append("location", "home");
    } else if (editedImage) {
      fd.append("uploadFile", editedImage);
      fd.append("location", "home");
    } else {
      return false; // No files to upload
    }

    setUploaded(null);

    try {
      // Upload media file
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
          setDisableUploadBtn(true);
        },
      });

      if (response.data.data) {
        const mediaType = convertedVideoURL ? "video" : "image";

        // If uploading a video, upload its thumbnail
        if (convertedVideoURL && videoThumbnail) {
          const thumbnailBlob = await fetch(videoThumbnail).then((res) =>
            res.blob()
          );
          const thumbnailFile = new File(
            [thumbnailBlob],
            `thumbnail_${Date.now()}.jpg`,
            { type: "image/jpeg" }
          );
          const thumbnailFormData = new FormData();
          thumbnailFormData.append("uploadFile", thumbnailFile);
          thumbnailFormData.append("location", "home");

          const thumbnailResponse = await axios({
            url: "uploadfile",
            method: "post",
            data: thumbnailFormData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          });

          if (thumbnailResponse.data.data) {
            videoThumbnailS3Url = thumbnailResponse.data.data[0];
          }
        }

        // Construct replyTo object
        const replyToMessage = replyingMessage
          ? {
              chatId: replyingMessage.chatId || 0,
              userName: replyingMessage.userName || "",
              userImage: replyingMessage.userImage || "",
              message: replyingMessage.message || "",
              messageType: replyingMessage.messageType || "message",
              imageUrl: replyingMessage.imageUrl || "",
              videoUrl: replyingMessage.videoUrl || "",
              videoThumbnail: replyingMessage.videoThumbnail || "",
              audioUrl: replyingMessage.audioUrl || "",
              documentUrl: replyingMessage.documentUrl || "",
            }
          : null;

        // Construct the message object
        const message = {
          userID: profile?.id || 0,
          groupID: props.activeChatRoom,
          userName: profile?.username,
          MessageType: mediaType,
          [mediaType]: response.data.data[0],
          videoThumbnail: videoThumbnailS3Url || "",
          userImage: profile?.userImage || "",
          isReply: !!replyToMessage, // Indicate if this is a reply
          replyTo: replyToMessage,
        };

        console.log("Media is being sent", message);

        // Send the message
        if (profile?.id) {
          sendMessage(message);
          setIsScrollToBottom(true);
          setChatMessage("");
          setTextareaHeight(50);
          imageCloseModalHandle();
          setSelectedImage([]);
          setConvertedVideoURL(null);
          setProgress(0);
          setSendingImg(false);
          setVideoThumbnail(null);
          setReplyingMessage(null);
        }
      } else {
        console.error("Unsupported file format!!!");
      }
    } catch (error) {
      console.error("Error uploading media:", error);
      // Handle additional error cases here
    }
  };



  const getCameraPermission = async () => {
    setRecordedVideo(null);
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true };
        // create audio and video streams separately
        const audioStream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );
        console.log(videoStream);

        setPermission(true);
        //combine both audio and video streams
        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);
        setStream(combinedStream);
        //set videostream to live feed player
        let video = liveVideoFeedRef.current;
        console.log("get Permissions", video);
        video.srcObject = videoStream;
        video.play();
      } catch (err) {
        console.log(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    setRecordingStatus("recording");
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((stream) => {
        let video = liveVideoFeedRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.log(err);
      });
    const media = new MediaRecorder(stream, { mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localVideoChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localVideoChunks.push(event.data);
    };
    setVideoChunks(localVideoChunks);
  };

  // const stopRecording = () => {
  //   // video.srcObject = null;

  //   setPermission(false);
  //   setRecordingStatus("inactive");
  //   mediaRecorder.current.stop();
  //   mediaRecorder.current.onstop = () => {
  //     const videoBlob = new Blob(videoChunks, { type: mimeType });
  //     const videoUrl = URL.createObjectURL(videoBlob);
  //     setRecordedVideo(videoUrl);
  //     setVideoChunks([]);
  //   };

  //   navigator.mediaDevices
  //     .getUserMedia({
  //       video: true,
  //     })
  //     .then((stream) => {
  //       let video = liveVideoFeedRef.current;
  //       console.log("inside navigator", stream);
  //       console.log("video is", video);
  //       // video.srcObject = stream;
  //       const tracks = stream.getTracks();
  //       for (var i = 0; i < tracks.length; i++) {
  //         var track = tracks[i];
  //         track.stop();
  //       }
  //       video.srcObject = null;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const stopRecording = () => {
    setPermission(false);
    setRecordingStatus("inactive");

    // Stop MediaRecorder
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const videoBlob = new Blob(videoChunks, { type: mimeType });
      const videoUrl = URL.createObjectURL(videoBlob);
      setRecordedVideo(videoUrl);
      setVideoChunks([]);
    };

    // Stop the video feed and camera
    const video = liveVideoFeedRef.current;
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      // Stop all tracks
      tracks.forEach((track) => track.stop());

      // Clear the video source
      video.srcObject = null;
    }

    // Stop the main camera stream
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      setStream(null); // Clear the saved stream
    }
  };

  const camCloseModalHandle = () => {
    if (recordingStatus === "recording") {
      stopRecording();
      setRecordedVideo(null);
    }
    setOpenCamModal(false);
    setRecordedVideo(null);
    setPermission(false);
    // setStream(null);
    setRecordingStatus("inactive");
    setVideoChunks([]);
    setPermission(false);
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      setStream(null); // Clear the saved stream
    }
  };

  const submitVideo = async () => {
    console.log("recordedVideo", recordedVideo);
    const mediaBlob = await fetch(recordedVideo).then((response) =>
      response.blob()
    );
    const videoFile = new File([mediaBlob], "chat_video_recording.mp4", {
      type: "video/mp4",
    });
    console.log("Video File", videoFile);

    let fd = new FormData();
    fd.append("uploadFiles", videoFile);

    const response = await axios({
      url: "uploadFile",
      method: "post",
      data: fd,
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });

    if (response.data.data) {
      const obj = {
        conversationId: "",
        userId: profile?.id || 0,
        message: "",
        images: "",
        videos: response.data.data[0],
        groupId: props.activeChatRoom,
        status: "active",
      };
      if (profile?.id) {
        props.socket.emit("chat_message", obj);
        setChatMessage("");
        setTextareaHeight(50);
        setOpenCamModal(false);
        setRecordedVideo([]);
        // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
      }
    } else {
      /// todo : handle error here....
      console.log("Unsupported file format!!!");
    }
  };

  // Open and close modal handlers
  const audioCloseModalHandle = () => {
    setOpenAudioModal(false);
    resetAudioState();
  };

  const resetAudioState = () => {
    setAudioRecordingStatus("inactive");
    setRecordedAudio(null);
    setAudioPermission(false);
    audioChunks.current = [];
  };

  // Function to get audio recording permissions
  const getAudioPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      liveAudioFeedRef.current.srcObject = stream;
      setAudioPermission(true);
    } catch (err) {
      console.error("Permission denied for audio recording:", err);
    }
  };

  const startAudioRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/wav", // You can also use 'audio/webm' or 'audio/ogg'
          recorderType: RecordRTC.StereoAudioRecorder, // For high-quality audio
        });

        recorder.startRecording();
        setAudioRecordingStatus("recording");

        recorder.stream = stream; // Save the stream to stop it later

        mediaRecorderRef.current = recorder;
      })
      .catch((err) => console.error("Error accessing audio stream: ", err));
  };

  const stopAudioRecording = () => {
    mediaRecorderRef.current.stopRecording(() => {
      const audioBlob = mediaRecorderRef.current.getBlob();
      const audioUrl = URL.createObjectURL(audioBlob);
      setRecordedAudio(audioUrl);
      setAudioRecordingStatus("stopped");
    });
  };

  const submitAudio = async (file) => {
    console.log("Submitting audio:", recordedAudio);

    // Convert the blob URL to Blob object
    const blob = await fetch(recordedAudio).then((res) => res.blob());

    // Generate a unique file name
    const uniqueFileName = `audio_${Date.now()}.mp3`; // Example: audio_1698066423000.mp3

    // Create FormData and append the file
    let fd = new FormData();
    fd.append("uploadFile", blob, uniqueFileName);
    fd.append("location", "home");

    try {
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      });

      //   console.log(response);

      if (response.data.data) {
        audioCloseModalHandle();

        const message = {
          userID: profile?.id || 0,
          groupID: props.activeChatRoom,
          userName: profile?.username,
          MessageType: "audio",
          audio: response.data.data[0],
          userImage: profile?.userImage || "",
        };

        console.log("Voice note is being sent", message);

        // Send the message (emit to socket or other logic)
        sendMessage(message);
        setIsScrollToBottom(true);
      } else {
        console.error("Unsupported file format!!!");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const imageClicked = (popItem) => {
    setOpenImageGalleryModal(true);

    const allMedia = chatHistory[props.activeChatRoom]
      .filter((i) => i.images || i.videos)
      .map((e) => (e.images ? e.images : e.videos)); // Collect both images and videos

    const activeIndex = allMedia.findIndex((e) => e === popItem);

    setCarouselActiveIndex(activeIndex);
    console.log("Pop-up ActiveIndex is", popItem);
  };

  const closeImageGalleryModal = () => {
    setOpenImageGalleryModal(false);
  };

  const onCarouselMenuItemclick = (carouselActiveIndex) => {
    setCarouselActiveIndex(carouselActiveIndex);
  };

  const leftCarouselIndex = () => {
    if (carouselActiveIndex > 0) {
      setCarouselActiveIndex(carouselActiveIndex - 1);
    }
  };
  const rightCarouselIndex = () => {
    const allImages = chatHistory[props.activeChatRoom]
      .filter((i) => i.images !== "")
      .map((e) => e.images);
    if (carouselActiveIndex < allImages.length - 1) {
      setCarouselActiveIndex(carouselActiveIndex + 1);
      // setNextButtonDisable(true);
    }
  };

  useEffect(() => {
    setIsScrollToBottom(true);
  }, [props.activeChatRoom, groups]);

  useEffect(() => {
    // scrollToBottom();
    if (isScrollToBottom) {
      scrollToBottom();
    }
  }, [chatHistory, isScrollToBottom]);

  useEffect(() => {
    if (props.isChatLoading) {
      setTimeout(() => {
        props.updateChatLoading(false);
      }, 5000);
    }
  }, [props.isChatLoading]);

  useEffect(() => {
    console.log("groups:", groups);
    console.log("props.activeChatRoom:", props.activeChatRoom);
    if (groups.length !== 0 && props.activeChatRoom !== null) {
      const activeRoomDetails = groups.find(
        (room) => room.id === parseInt(props.activeChatRoom)
      );

      console.log("activeRoomDetails", activeRoomDetails);
      if (activeRoomDetails !== undefined) {
        setActiveRooms(activeRoomDetails);
        setIsArchived(activeRoomDetails.archive);
        setActiveCaseName(activeRoomDetails.name);
        scrollToBottom();
        setChatMessage("");
        setTextareaHeight(50);
        props.updateChatLoading(true);
      }
    }
  }, [props.activeChatRoom, groups]);

  useEffect(() => {
    if (routeParams?.id && routeParams?.id !== ("" || undefined || null)) {
      let id = parseInt(routeParams.id);
      props.updateActiveChat(id);
    }
  }, [routeParams]);

  const handleBuyNowClick = async (room) => {
    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
        "Content-Type": "application/json",
      },
    };

    try {
      const paymentLinkForProduct = await axios.get(
        `${BaseURL}/payment/paymentLinks`,
        headers
      );
      const matchingProduct = paymentLinkForProduct.data.data.find(
        (product) => product.description === room.name
      );
      const matchingProductlink = matchingProduct.payment_url;
      if (paymentLinkForProduct) {
        window.location.assign(matchingProductlink);
      } else {
        console.log("there is not paymentLinkForProduct");
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error("Error fetching data:", error.message);
    }
  };

  const handleDeleteClick = (room) => {
    // console.log("room", room.chatId)
    deleteMessage(String(room.chatId));
    handleModalToggle();
    // const requestData = {
    //   room_id: room?.groupId,
    //   conversation_id: room?.id,
    // };

    // const headers = {
    //   Authorization: "Bearer " + localStorage.getItem("jwtTokenApi"),
    //   "Content-Type": "application/json",
    // };

    // axios
    //   .post(`${BaseURL}/chat/history/delete`, requestData, { headers })
    //   .then((response) => {
    //     console.log("Product deleted:", response?.data);
    //     if (response?.data?.status == 200) {
    //       let index = chatHistory[room?.groupId].findIndex(
    //         (item) => item.id == room.id
    //       );
    //       chatHistory[room?.groupId].splice(index, 1);
    //       setStore({
    //         ...store,
    //         chatStore: {
    //           ...chatStore,
    //           chatHistory: { ...chatHistory },
    //         },
    //       });
    //       handleModalToggle();
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error deleting product:", error);
    //   });
    // const chatId =chatId
    // deleteMessage(payload)
  };

  const handleModalToggle = (item) => {
    if (item) {
      setShowModal(!showModal);
      setItemToDelete(item);
    } else {
      setShowModal(!showModal);
      setItemToDelete(null);
    }
  };
  const shareUrl =
    window.location.href + "/" + JSON.stringify(props.activeChatRoom);

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setTooltipText(true);
    setTimeout(() => setTooltipText(false), 2000);
  };

  const uploadEditedImage = async () => {
    if (!chatImageEditedResult) {
      console.log("No edited image to upload.");
      return;
    }

    try {
      // Step 1: Fetch the Blob from the edited image URL
      const blob = await fetch(chatImageEditedResult).then((res) => res.blob());

      // Step 2: Generate a unique filename for the image
      const mimeType = blob.type; // Get the MIME type of the blob
      const extension = mimeType.split("/")[1]; // Extract file extension (e.g., 'png', 'jpeg')
      const uniqueFileName = `image_${Date.now()}_${Math.floor(
        Math.random() * 1000000
      )}.${extension}`;

      // Step 3: Prepare FormData for uploading
      let fd = new FormData();
      fd.append("uploadFile", blob, uniqueFileName);
      fd.append("location", "home"); // Add location if required by API

      setUploaded(null); // Reset upload progress

      // Step 4: Make the upload API request
      const response = await axios({
        url: "uploadfile",
        method: "post",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
          setDisableUploadBtn(true);
        },
      });

      console.log("Upload response:", response);

      if (response.data.data) {
        // Step 5: Construct the chat message object with the uploaded image URL
        const message = {
          userID: profile?.id || 0,
          groupID: props.activeChatRoom,
          userName: profile?.username,
          MessageType: "image",
          userImage: profile?.userImage || "",
          image: response.data.data[0], // API returned image URL
        };

        // Step 6: Emit the message to the chat
        if (profile?.id) {
          sendMessage(message); // Emit the message using your `sendMessage` function

          console.log("Image message sent to chat:", message);

          // Reset chat inputs and UI states
          setIsScrollToBottom(true);
          setChatMessage("");
          setTextareaHeight(50);
          setUploaded(0);
        }
      } else {
        console.log("Upload failed: Unsupported file format!");
      }
    } catch (error) {
      console.error("Error uploading edited image:", error);
    }
  };

  useEffect(() => {
    const fetchGroupUsers = async () => {
      try {
        const response = await axios({
          url: `/group/${props.activeChatRoom}/users`,
          method: "get",
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        });

        console.log("response", response.data);
        setGroupUsers(response.data);

        if (groupUsers) {
          console.log("response1", groupUsers);
        }
      } catch (error) {
        console.error("Error fetching group users:", error);
      }
    };

    if (props?.activeChatRoom) {
      fetchGroupUsers();
    }
  }, [props?.activeChatRoom]);

  const handleEditClick = (imageURL) => {
    // imageCloseModalHandle()
    closeImageGalleryModal();
    setChatImageEditURL(imageURL);
    setIsImageEditorOpen(true);
  };

  // Function to format date headers
  const formatDateHeader = (date) => {
    const now = moment();
    const messageDate = moment(date);

    // console.log("Date received in formatdate function", date)

    // If date is invalid, return "Invalid date"
    if (!messageDate.isValid()) return "Invalid date";

    if (now.isSame(messageDate, "day")) return "Today";
    if (now.clone().subtract(1, "days").isSame(messageDate, "day"))
      return "Yesterday";
    return messageDate.format("D-MMM-YYYY");
  };

  const extractHourAndMin = (dateString) => {
    const date = moment(dateString);

    // Check if date is valid
    if (!date.isValid()) return "Invalid date";

    // Format the time as hh:mm AM/PM
    return date.format("h:mm A");
  };

  const filteredChatHistory = filterByQuestion
    ? chatHistory?.[props?.activeChatRoom]?.filter(
        (item) => item.messageType === "question"
      )
    : filteredUser
    ? chatHistory?.[props?.activeChatRoom]?.filter(
        (item) => item.userName === filteredUser
      )
    : chatHistory?.[props?.activeChatRoom];

  useEffect(() => {
    setFilteredUser(null); // Reset user filter when active chat room changes
    setFilterByQuestion(false); // Reset question filter when active chat room changes
    console.log("Chat History Updated:", chatHistory[props.activeChatRoom]);
  }, [props.activeChatRoom, chatHistory]);

  const handleDownload = (item) => {
    // Ensure we have a valid file URL (images or videos)
    const fileUrl = item?.images || item?.videos;
    if (!fileUrl) {
      console.error("No valid file URL available for download.");
      return;
    }

    // Extract the file name from the URL
    const fileNameFromUrl = fileUrl.split("/").pop();
    const timestamp = new Date().getTime();
    const uniqueFileName = fileNameFromUrl
      ? `${fileNameFromUrl
          .split(".")
          .slice(0, -1)
          .join("")}_${timestamp}.${fileNameFromUrl.split(".").pop()}`
      : `download_${timestamp}`; // Ensure fallback if the URL is malformed

    // Fetch the file as a blob
    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch the file.");
        }
        return response.blob();
      })
      .then((blob) => {
        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = uniqueFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the temporary URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  };

  const handleClinicalNoteInputChange = (e) => {
    const value = e.target.value;
    if (value !== clinicalNote) setClinicalNote(value);
  };

  return (
    <div className="ir-chat-content-messages-main-container w-100 overflow-hidden position-relative">
      {onlyAchive ? (
        <div className="buyNowBtnParent">
          <p>
            This room is Archived. To access this room please purchase by
            clicking below Buy Now button
          </p>
          <button
            className="buyNowBtn"
            onClick={() => handleBuyNowClick(activeRooms)}
            disabled={activeRooms?.paymentInfo?.paymentLink === null}
          >
            Buy Now
          </button>
        </div>
      ) : (
        <>
          {props.isChatLoading && (
            <div className="loadingContainer">
              <div className="loadingIcon">
                <img src={LoadingImage} alt="loading..." />
              </div>
            </div>
          )}

          <div className="ir-chat-topbar">
            <div className="align-tem-center d-flex ir-chat-top-header">
              <h2 onClick={handleGroupInfoClick} style={{ cursor: "pointer" }}>
                {activeCaseName}
              </h2>
              <div className="ir-chat-top-head-elements">
                {/* <div className="ir-chat-top-head-invite ir-chat-top-head-heart" onClick={addToFavourites}>
                                    <i className="bi bi-heart"></i>
                                </div> */}
                <div className="top-nav-bar-menu-items-container">
                  <div
                    ref={searchRef}
                    className={`ir-chat-top-head-invite ${
                      isSearchActive ? "active" : ""
                    }`}
                    // onClick={handleSearchClick}
                    onClick={(e) => e.stopPropagation()}
                    title="Search"
                  >
                    <i className="bi bi-search" onClick={handleSearchClick}></i>
                    <input
                      type="text"
                      className="ir-chat-search-input"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>

                  {/* <div
                    className={`ir-chat-top-head-invite `}
                    // onClick={handleSearchClick}
                    onClick={(e) => e.stopPropagation()}
                    title="Media"
                  >
                    <i
                      class="bi bi-camera"
                      onClick={() => {
                        setIsViewMoreMenuOpen(false);
                        toggleAllMediaOverlay();
                        setIsSearchActive(false);
                      }}
                    ></i>

                  </div> */}
                  <div
                    className={`ir-chat-top-head-invite-media `}
                    // onClick={handleSearchClick}
                    onClick={(e) => e.stopPropagation()}
                    title="Media"
                  >
                    {/* <i
                      class="bi bi-camera"
                      onClick={() => {
                        setIsViewMoreMenuOpen(false);
                        toggleAllMediaOverlay();
                        setIsSearchActive(false);
                      }}
                    ></i> */}
                    <img
                      src={require("../../../assets/images/media-img.png")}
                      alt="media"
                      onClick={() => {
                        setIsViewMoreMenuOpen(false);
                        toggleAllMediaOverlay();
                        setIsSearchActive(false);
                      }}
                    />
                  </div>
                  <div
                    className={`ir-chat-top-head-invite`}
                    // onClick={handleSearchClick}
                    onClick={(e) => e.stopPropagation()}
                    title="Filter"
                  >
                    <i
                      class="bi bi-funnel"
                      onClick={() => {
                        setIsViewMoreMenuOpen(false);
                        toggleSearchMessagesByUserPopup();
                        setIsSearchActive(false);
                      }}
                    ></i>
                  </div>

                  <div
                    className="ir-chat-top-head-invite"
                    onClick={(e) => e.stopPropagation()}
                    title="Synopsis"
                  >
                    <i
                      className="bi bi-eye"
                      onClick={() => {
                        fetchSynopsis(activeRooms?.id);
                      }}
                    ></i>
                  </div>

                  <div
                    className="ir-chat-top-head-view-more"
                    ref={menuRef}
                    title="View More"
                  >
                    <i
                      className="bi bi-three-dots-vertical"
                      onClick={toggleViewMoreMenu}
                    ></i>

                    {isViewMoreMenuOpen && (
                      <ul className="ir-chat-menu-dropdown">
                        <li
                          className="ir-chat-menu-item"
                          onClick={() => {
                            setFilterByQuestion(!filterByQuestion);
                            setIsViewMoreMenuOpen(false);
                          }}
                        >
                          <i className="bi bi-question-circle"></i>
                          Messages by question
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isSynopsisVisible && synopsisData && (
            <div
              className={`ir-synopsis-sidebar ${
                isSynopsisVisible ? "show" : ""
              }`}
            >
              <div className="ir-synopsis-header">
                <h5 className="ir-synopsis-title">
                  Synopsis of {activeRooms.name}
                </h5>
                <button
                  type="button"
                  className="ir-synopsis-close-btn"
                  onClick={() => setIsSynopsisVisible(false)}
                >
                  X
                </button>
              </div>
              <div className="ir-synopsis-body">
                <h6>Details:</h6>
                <p>{synopsisData.details || "No details available"}</p>
                <h6>Objective:</h6>
                <p>{synopsisData.objective || "No objective available"}</p>
              </div>
            </div>
          )}

          {/* {isSearchOverlayOpen && (
            <div ref={overlayRef} className="search-overlay">
              <div className="chat-history">
                {searchTerm.length < 2 ? (
                  <div className="no-results">Search for messages.</div>
                ) : filteredMessages.length > 0 ? (
                  filteredMessages.map((msg) => (
                    <div
                      key={msg.id}
                      className="message-item"
                      onClick={() => handleMessageClick(msg.chatId)}
                    >
                      <div className="message-user">{msg.displayTime}</div>
                      <div className="message-content">
                        {highlightText(msg.message, searchTerm)}
                      </div>
                      <div className="message-time">{msg.userName}</div>
                    </div>
                  ))
                ) : (
                  <div className="no-results">No messages found.</div>
                )}
              </div>
            </div>
          )} */}

        {isSearchOverlayOpen && searchTerm.length > 0 && (
        <div ref={overlayRef} className="search-overlay">
            <div className="chat-history">
            {filteredMessages.length > 0 ? (
                filteredMessages.map((msg) => (
                <div
                    key={msg.id}
                    className="message-item"
                    onClick={() => handleMessageClick(msg.chatId)}
                >
                    <div className="message-user">{msg.displayTime}</div>
                    <div className="message-content">
                    {highlightText(msg.message, searchTerm)}
                    </div>
                    <div className="message-time">{msg.userName}</div>
                </div>
                ))
            ) : (
                <div className="no-results">No messages found.</div>
            )}
            </div>
        </div>
        )}




          <div
            className={`ir-chat-conversation p-3 p-lg-4 positin-relative ${
              isDragActive ? "drag-active" : ""
            }`}
            {...getRootProps({ onClick: (e) => e.stopPropagation() })}
          >
            {/* {filteredChatHistory?.length > 0 &&
                            updateChatTimes(filteredChatHistory) */}
            <div className="simplebar-content">
              {filteredChatHistory?.length <
                chatHistory[props?.activeChatRoom]?.length && (
                <div className="chat-header">
                  <div className="filter-info">
                    {filterByQuestion ? (
                      <span>These are filtered questions</span>
                    ) : (
                      <span>
                        These are filtered messages for {filteredUser}
                      </span>
                    )}

                    <button
                      className="clear-filters-btn"
                      onClick={() => {
                        setFilteredUser(null);
                        setFilterByQuestion(false);
                      }}
                    >
                      {/* <i className="bi bi-x-circle"></i> */}
                      Clear Filters
                    </button>
                  </div>
                </div>
              )}

              {/* {pinnedPrimary && (
                <div
                  className="pinned-primary-container"
                onClick={handleScrollToPinnedMessage}
                >
                  <div className="pinned-primary-content">
                    <p>{pinnedPrimary.displayMessage}</p>
                  </div>
                </div>
              )} */}

              {/* {pinnedPrimary && (
                <div
                  className="pinned-primary-container"
                  onClick={handleScrollToPinnedMessage}
                >
                  <div className="pinned-primary-content">
                    <p className="pinned-text">
                      {pinnedPrimary.displayMessage.length > 90
                        ? `${pinnedPrimary.displayMessage.slice(0, 90)}...`
                        : pinnedPrimary.displayMessage}
                    </p>
                    <i className="bi bi-three-dots-vertical"></i>
                  </div>
                </div>
              )} */}

              {pinnedPrimary && (
                <div
                  className="pinned-primary-container"
                  // ref={pinnedMessageRef}
                >
                  {/* Display Latest Primary Question */}
                  <div className="pinned-primary-content">
                    {!isDetailsVisible && (
                      <p
                      // onClick={handleScrollToPinnedMessage}
                      >
                        {pinnedPrimary.displayMessage.length > 90
                          ? `${pinnedPrimary.displayMessage.slice(0, 90)}...`
                          : pinnedPrimary.displayMessage}
                      </p>
                    )}

                    <i
                      className={`bi ${
                        isDetailsVisible ? "bi-chevron-up" : "bi-chevron-down"
                      } pinned-toggle-icon`}
                      onClick={() => {
                        setIsSearchOverlayOpen(false);
                        setIsSearchActive(false);
                        setDetailsVisible((prev) => !prev);
                        const container = document.querySelector(
                          ".pinned-primary-container"
                        );
                        container.classList.toggle("expanded");
                      }}
                    ></i>
                  </div>

                  {/* Display Pinned Question Image */}
                  {/* {isDetailsVisible && pinnedPrimary.question.images?.length > 0 && (
                                <div className="expanded-question-con">
                                    <p className="expanded-question-text" onClick={handleScrollToPinnedMessage}>
                                        {pinnedPrimary.displayMessage}
                                    </p>
                                    <img
                                        src={pinnedPrimary.question.images[0]}
                                        alt="Pinned Primary"
                                        className="pinned-primary-image"
                                    />
                                </div>
                            )} */}

                  {/* Display Current Questions and Pagination */}
                  {isDetailsVisible && (
                    <>
                      {currentQuestions.map((question, index) => (
                        <div
                          key={index}
                          ref={pinnedMessageRef}
                          className="question-item expanded-question-con"
                        >
                          <p
                            className="expanded-question-text"
                            onClick={handleScrollToPinnedMessage}
                          >
                            {question.displayMessage}
                          </p>
                          {question.question.images?.length > 0 && (
                            <img
                              className="pinned-primary-image"
                              src={question.question.images[0]}
                              alt={`Question ${index}`}
                              onClick={handleScrollToPinnedMessage}
                            />
                          )}
                        </div>
                      ))}
                      {renderPagination()}
                    </>
                  )}
                </div>
              )}

              <ul className="list-unstyled chat-conversation-list">
                {/* {chatHistory?.[props?.activeChatRoom] &&
                        chatHistory[props?.activeChatRoom].length > 0 &&
                        updateChatTimes(chatHistory[props?.activeChatRoom]) */}
                {filteredChatHistory?.length > 0 &&
                  updateChatTimes(filteredChatHistory)
                    .sort(
                      (a, b) => new Date(a?.createdOn) - new Date(b?.createdOn)
                    )
                    .map((item, i) => {
                      //   console.log("item", item, profile);
                      //   let str = item?.userName.toUpperCase();
                      //   let matches = str?.match(/\b(\w)/g); // ['J','S','O','N']
                      //   let profileDp = matches?.join(""); // JSON
                      let str = item?.userName?.toUpperCase();
                      let profileDp;

                      if (str?.includes(" ")) {
                        // Extract initials from multi-word names
                        let matches = str.match(/\b(\w)/g); // Match the first letter of each word
                        profileDp = matches?.join(""); // Join initials, e.g., "JADDU" -> "JD"
                      } else {
                        // Extract first two characters from single-word names
                        profileDp = str?.substring(0, 2); // e.g., "JADDU" -> "JA"
                      }

                      if (
                        item?.images !== "" &&
                        item?.images !== undefined &&
                        typeof item?.images !== "string"
                      ) {
                        item.images = JSON.parse(item.images);
                      }

                      // Date grouping logic
                      // const currentMessageDate = new Date(item.createdOn).toLocaleDateString();
                      // console.log("Message ID:", item.id, "Raw Date:", item.createdOn);

                      const currentMessageDate = formatDateHeader(
                        item.createdOn
                      );

                      const previousMessageDate =
                        i > 0
                          ? formatDateHeader(
                              chatHistory[props.activeChatRoom][i - 1].createdOn
                            )
                          : null;
                      const isNewDate =
                        i === 0 || currentMessageDate !== previousMessageDate;

                      if (
                        !currentMessageDate ||
                        currentMessageDate === "Invalid date"
                      ) {
                        console.warn(
                          "Invalid date encountered for message ID:",
                          item
                        );
                      }

                      // refs.current[item.chatId] = React.createRef();

                      const messageTime = extractHourAndMin(item.createdOn);

                      return (
                        <React.Fragment key={i}>
                          {isNewDate && (
                            <li className="date-separator">
                              <div className="separator-content">
                                {currentMessageDate}
                              </div>
                            </li>
                          )}
                          <li
                            className={
                              "chat-list " +
                              (profile.username === item.userName &&
                                "chat-list-outgoing")
                            }
                            // ref={(el) => (messageRefs.current[item.chatId] = el)}
                            // ref={(el) => (pinnedMessageRef.current[item.chatId] = el)}
                            // ref={(el) => {
                            //   // console.log("Element:", el, "Chat ID:", item.chatId);
                            //   if (el) {
                            //     pinnedMessageRef.current[item?.chatId] = el;
                            //   }
                            // }}
                            ref={(el) => {
                              if (el && pinnedMessageRef.current) {
                                pinnedMessageRef.current[item?.chatId] = el;
                              }
                            }}
                          >
                            <div className="conversation-list">
                              <div className="ir-chat-profile-dp-container">
                                {/* {item?.userImage === "" || profile?.userImage ? (
                                  <p className="ir-chat-profile-dp">
                                    {profileDp}
                                  </p>
                                ) : (
                                  <img
                                    className="chat-avatar"
                                    src={item?.userImage || profile.userImage || NoImage}
                                    alt="profile pic"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = NoImage;
                                    }}
                                  />
                                )} */}
                                {item?.userImage?.trim() === "" ? (
                                  <p className="ir-chat-profile-dp">
                                    {profileDp}
                                  </p>
                                ) : (
                                  <img
                                    className="chat-avatar"
                                    src={item?.userImage || NoImage}
                                    alt="profile pic"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // Prevents infinite loop
                                      currentTarget.src = NoImage; // Fallback to a placeholder image
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                className={`ir-chat-conv-info-container ${
                                  highlightedMessageId === item.id
                                    ? "highlighted"
                                    : ""
                                } ${
                                  item.messageType === "poll"
                                    ? "ir-chat-conv-info-container-poll"
                                    : ""
                                }`}
                                //  ref={refs.current[item.chatId]}
                                ref={chatMessageRefs.current[item.chatId]}
                              >
                                <div
                                  className={`ir-chat-conv-info-inner-container  ${
                                    highlightedMessageId === item.id
                                      ? "highlighted"
                                      : ""
                                  } ${
                                    item.messageType === "poll"
                                      ? "ir-chat-conv-info-container-poll"
                                      : ""
                                  }`}
                                >
                                  <div className="ir-chat-conv-user-details">
                                    <div className="ir-chat-conv-name">
                                      {item.userName || "Deleted User"}
                                    </div>
                                    <div className="ir-chat-conv-time">
                                      {messageTime || item?.displayTime}
                                      {/* {profile?.firstname === "admin" && profile?.lastname === "admin"  && (
                                                <span
                                                className="ir-chat-content-delete-icon material-symbols-outlined"
                                                onClick={() => handleModalToggle(item)}
                                                >
                                                delete
                                                </span>
                                            )} */}

                                      {profile?.firstname === "admin" &&
                                        profile?.lastname === "admin" && (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "10px",
                                            }}
                                          >
                                            {/* Download Icon */}
                                            {(item?.messageType === "video" ||
                                              item?.messageType ===
                                                "image") && (
                                              <span
                                                className="ir-chat-content-download-icon bi bi-download"
                                                onClick={() =>
                                                  handleDownload(item)
                                                }
                                                style={{ cursor: "pointer" }}
                                              ></span>
                                            )}

                                            {/* Delete Icon */}
                                            <span
                                              className="ir-chat-content-delete-icon material-symbols-outlined"
                                              onClick={() =>
                                                handleModalToggle(item)
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              delete
                                            </span>
                                          </div>
                                        )}

                                      {/* Menu Section */}
                                      <div className="ir-chat-menu-container">
                                        <button
                                          className="ir-chat-menu-button"
                                          onClick={() =>
                                            toggleMessageMenu(item.chatId)
                                          }
                                        >
                                          <i class="bi bi-three-dots"></i>
                                        </button>

                                        {showMessageMenu === item.chatId && (
                                          <div
                                            className={`ir-chat-menu-options ${
                                              profile.username === item.userName
                                                ? "sender-menu"
                                                : "receiver-menu"
                                            }`}
                                          >
                                            <button
                                              className="ir-chat-menu-option"
                                              onClick={() =>
                                                toggleEmojiPicker(item.chatId)
                                              }
                                            >
                                              React
                                            </button>

                                            <button
                                              className="ir-chat-menu-option"
                                              onClick={() => handleReplyClick(item)}
                                            >
                                              Reply
                                            </button>

                                          </div>
                                        )}

                                        {showEmojiPicker === item.chatId && (
                                          <div
                                            className={`ir-chat-emoji-picker ${
                                              profile.username === item.userName
                                                ? "sender-picker"
                                                : "receiver-picker"
                                            }`}
                                          >
                                            <EmojiPicker
                                              onEmojiClick={(
                                                event,
                                                emojiObject
                                              ) =>
                                                handleEmojiSelect(
                                                  item,
                                                  emojiObject,
                                                  event
                                                )
                                              }
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {item?.isReply &&(
                                        <div className="reply-preview bg-trns">
                                        <div className="reply-content">
                                        <p>
                                         <strong>{item?.replyTo.userName}</strong>
                                        </p>
                                        {/* Use AssetRender to handle message types */}
                                        <MiniAssetRender
                                            data={item?.replyTo}
                                            // imageClicked={(image) => console.log('Image clicked:', image)}
                                            options={{ width: 50, height: 50, videoControl: false }}
                                        />
                                        </div>
                                    </div>

                                  )}

                                  <div className="user-chat-content">
                                    <div className="ctext-content">
                                      <AssetRender
                                        imageClicked={imageClicked}
                                        data={item}
                                        options={{
                                          videoControl: false,
                                          height: 80,
                                        }}
                                      />
                                    </div>

                                    {/* Reactions Section */}
                                    {item.reactions &&
                                      item.reactions.length > 0 && (
                                        <div
                                          className="reaction-container"
                                          onClick={() =>
                                            openReactionsPopup(
                                              item.chatId,
                                              item.reactions
                                            )
                                          }
                                        >
                                          {item?.reactions?.map(
                                            (reaction, index) => (
                                              <span
                                                key={index}
                                                className="reaction-item"
                                                title={reaction.userName}
                                                // onClick={() => openReactionsPopup(reaction)}
                                              >
                                                {reaction.emoji}
                                              </span>
                                            )
                                          )}
                                        </div>
                                      )}

                                    {showReactionsPopup &&
                                      currentPopupChatId === item.chatId && (
                                        <div
                                          className={`reactions-popup  ${
                                            profile.username === item.userName
                                              ? "sender-reaction-con"
                                              : "receiver-reaction-con"
                                          }`}
                                        >
                                          <div className="popup-header">
                                            <div className="popup-filters">
                                              {/* "All" Button */}
                                              <button
                                                className={`filter-button ${
                                                  selectedEmoji === "All"
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  setSelectedEmoji("All")
                                                }
                                              >
                                                All
                                              </button>
                                              {/* Unique Emoji Buttons */}
                                              {uniqueEmojis.map(
                                                (emoji, index) => (
                                                  <button
                                                    key={index}
                                                    className={`filter-button ${
                                                      selectedEmoji === emoji
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      setSelectedEmoji(emoji)
                                                    }
                                                  >
                                                    {emoji}
                                                  </button>
                                                )
                                              )}
                                            </div>
                                            <button
                                              onClick={closeReactionsPopup}
                                              className="all-reaction-close-btn"
                                            >
                                              &times;
                                            </button>
                                          </div>

                                          {/* Filter Buttons */}

                                          {/* Reactions Content */}
                                          <div className="popup-content">
                                            {reactionsData
                                              .filter(
                                                (reaction) =>
                                                  selectedEmoji === "All" ||
                                                  reaction.emoji ===
                                                    selectedEmoji
                                              )
                                              .map((reaction, index) => (
                                                <div
                                                  key={index}
                                                  className="reaction-detail"
                                                >
                                                  <img
                                                    src={
                                                      reaction?.profileImage ||
                                                      NoImage
                                                    }
                                                    alt={
                                                      reaction?.userName ||
                                                      "User"
                                                    }
                                                    className="reaction-user-image"
                                                  />

                                                  <span>
                                                    {reaction.userName}
                                                  </span>
                                                </div>
                                              ))}
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </React.Fragment>
                      );
                    })}
                <li>
                  <div ref={scrollTarget} className="ir-scroll-target"></div>
                </li>
              </ul>
            </div>
          </div>

          <div className="ir-chat-conversation-container">
            {activeChatGroupData?.freeze ? (
              <div className="ir-chat-frozen-message">
                <p>
                  This group is currently inactive. Please reach out to the
                  admin to activate it.
                </p>
                {userStore.profile.username === "admin" && (
                  <button
                    className="group_unfreeze_btn"
                    onClick={() => unFreezeRoom(activeChatGroupData.id)}
                  >
                    Activate Group
                  </button>
                )}
              </div>
            ) : (
              <div className="ir-chat-conversation-send-container">
                <form
                  className="app-content-footer d-flex"
                  onSubmit={handleSubmit}
                  disabled={activeChatGroupData?.freeze}
                >

                     {/* Reply Preview */}
                     {replyingMessage && (
                        <div className="reply-preview">
                            <div className="reply-content">
                            <p>
                                Replying to: <strong>{replyingMessage.userName}</strong>
                            </p>
                            {/* Use AssetRender to handle message types */}
                            <MiniAssetRender
                                data={replyingMessage}
                                // imageClicked={(image) => console.log('Image clicked:', image)}
                                options={{ width: 50, height: 50, videoControl: false }}
                            />
                            </div>
                            <button type="button" className="cancel-reply" onClick={cancelReply}>
                            Cancel
                            </button>
                        </div>
                        )}

                  <div className="input-wrapper">
                    <div className={`app-content-footer-buttons`}>
                      <div
                        data-bs-toggle="tooltip"
                        className={`app-footer-plus`}
                        // onClick={addAttachHandle}
                        onClick={(e) => {
                          if (activeChatGroupData?.freeze) {
                            e.preventDefault();
                          } else {
                            addAttachHandle();
                          }
                        }}
                        title="Upload"
                        // disabled={activeChatGroupData?.freeze}
                      >
                        <i className="bi bi-paperclip"></i>
                      </div>
                    </div>
                    {/* {addAttachActive && ( */}
                    <div
                      className={`ir-ws-add-attachment-main-container ${
                        addAttachActive ? "addActiveOpen" : ""
                      } `}
                    >
                      <div className="add-attach-menu-item">
                        <label htmlFor="sendAattachDocs">
                          <i className="bi bi-file-earmark"></i>
                          <input
                            id="sendAattachDocs"
                            type="file"
                            accept="*"
                            // Spread the props for file input functionality
                            {...getInputProps()}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.target.value = null;
                            }}
                            onChange={sendAttachDocsHandle}
                          />
                          Documents
                        </label>
                      </div>
                      <div className="add-attach-menu-item">
                        <label htmlFor="sendAttachImage">
                          <i className="bi bi-image"></i>
                          <input
                            id="sendAttachImage"
                            type="file"
                            onChange={sendAttachImageHandle}
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                          />
                          Photos & Videos
                        </label>
                      </div>
                      <div
                        className="add-attach-menu-item"
                        // onClick={() => setOpenCamModal(true)}
                        onClick={() => {
                            if (!replyingMessage) {
                              setOpenCamModal(true);
                            }
                          }}
                          style={{ cursor: replyingMessage ? 'not-allowed' : 'pointer' }}

                      >
                        <i className="bi bi-camera-video"></i> Record Video
                      </div>
                      {/* <div
                        className="add-attach-menu-item"
                        onClick={() => setOpenAudioModal(true)}
                    >
                        <i className="bi bi-camera-video"></i> Voice Record
                    </div> */}
                      <div
                        className="add-attach-menu-item"
                        onClick={openCanvasModal}
                      >
                        <i className="bi bi-easel-fill"></i> Whiteboard
                      </div>
                      <div
                        className="add-attach-menu-item"
                        // onClick={togglePollPopup}
                        onClick={() => {
                            if (!replyingMessage) {
                                togglePollPopup()
                            }
                          }}
                          style={{ cursor: replyingMessage ? 'not-allowed' : 'pointer' }}
                      >
                        <i className="bi bi-bar-chart"></i> Create Poll
                      </div>
                      <div
                        className="add-attach-menu-item"
                        // onClick={toggleQuestionPopupVisibility}
                        onClick={() => {
                            if (!replyingMessage) {
                                toggleQuestionPopupVisibility()
                            }
                          }}
                          style={{ cursor: replyingMessage ? 'not-allowed' : 'pointer' }}
                      >
                        {/* <i className="bi bi-bar-chart"></i> Create Question
                         */}
                        <i className="bi bi-question-circle"></i> Create
                        Question
                      </div>
                    </div>
                    <textarea
                      style={textareaStyle}
                      className="form-control chat-text-area"
                      placeholder="Write something and send..."
                      value={chatMessage || ""}
                      onChange={handleChatSendMessage}
                      onKeyPress={handleEnterKeyPress}
                      disabled={activeChatGroupData?.freeze}
                      ref={inputRef}
                    ></textarea>
                    <button
                      className="btn bg-app-default-color app-content-footer-send"
                      disabled={!chatMessage}
                      type="submit"
                    >
                      <i className="bi bi-send"></i>
                    </button>
                    <button
                      className="btn bg-app-default-color app-content-footer-voice"
                      //   disabled={!chatMessage}
                      type="button"
                      onClick={() => setOpenAudioModal(true)}
                      disabled={activeChatGroupData?.freeze}
                    >
                      <i className="bi bi-mic"></i>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>

          {shareModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-ws-share-main-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="iir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={shareCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Share Case
                    </h3>
                  </div>
                  <div className="ir-share-modal-inside-content">
                    <p>Invite your friends through social network</p>
                    <div className="ir-share-container">
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        <FacebookShareButton
                          url={shareUrl}
                          quote={"Dummy text!"}
                          hashtag="#ir4u"
                        >
                          <FacebookIcon size={50} round />
                        </FacebookShareButton>
                      </div>
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        <WhatsappShareButton
                          url={shareUrl}
                          quote={"Dummy text!"}
                        >
                          <WhatsappIcon size={50} round />
                        </WhatsappShareButton>
                      </div>
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        {!tooltipText ? (
                          <div
                            className="ir-share-icon-copy-link ir-share-icon-link"
                            onClick={handleCopyLink}
                            title="Copy Link"
                          >
                            <i className="bi bi-link-45deg"></i>
                          </div>
                        ) : (
                          <div
                            className="ir-share-icon-copy-link ir-share-icon-link"
                            title="Link Copied"
                          >
                            <i class="bi bi-check-lg"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openCamModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={camCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Record Video
                    </h3>
                  </div>
                  <div className="ir-ws-signup-form-container ir-ws-chat-recorded-video">
                    <div className="ir-share-container">
                      <div className="audio-controls">
                        <video
                          className="ir-ws-width-100 ir-ws-height-100-percent ir-ws-video-upload"
                          style={{
                            display:
                              recordingStatus === "recording"
                                ? "block"
                                : "none",
                          }}
                          ref={liveVideoFeedRef}
                        ></video>
                      </div>
                      {recordedVideo ? (
                        <div className="audio-player">
                          <video
                            className="ir-ws-width-100 ir-ws-height-100-percent ir-ws-video-upload"
                            src={recordedVideo}
                            autoPlay
                            controls
                          ></video>
                        </div>
                      ) : null}
                    </div>
                    <div className="ir-modal-btn-container record-btn-container">
                      {!permission && !recordedVideo ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={getCameraPermission}
                          >
                            <span>Get Permissions</span>
                          </button>
                        </div>
                      ) : null}
                      {permission && recordingStatus === "inactive" ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={startRecording}
                          >
                            <span>Start recording</span>
                          </button>
                        </div>
                      ) : null}
                      {recordingStatus === "recording" ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={stopRecording}
                          >
                            <span>Stop recording</span>
                          </button>
                        </div>
                      ) : null}
                      {recordedVideo ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={submitVideo}
                          >
                            <span>Upload Recording</span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Modal for Audio Recording */}

          {openAudioModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={audioCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Record Audio
                    </h3>
                  </div>
                  <div className="ir-ws-signup-form-container">
                    <div className="ir-share-container">
                      <div className="audio-controls">
                        <audio
                          className="ir-ws-width-100 ir-ws-audio-upload"
                          style={{
                            display:
                              audioRecordingStatus === "recording"
                                ? "block"
                                : "none",
                          }}
                          ref={liveAudioFeedRef}
                        ></audio>
                      </div>

                      {/* Recording wave animation */}
                      <div
                        className={`recording-wave-container ${
                          audioRecordingStatus === "recording"
                            ? "recording-active"
                            : "recording-inactive"
                        }`}
                      >
                        <div className="recording-wave">
                          <div className="wave-bar" style={{ "--i": 1 }}></div>
                          <div className="wave-bar" style={{ "--i": 2 }}></div>
                          <div className="wave-bar" style={{ "--i": 3 }}></div>
                          <div className="wave-bar" style={{ "--i": 4 }}></div>
                          <div className="wave-bar" style={{ "--i": 5 }}></div>
                          <div className="wave-bar" style={{ "--i": 6 }}></div>
                          <div className="wave-bar" style={{ "--i": 7 }}></div>
                          <div className="wave-bar" style={{ "--i": 8 }}></div>
                        </div>
                      </div>

                      {recordedAudio ? (
                        <div className="audio-player">
                          <audio
                            className="ir-ws-width-100 ir-ws-audio-upload"
                            src={recordedAudio}
                            controls
                          ></audio>
                        </div>
                      ) : null}
                    </div>
                    <div className="ir-modal-btn-container record-btn-container">
                      {!audioPermission && !recordedAudio ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={getAudioPermission}
                          >
                            <span>Get Permissions</span>
                          </button>
                        </div>
                      ) : null}

                      {audioPermission &&
                      audioRecordingStatus === "inactive" ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={startAudioRecording}
                          >
                            <span>Start Recording</span>
                          </button>
                        </div>
                      ) : null}

                      {audioRecordingStatus === "recording" ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={stopAudioRecording}
                          >
                            <span>Stop Recording</span>
                          </button>
                        </div>
                      ) : null}

                      {recordedAudio ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={submitAudio}
                          >
                            <span>Upload Recording</span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openWhiteBoardModal && (
            <div className="ir-whiteboard-modal">
              <div className="ir-whiteboard-modal-container">
                <div className="ir-whiteboard-modal-content">
                  <div className="ir-form-title">
                    <button onClick={whiteBoardCloseModalHandle}>
                      <span className="material-symbols-outlined">
                        arrow_back
                      </span>
                    </button>
                    <h2>Whiteboard</h2>
                  </div>
                  <div className="ir-whiteboard-modal-inside-content">
                    <div className="ir-share-container">
                      <WhiteBoard
                        isUpload={isUpload}
                        updateIsUpload={(data) => setIsUpload(data)}
                        uploadFile={(file) => whiteBoardSubmit(file)}
                        closeModel={setOpenWhiteBoardModal}
                        submitImage={submitImage}
                      />
                      <div className="ir-modal-btn-container ir-wbd-upload-btn-container">
                        <button
                          className="btn btn-submit"
                          onClick={() => {
                            setIsUpload(true);
                          }}
                        >
                          <span className="material-symbols-outlined">
                            done
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openImageModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container ir-ws-chat-upload-img-modal-container ir-ws-edit-img-upload-modal">
                <div className="iks-mint-modal-content">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={imageCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-title-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Upload Image
                    </h3>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <div className="ir-share-container-image-modal">
                      {/* Image Handling */}
                      {selectedImage[0]?.name.match(
                        /\.(jpg|jpeg|png|gif|bmp|tiff|tif|webp)$/
                      ) && (
                        <>
                          {showSavedEditedImage === false ? (
                            <FilerobotImageEditor
                              source={URL.createObjectURL(selectedImage[0])}
                              Text={{
                                text: "Double click to add text ",
                                fontSize: 22,
                              }}
                              onBeforeSave={(imageFileInfo) => {
                                console.log(
                                  "Image info before save:",
                                  imageFileInfo
                                );
                                return false; // Prevents the modal from appearing
                              }}
                              onSave={(editedImageObject) => {
                                setSavedEditedImage(editedImageObject);
                                setShowSavedEditedImage(true);
                              }}
                              defaultSavedImageType="jpeg" // Always save as JPEG
                              defaultSavedImageQuality={1} // Set quality to 80%
                              Pen={{
                                selectAnnotationAfterDrawing: true,
                              }}
                              tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
                              Rotate={{ angle: 90, componentType: "slider" }}
                              Crop={{ noPresets: true, ratio: "custom" }}
                            />
                          ) : (
                            <div className="ir-chat-content-edited-img-main-cont">
                              <div className="ir-chat-content-edited-image-cont">
                                <img
                                  src={savedEditedImage.imageBase64}
                                  className="ir-chat-cont-edited-img"
                                  alt="edited img"
                                />
                              </div>
                              <div className="ir-chat-cont-img-input-cont">
                                <input
                                  type="text"
                                  className="ir-ws-clinical-note-input-field"
                                  placeholder="Add clinical note"
                                  value={clinicalNote}
                                  onChange={handleClinicalNoteInputChange}
                                />
                                <p
                                  className="ir-chat-cont-msg-send-btn"
                                  onClick={sendEditedImgMsg}
                                >
                                  {sendingImg ? "Sending..." : "Send"}
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openVideoModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container ir-ws-chat-upload-img-modal-container">
                <div className="iks-mint-modal-content">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={imageCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-title-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title ir-ws-upload-video-text">
                      Upload Video
                    </h3>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <div className="ir-share-container">
                      {/* Video Handling */}
                      {convertedVideoURL === null ? (
                        <Progressbar progress={progress} />
                      ) : (
                        <>
                          {selectedImage[0]?.type.startsWith("video/") && (
                            <div className="ir-ws-video-trim-container">
                              {isTrimming ? (
                                <div className="ir-ws-video-trim-wrapper">
                                  <video
                                    id="videoElement"
                                    className="video-js ir-ws-video-preview"
                                    controls
                                    onLoadedMetadata={(e) => {
                                      setVideoDuration(e.target.duration);
                                      setTrimRange([0, e.target.duration]);
                                    }}
                                    src={convertedVideoURL}
                                  />
                                  <div className="ir-ws-trim-slider-container">
                                    <Slider
                                      range
                                      min={0}
                                      max={
                                        videoDuration > 0 ? videoDuration : 1
                                      }
                                      step={videoDuration > 60 ? 0.1 : 0.01}
                                      value={trimRange}
                                      onChange={(values) => {
                                        const newStart = parseFloat(values[0]);
                                        const newEnd = parseFloat(values[1]);
                                        setTrimRange([newStart, newEnd]);
                                        updateVideoPreview(newStart, newEnd);
                                      }}
                                      onAfterChange={(values) => {
                                        const newStart = parseFloat(values[0]);
                                        const newEnd = parseFloat(values[1]);
                                        setTrimRange([newStart, newEnd]);
                                      }}
                                    />
                                    <div className="ir-ws-slider-labels">
                                      <span>
                                        Start: {formatTime(trimRange[0])}
                                      </span>
                                      <span>
                                        End: {formatTime(trimRange[1])}
                                      </span>
                                    </div>
                                  </div>
                                  <p className="ir-ws-trim-title">
                                    Trim your video before uploading
                                  </p>
                                  <div className="ir-ws-trim-button-container">
                                    <button
                                      className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-trim-btn"
                                      onClick={trimVideo}
                                      disabled={
                                        isTrimmedVideoConverted === false
                                      }
                                    >
                                      {isTrimmedVideoConverted === false ? (
                                        <span>
                                          <i className="bi bi-hourglass-split"></i>{" "}
                                          Trimming...
                                        </span>
                                      ) : (
                                        <span>Trim Video</span>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div className="ir-ws-video-wrapper">
                                  <video
                                    className="ir-ws-video-preview"
                                    controls
                                    src={convertedVideoURL}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                      {uploaded && (
                        <div className="progress mt-2">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={uploaded}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: `${uploaded}%` }}
                          >
                            {`${uploaded}%`}
                          </div>
                        </div>
                      )}
                      {convertedVideoURL !== null && !isTrimming && (
                        <div className="ir-ws-text-center ir-ws-default-btn-container trim-upload-btn">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            disabled={disableUploadBtn}
                            onClick={submitImage}
                          >
                            <span>Upload</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openAttachDocsModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-attach-document-modal">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={attachDocsCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Upload Documents
                    </h3>
                  </div>
                  <div className="ir-share-modal-inside-content">
                    <p className="ir-ws-app-color ir-ws-font-size-xs">
                      {selectedDoc.name}
                    </p>
                    <div className="ir-ws-text-center ir-share-container">
                      <img
                        className="ir-ws-block ir-ws-margin-o-auto ir-modal-chat-preview"
                        src={require("../../../assets/images/chat-images/document-icon.png")}
                        alt="file-preview"
                      />
                      <span className="ir-ws-block ir-ws-font-size-xxs ir-chat-modal-file-size">
                        {selectedDoc.size / 1000} kB
                      </span>
                    </div>
                    <div className="ir-ws-text-center ir-ws-default-btn-container">
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                        onClick={uploadDocument}
                      >
                        <span>Upload</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openImageGalleryModal && (
            <div className="iks-mint-cust-modal ir-chat-inside-modal-container ir-image-preview-main-modal">
              <div className="iks-mint-modal-container">
                <div className="ir-thumb-image-inner-container">
                  <div className="ir-image-large-thumb-title-container">
                    <h3>{activeCaseName}</h3>
                    <div className="ir-image-head-thumb-right">
                      <button onClick={closeImageGalleryModal}>
                        <i className="bi bi-x-lg"></i>
                      </button>
                    </div>
                  </div>
                  <div className="ir-modal-thumb-container-large-image">
                    {chatHistory?.[props.activeChatRoom] &&
                      chatHistory[props.activeChatRoom].length > 0 &&
                      chatHistory[props.activeChatRoom]
                        .filter((i) => i.images || i.videos)
                        .map((item, i) => {
                          const isImage =
                            item.images &&
                            /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(item.images);
                          const isVideo =
                            item.videos &&
                            /\.(mp4|mov|wmv|avi|webm|mkv)$/i.test(item.videos);

                          return (
                            <div key={i}>
                              <div
                                className={`iks-cine-content iks-cine-content-${i} ${
                                  i === carouselActiveIndex
                                    ? "iks-carousel-active"
                                    : "iks-carousel-inactive"
                                }`}
                              >
                                <AssetRender
                                  data={{
                                    images: isImage ? item.images : "",
                                    videos: isVideo ? item.videos : "",
                                  }}
                                  imageClicked={(data) => {
                                    if (isImage) onCarouselMenuItemclick(i);
                                    if (isVideo) onCarouselMenuItemclick(i);
                                  }}
                                  options={{
                                    fullScreen: false,
                                    height: "100%",
                                    videoControl: true,
                                  }}
                                />

                                {isImage && (
                                  <div className="chat-img-edit-btn">
                                    <button
                                      className="btn"
                                      onClick={() =>
                                        handleEditClick(item.images)
                                      }
                                    >
                                      <i className="bi bi-pencil"></i>
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}

                    <button
                      onClick={leftCarouselIndex}
                      disabled={prevButtonDisable}
                      className={`ir-left-car-btn ir-carousel-action-btns`}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                      onClick={rightCarouselIndex}
                      disabled={nextButtonDisable}
                      className={`ir-right-car-btn ir-carousel-action-btns`}
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                  <div className="ir-thumbanil-modal-image">
                    {chatHistory?.[props.activeChatRoom] &&
                      chatHistory[props.activeChatRoom].length > 0 &&
                      chatHistory[props.activeChatRoom]
                        .filter((i) => i.images || i.videos)
                        .map((item, i) => {
                          const isImage =
                            item.images &&
                            /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(item.images);
                          const isVideo =
                            item.videos &&
                            /\.(mp4|mov|wmv|avi|webm|mkv)$/i.test(item.videos);

                          return (
                            <div
                              key={i}
                              onClick={() => onCarouselMenuItemclick(i)}
                              className={`ir-car-item-container ${
                                i === carouselActiveIndex
                                  ? "iks-carousel-item-active"
                                  : "iks-carousel-item-inactive"
                              }`}
                            >
                              <AssetRender
                                data={{
                                  images: isImage ? item.images : "",
                                  videos: isVideo ? item.videos : "",
                                }}
                                imageClicked={(data) =>
                                  onCarouselMenuItemclick(i)
                                }
                                options={{
                                  fullScreen: false,
                                  videoControl: false,
                                }}
                                className="ir-car-item-img"
                              />
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {isPopupOpen && (
            <div className="poll-popup">
              <div className="poll-popup-content">
                <div className="poll-header">
                  <h2 className="poll-heading">Create Poll</h2>
                </div>
                <label className="poll-question-label">
                  Question
                  <input
                    type="text"
                    className="poll-question-input"
                    value={question}
                    onChange={handleQuestionChange}
                    placeholder="Ask question"
                  />
                </label>
                <div className="poll-options-container">
                  <label className="poll-question-label">Options</label>
                  <div
                    className="poll-options-list"
                    onDragOver={(e) => e.preventDefault()}
                  >
                    {options.map((option, index) => (
                      <div
                        key={index}
                        className="poll-option-wrapper"
                        draggable
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDrop={(e) => handleDrop(e, index)}
                      >
                        <div className="option-input-cont">
                          <input
                            type="text"
                            className="poll-option-input"
                            value={option}
                            onChange={(e) =>
                              handleOptionChange(index, e.target.value)
                            }
                            placeholder="Add"
                          />

                          {duplicateError === index && (
                            <p className="poll-error-message">
                              Option "{option}" already exists.
                            </p>
                          )}
                        </div>
                        <i className="bi bi-arrows-move poll-option-drag-icon"></i>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="poll-buttons-container">
                  <div className="poll-submit-button">
                    <button
                      className="btn btn-primary bg-app-default-color btn-lg btn-block ir-btn-default ir-create-case-btn"
                      id="newChat"
                      onClick={handlePollCreationSubmit}
                    >
                      <span>
                        <i className="bi bi-bar-chart"></i> Create
                      </span>
                    </button>
                  </div>

                  <button
                    className="poll-cancel-button"
                    onClick={togglePollPopup}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>
          )}

{isQuestionPopupVisible && (
  <div
    className="create-question__popup"
    onClick={(e) => {
      if (e.target.classList.contains("create-question__popup")) {
        toggleQuestionPopupVisibility();
      }
    }}
  >
    <div className="create-question__popup-content h70">
      <h3 className="create-question__popup-title">Create Question</h3>

      <textarea
        className="create-question__textarea"
        name="questionText"
        placeholder="Enter your question here"
        value={questionDetails.questionText}
        onChange={handleQuestionInputChange}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${e.target.scrollHeight}px`;
        }}
        rows={4}
      />

      {/* Image Preview */}
      {questionDetails.questionImagePreview && (
        <div className="create-question__image-preview">
          <img
            src={questionDetails.questionImagePreview}
            alt="Uploaded"
            className="create-question__preview-img"
          />
          <button
            className="create-question__remove-img"
            onClick={handleImageRemove}
          >
            &times;
          </button>
        </div>
      )}

      {/* File Input */}
      <div className="create-question__file-input-container">
        <input
          id="questionFile"
          className="create-question__file-input"
          type="file"
          accept="image/*"
          onChange={handleQuestionImageUpload}
        />
        <label
          htmlFor="questionFile"
          className="create-question__file-label"
        >
          <i className="bi bi-upload me-1"></i> Upload Image
        </label>
      </div>

      {((profile?.firstname === "admin" &&
        profile?.lastname === "admin") ||
        activeRooms?.createdBy === profile.username) && (
        <div className="create-question__checkbox-container">
          <label className="create-question__checkbox-label">
            <input
              className="create-question__checkbox"
              type="checkbox"
              name="isPrimary"
              checked={questionDetails.isPrimary}
              onChange={handleQuestionInputChange}
            />
            Is Primary
          </label>
        </div>
      )}

      <div className="create-question__actions">
        <div className="poll-submit-button">
          <button
            className="btn btn-primary bg-app-default-color btn-lg btn-block ir-btn-default ir-create-case-btn"
            id="newChat"
            onClick={handleQuestionSubmit}
          >
            <span>
              <i className="bi bi-chat-dots me-1"></i> Create
            </span>
          </button>
        </div>
        <button
          className="create-question__cancel-button"
          onClick={toggleQuestionPopupVisibility}
        >
          X
        </button>
      </div>
    </div>
  </div>
)}


          {isSearchMessageByUsernamePopop && (
            <div className="ir-chat-view-more-modal-overlay">
              <div className="ir-chat-view-more-modal-container">
                <button
                  className="ir-chat-view-more-modal-close-btn"
                  onClick={toggleSearchMessagesByUserPopup}
                >
                  &times;
                </button>
                <h3 className="ir-chat-view-more-modal-title">
                  Filter messages by username
                </h3>
                {/* <input
            type="text"
            placeholder="Enter user name..."
            className="ir-chat-view-more-modal-input"
          />
          <button className="ir-chat-view-more-modal-submit-btn">Search</button> */}

                <ul className="ir-chat-view-more-modal-user-list">
                  {groupUsers?.users?.length > 0 ? (
                    groupUsers?.users?.map((user, i) => (
                      <li
                        key={i}
                        className="ir-chat-view-more-modal-user-item"
                        onClick={() => {
                          setFilteredUser(user?.userName || "Deleted User");
                          toggleSearchMessagesByUserPopup();
                        }}
                      >
                        {user?.userName ? user?.userName : "Deleted user"}
                      </li>
                    ))
                  ) : (
                    <p className="ir-chat-view-more-modal-empty">
                      No users found.
                    </p>
                  )}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
      {showModal && (
        <DeleteConfirmation
          toggleModal={handleModalToggle}
          confirmationFunc={() => {
            handleDeleteClick(itemToDelete);
          }}
        />
      )}

      {isImageEditorOpen && (
        <ImageEditorModal
          isOpen={isImageEditorOpen}
          onClose={() => setIsImageEditorOpen(false)}
          onSave={handleSaveEditedImage}
          uploadEditedImage={uploadEditedImage}
          closeImageGalleryModal={closeImageGalleryModal}
        />
      )}
    </div>
  );
};

export default ChatContent;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { signInApi1 } from "../../redux/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./SignIn.scss";
import axios from "axios";
import { Link } from "react-router-dom";

const SignIn = (props) => {
  const dispatch = useDispatch();
  const [isFromAdmin, seIsFromAdmin] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [pathname, setPathname] = useState("");
  const navigate = useNavigate();
  const Admin = localStorage.getItem("loggedInUsername") === "admin";
  const [isChatRoom, setIsChatRoom] = useState(false);
  const referer = document.referrer;
  // console.log(referer);
  // const isFromChatRoom = referer.includes("/chat-room");
  const isInternalReferrer = referer.includes(window.location.origin);
  const isFromSignUp = referer.includes("/signup");

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtTokenApi1");
    if (jwtToken) {
      navigate("/"); 
    }
  }, [navigate]);


  const userProfileDetails = async () => {
    // console.log("pathname of the path is " + pathname);
    try {
      const response = await axios.get(
        `https://backend.ir4u.info/api/v1/user/profile`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      console.log("User profile from 1st api response", response);

      localStorage.setItem("userId", response.data?.id);

      const prevURL = localStorage.getItem('prevURL')
      console.log("Previous URL: " + prevURL);
      const isAdmin = localStorage.getItem("loggedInUsername") === "admin";

      if (response.data?.isEmailVerified) {
        if (prevURL && !isAdmin) {
          navigate(prevURL);
          // navigate("/cases");
          // console.log("Navigating to: " + prevURL);
          localStorage.removeItem('prevURL');
        } else if (!isInternalReferrer || isFromSignUp) {
          console.log("Email is verified, navigating to homepage");
          navigate("/");
          // setPathname("");
        } else if (isChatRoom) {
          // If email is verified and not on the signup page, navigate back to the previous page
          // navigate("/");
          navigate("/chat-room");
          window.location.reload();
        } else {
          navigate(-1);
        }
      } else {
        if (response.data.username !== "admin") {
          Swal.fire("", "Your mail id is not verified please verify");
        }
        // navigate("/");
        if (!isInternalReferrer || isFromSignUp) {
          console.log("this is called");
          navigate("/");
          // setPathname("");
        } else if (isChatRoom) {
          // If email is verified and not on the signup page, navigate back to the previous page
          // navigate("/");
          navigate("/chat-room");
          window.location.reload();
          // console.log(response);
        } else {
          navigate(-1);
          // navigate("/");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Access the "isAdminRedirect" prop in useEffect
    const isAdminRedirect = props.isAdminRedirect;
    const isChatRoomRedirect = props.isChatRoomRedirect;
    if (isAdminRedirect) {
      // Handle logic specific to when redirected from the admin page
      console.log("Redirected from admin page");
      seIsFromAdmin(true);
    }
    if (isChatRoomRedirect) {
      setIsChatRoom(true);
    }
  }, []);
  // console.log(isChatRoom);
  const onChangeUsername = (e) => {
    const Newusername = e.target.value;
    setUsername(Newusername);
    validateForm(Newusername, password);
  };

  const onChangePassword = (e) => {
    const Newpassword = e.target.value;
    setPassword(Newpassword);
    validateForm(username, Newpassword);
  };

  const validateForm = (newUsername, newPassword) => {
    // Your validation logic
    const areAllFieldsFilled = newUsername !== "" && newPassword !== "";
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);

    const [api1Response] = await Promise.all([
        dispatch(signInApi1(formData)),
    ]);

      console.log("api1Response", api1Response);

      // Handle responses for API 1
      if (
        api1Response.payload.headers["authorization"]
      ) {
        // Extract data or tokens from the response
        const jwtTokenApi1 =
          api1Response.payload.headers["authorization"].split("Bearer ")[1];

        const isAdmin = username === "admin";

        // Handle success (redirect, show success message)
        localStorage.setItem("jwtTokenApi1", jwtTokenApi1);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("loggedInUsername", username);
        // console.log("jwt token1", jwtTokenApi1);

        // Get the previous URL from localStorage
        const prevURL = localStorage.getItem('prevURL');
        // console.log("prevURL: ", prevURL);

        if (isAdmin && isFromAdmin) {
          navigate("/admin");
          window.location.reload();
        } else if (username !== "admin" || isChatRoom) {
          userProfileDetails();
        } else if (prevURL) {
          navigate(prevURL);
          localStorage.removeItem('prevURL');
        } else {
          navigate("/");
        }
      } else {
        Swal.fire("", "Invalid credentials", "error");
      }
    } catch (error) {
      Swal.fire("", "Incorrect username or password", "error");
      console.log("This is the error", error.response);
    }
  };

  return (
    <div className="ir-ws-sign-in-main-container">
      <div className="ir-ws-signin-inner-container ir-ws-sign-inner-container">
        <div className="ir-ws-sign-in-content-container">
          <div className="ir-ws-signin-logo-container">
            <div className="ir-ws-text-center ir-ws-signin-logo-inner-container">
              <a href="/">
                <img
                  className="ir-ws-signin-logo-img"
                  src={require("../../assets/images/ir4u2.png")}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <form onSubmit={handleLogin}>
            <div className="ir-ws-signup-content-inner-container">
              <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                Get Started
              </h3>
            </div>
            <div className="ir-ws-signup-form-container">
              <div className="ir-ws-signup-content-group">
                <input
                  className={"ir-ws-signup-input-field"}
                  type="text"
                  required="required"
                  autoComplete="off"
                  value={username}
                  name="username"
                  onChange={onChangeUsername}
                />
                <label className="ir-ws-signup-label">Username</label>
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
              </div>
              <div className="ir-ws-signup-content-group">
                <input
                  className="ir-ws-signup-input-field"
                  type="password"
                  required="required"
                  autoComplete="off"
                  value={password}
                  onChange={onChangePassword}
                />
                <label className="ir-ws-signup-label">Password</label>
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
              </div>
              <div className="ir-ws-sign-footer-lin-container ir-forgot-password-link">
                <p
                  className="ir-ws-app-color"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password?
                </p>
              </div>
              <div
                className={`ir-ws-text-center ir-ws-default-btn-container  ir-ws-register-button-container `}
              >
                <button
                  className={`ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws-sign-button `}
                  style={{ width: "170px", height: "58px" }}
                >
                  <span>Sign In</span>
                </button>
              </div>
            </div>
          </form>
          <div className="ir-ws-signup-form-footer-container">
            <div className="ir-ws-login-social-container"></div>
            <div className="ir-ws-sign-footer-lin-container">
              <p className="ir-default-color">
                Not having an account? &nbsp;
                <b>
                  <Link to={"/signup"} className="ir-ws-app-color">
                    SignUp
                  </Link>
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* {showEmailPopUp && !isAdmin && !isEmailVerified && (
        <EmailPopUp username={username} />
      )} */}
    </div>
  );
};

export default SignIn;

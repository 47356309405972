import React from "react";
// import './AllVoters.scss';

const AllVoters = ({ chatHistory, selectedPollId, onClose }) => {
    // Find the poll data by ID
    const pollData = chatHistory.find(
        (chat) => chat?.poll?.ID === selectedPollId
    );
console.log("pollData:", pollData);
    if (!pollData) {
        return (
            <div className="all-voters-container">
                <div className="all-voters-header">
                    <h4>All Voters</h4>
                    <button className="close-icon" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <p>No poll data found.</p>
            </div>
        );
    }

    return (
        <div className="all-voters-container">
            <div className="all-voters-header">
                <h4 className="all-voters-title">All Voters</h4>
                <button className="close-icon" onClick={onClose}>
                    &times;
                </button>
            </div>
            {pollData?.poll?.pollOptions?.map((option) => (
                <div key={option.ID} className="voter-list-option">
                    <div className="option-title">

                        <h4 className="option-title-text">{option.text}</h4>
                        {option.voters?.length && <span className="votes-count">{option.voters?.length} Votes</span>}
                    </div>
                    <div className="voter-list">
                        {option.voters?.length > 0 ? (
                            option.voters.map((voter, index) => (
                                <div key={index} className="voter-item">
                                    {voter?.profileImage ? (
                                        <img
                                            src={voter.profileImage}
                                            alt={voter?.userName || "User"}
                                            className="voter-profile-pic"
                                        />
                                    ) : (
                                        <span className="voter-profile-pic">
                                            {voter?.userName ? voter.userName.slice(0, 2).toUpperCase() : "NA"}
                                        </span>
                                    )}

                                    <span className="voter-username">{voter?.userName || "Anonymous"}</span>
                                </div>
                            ))
                        ) : (
                            <div className="no-votes">No votes yet</div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AllVoters;

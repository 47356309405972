import { useEffect, useContext } from 'react';
import { RootStore } from "./store/store";

let ws = null;
let reconnectTimeout = null;

const WebSocketService = () => {
    const [store, setStore] = useContext(RootStore);
    const { chatStore, userStore } = store;

    // Function to update chat history without mutating state directly
    const updateChatStore = (roomId, message) => {
        setStore((prevStore) => {
            const updatedChatHistory = { ...prevStore.chatStore.chatHistory };
            // Initialize an array for this room if it doesn't exist
            if (!updatedChatHistory[roomId]) {
                updatedChatHistory[roomId] = [];
            }
            // Append the new message to the array for this room
            updatedChatHistory[roomId] = [...updatedChatHistory[roomId], message];

            return {
                ...prevStore,
                chatStore: {
                    ...prevStore.chatStore,
                    chatHistory: updatedChatHistory,
                },
            };
        });
    };

    const updatePollVotes = (chat) => {
        const { poll } = chat;
        const pollId = poll.ID;

        setStore((prevStore) => {
            const updatedChatHistory = { ...prevStore.chatStore.chatHistory };

            // Traverse chat history to find and update the relevant poll
            Object.keys(updatedChatHistory).forEach((roomId) => {
                updatedChatHistory[roomId] = updatedChatHistory[roomId].map((message) => {
                    if (message.poll?.ID === pollId) {
                        console.log("Updating poll in message:", message);

                        // Replace the entire poll with the updated one
                        return { ...message, poll };
                    }
                    return message;
                });
            });

            console.log("Updated chat history:", updatedChatHistory);

            return {
                ...prevStore,
                chatStore: { ...prevStore.chatStore, chatHistory: updatedChatHistory },
            };
        });
    };

    // Update reactions in the store

    const updateReactions = (chat) => {
        const { chatId, reactions } = chat;

        setStore((prevStore) => {
            const updatedChatHistory = { ...prevStore.chatStore.chatHistory };

            // Traverse chat history to find and update the relevant message by chatId
            Object.keys(updatedChatHistory).forEach((roomId) => {
                updatedChatHistory[roomId] = updatedChatHistory[roomId].map((message) => {
                    if (message.chatId === chatId) {
                        console.log("Updating reactions for message:", message);

                        // Replace the reactions field with the updated one
                        return { ...message, reactions };
                    }
                    return message;
                });
            });

            console.log("Updated chat history with reactions:", updatedChatHistory);

            return {
                ...prevStore,
                chatStore: { ...prevStore.chatStore, chatHistory: updatedChatHistory },
            };
        });
    };


const removeMessageFromStore = (roomId, chatId) => {
    setStore((prevStore) => {
        console.log("Room ID:", roomId);
        console.log("Chat ID to remove:", chatId);

        const updatedChatHistory = { ...prevStore.chatStore.chatHistory };

        if (updatedChatHistory[roomId]) {
            console.log("Messages before filter:", updatedChatHistory[roomId]);

            // Filter messages where the chatId matches
            updatedChatHistory[roomId] = updatedChatHistory[roomId].filter((message) => {
                // console.log("Comparing:", String(message.chatId), String(chatId));
                return String(message.chatId) !== String(chatId); // Convert to strings for comparison
            });

            console.log("Messages after filter:", updatedChatHistory[roomId]);
        } else {
            console.warn("Room ID not found in chat history.");
        }

        return {
            ...prevStore,
            chatStore: {
                ...prevStore.chatStore,
                chatHistory: updatedChatHistory,
            },
        };
    });
};



    const connectToWebSocket = (groupID, username) => {
        // Close any existing WebSocket connection
        if (ws) {
            ws.onmessage = null;
            ws.onclose = null;
            ws.onerror = null;
            ws.close(); // Close the existing WebSocket
            ws = null;
        }

        // const wsUrl = `wss://chats.ir4u.info/ws?group=${groupID}`;
        // const wsUrl = `wss://chats.ir4u.info/ws?group=${groupID}&username=${username}`;
        const wsUrl = `wss://staging-chats.ir4u.info/ws?group=${groupID}&username=${username}`;

        ws = new WebSocket(wsUrl);

        ws.onopen = () => {
            console.log(`Connected to WebSocket for group ${groupID}`);
            clearTimeout(reconnectTimeout);
            reconnectTimeout = null;
        };


        // ws.onmessage = (event) => {
        //     const data = JSON.parse(event.data);
        //     const roomId = data?.groupId;

        //     console.log("WB Response:", data);

        //     if (data.event === "reactionUpdate") {
        //         updateReactions(data.chat);
        //         console.log("Reactions received:", data);
        //     } else if (data.event === "updatedVoteCount") {
        //         updatePollVotes(data.chat);
        //         console.log("Poll received:", data);
        //     } else {
        //         updateChatStore(roomId, data);
        //         console.log("Message received:", data);
        //     }
        // };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const roomId = data?.groupId;

            console.log("WB Response:", data);

            switch(data.event) {
                case "reactionUpdate":
                    updateReactions(data.chat);
                    console.log("Reactions received:", data);
                    break;
                case "updatedVoteCount":
                    updatePollVotes(data.chat);
                    console.log("Poll received:", data);
                    break;
                case "deleteMessage":
                    removeMessageFromStore(data.groupId, data.chatId);
                    console.log("Message deleted:", data);
                    break;
                default:
                    updateChatStore(roomId, data);
                    console.log("Message received:", data);
            }

            // if (data.event === "reactionUpdate") {
            //     updateReactions(data.chat);
            //     console.log("Reactions received:", data);
            // } else if (data.event === "updatedVoteCount") {
            //     updatePollVotes(data.chat);
            //     console.log("Poll received:", data);
            // } else if (data.event === "deleteMessage") {
            //     // handleDeleteMessage(roomId, data.chatId);
            //     removeMessageFromStore(roomId, data.chatId);
            //     console.log("Message deleted:", data);
            // } else {
            //     updateChatStore(roomId, data);
            //     console.log("Message received:", data);
            // }
        };


        ws.onclose = () => {
            console.log(`WebSocket disconnected for group ${groupID}`);
            reconnectTimeout = setTimeout(() => {
                console.log("Reconnecting to WebSocket...");
                connectToWebSocket(groupID);
            }, 2000); // Reconnect after 2 seconds
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
            // ws.close();
        };
    };


    // Function to send a message through WebSocket
    const sendMessage = (message) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            try {
                ws.send(JSON.stringify(message));
                console.log("Message sent:", message);
            } catch (error) {
                console.error("Failed to send message:", error);
            }
        } else {
            console.warn("WebSocket is not open. Message not sent.");
        }
    };


 const sendReactionToMessage = (chatID, emoji, userName, profileImage) => {
    const payload = {
        reaction: {
            chatID,
            emoji,
            userName,
            profileImage,
        },
    };

    try {
        ws.send(JSON.stringify(payload));
        console.log("Reaction sent:", payload);
    } catch (error) {
        console.error("Failed to send reaction:", error);
    }
};

const sendVote = (pollId, optionId, username, profileImage) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
        const payload = {
            voting: {
                chatID: pollId,
                optionID: optionId,
                username,
                profileImage,
            },
        };

        try {
            ws.send(JSON.stringify(payload));
            console.log("Vote sent successfully:", payload);
        } catch (error) {
            console.error("Error sending vote:", error);
        }
    } else {
        console.warn("WebSocket is not open. Cannot send vote.");
    }
};



const deleteMessage = (chatId) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
        const payload = {
            delete: {
                chatId,
            },
        };

        try {
            ws.send(JSON.stringify(payload));
            console.log("Delete message request sent:", payload);
        } catch (error) {
            console.error("Failed to send delete message request:", error);
        }
    } else {
        console.warn("WebSocket is not open. Cannot delete message.");
    }
};



    // Function to close WebSocket connection
    const closeWebSocket = () => {
        if (ws) {
            ws.close();
            ws = null;
        }
    };

    // useEffect(() => {
    //     return () => {
    //         closeWebSocket();
    //     };
    // }, []);

    // Return connectToWebSocket, sendMessage, and closeWebSocket to be used externally
    return { connectToWebSocket, sendMessage, sendReactionToMessage, sendVote, deleteMessage, closeWebSocket };
};

export default WebSocketService;

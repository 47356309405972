import { useState } from "react";
import "./WebinarAdminSideNavigation.scss";
import WebinarNavImg from "../../assets/images/webinar-admin.png";
import WebinarNavImgActive from "../../assets/images/webinar-admin-active.png";
import CoursesNavImg from "../../assets/images/courses-admin.png";
import CoursesNavImgActive from "../../assets/images/courses-admin-active.png";
import CasesNavImg from "../../assets/images/cases-admin.png";
import CasesNavImgActive from "../../assets/images/cases-admin-active.png";
import ChatAdmin from "../../assets/images/chat-admin-active.png";
import ChatAdminActive from "../../assets/images/chat-admin.png";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";

const WebinarAdminSideNavigation = () => {
  const { setActiveAltTitle, activeAltTitle } = useModalContext();
  const navigate = useNavigate();
  const ChatNavValues = [
    {
      id: 1,
      alttitle: "Webinar",
      img: WebinarNavImg,
      imgActive: WebinarNavImgActive,
    },
    {
      id: 2,
      alttitle: "Chat Rooms",
      img: ChatAdmin,
      imgActive: ChatAdminActive,
    },
    {
      id: 3,
      alttitle: "Courses",
      img: CoursesNavImg,
      imgActive: CoursesNavImgActive,
    },
    {
      id: 4,
      alttitle: "Cases",
      img: CasesNavImg,
      imgActive: CasesNavImgActive,
    },
    {
      id: 5,
      alttitle: "User",
      img: WebinarNavImg,
      imgActive: WebinarNavImgActive,
    },
  ];
  const handleLogout = () => {
    // localStorage.removeItem("jwtTokenApi2");
    localStorage.removeItem("jwtTokenApi1");
    localStorage.removeItem("loggedInUsername");
    localStorage.removeItem("registeredUsername");
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("isRegistered", false);
    navigate("/");
  };

  return (
    <div className=" ir-ws-flex ir-admin-navigation-container">
      <div className="navbar-brand-box">
        <a
          className="ir-ws-block ir-ws-text-center ir-txt-decrtn-none ir-ws-no-bg ir-ws-admin-logo-container"
          href="/"
        >
          <img
            className="ir-bg-white ir-admin-dashboard-logo"
            src={require("../../assets/images/chat-images/ir4u2.png")}
            alt="logo"
          />
          <p className="ir-ws-text-center ir-color-white">IR4U.info</p>
        </a>
      </div>
      <ul className="ir-ws-list-style-none ir-ws-admin-side-menu-nav">
        {ChatNavValues.map((val, id) => {
          if (val.id !== 6) {
            return (
              <li
                key={id}
                className={`ir-ws-position-relative ir-ws-admin-side-nav-item ${
                  activeAltTitle === val.alttitle ? "activeMenuItem" : ""
                }`}
                id={val.alttitle}
                onClick={() => setActiveAltTitle(val.alttitle)}
              >
                <a
                  className={`ir-ws-flex ir-ws-justify-left ir-ws-align-center ir-ws-position-relative ir-ws-admin-side-nav-item-link ${
                    activeAltTitle === val.alttitle
                      ? "active alttitle-text"
                      : ""
                  }`}
                >
                  {activeAltTitle === val.alttitle ? (
                    <img
                      className="ir-ws-admin-side-nav-list-icon"
                      src={val.imgActive}
                      alt={val.alttitle}
                    />
                  ) : (
                    <img
                      className="ir-ws-admin-side-nav-list-icon"
                      src={val.img}
                      alt={val.alttitle}
                    />
                  )}

                  <span className="ir-ws-admin-side-nav-list-text">
                    {val.alttitle}
                  </span>
                </a>
              </li>
            );
          }
        })}

        <li
          className="ir-ws-admin-side-nav-item ir-ws-admin-side-nav-profile"
          id="Profile"
        >
          <div className="ir-ws-an-profile-container">
            <img
              src={require("../../assets/images/adminDp.jpg")}
              className="ir-ws-admin-profile-pic"
            />
          </div>
        </li>
        <li className="ir-ws-admin-side-nav-item ir-ws-admin-side-nav-profile">
          <div className="ir-ws-an-profile-content">
            <span>Admin</span>
          </div>
        </li>
        <li className="ir-ws-admin-side-nav-item logout-button">
          <button
            className="ir-bg-white ir-ws-text-uppercase ir-ws-no-border ir-ws-admin-logout-btn"
            onClick={handleLogout}
          >
            {/* <img src={require('../../assets/images/chat-images/log-nav.png')} alt="Logout" />  */}
            <span>Logout</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default WebinarAdminSideNavigation;

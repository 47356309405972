import React from 'react';
// import './AssetRender.scss';

const MiniAssetRender = ({ data, options = { width: 80, height: 80 } }) => {
    let renderContent = null;

    if (data.message) {
        renderContent = <p className="chat-message">{data.message}</p>;
    }

    if (data.images) {
        renderContent = (
            <img
                className="chat-image"
                src={data.images}
                alt="Image Preview"
                style={{ height: options.height, width: 'auto' }}
            />
        );
    }

    if (data.videos) {
        renderContent = (
            <div className="video-preview-container">
                <video
                    src={data.videos}
                    height={options.height}
                    muted
                    controls={false}
                    style={{ pointerEvents: 'none' }}
                />
                <div className="video-overlay">Video Preview</div>
            </div>
        );
    }

    if (data.documents) {
        const ext = data.documents.split(".");
        const [,,, fileNameDetails, fileExtension] = ext;
        const fileName = fileNameDetails.split('/')[3];
        const truncatedFileName = data.documents.split('/').pop().slice(0, 18) + (data.documents.split('/').pop().length > 18 ? ' ..' : '');


        renderContent = (
            <div className="ir-chat-list-container-documents">
                <div className="doc-file-type-container">
                    <i className={`${fileExtension === 'pdf'
                        ? 'bi-filetype-pdf'
                        : fileExtension === 'doc'
                        ? 'bi-filetype-doc'
                        : fileExtension === 'docx'
                        ? 'bi-filetype-docx'
                        : fileExtension === 'xlsx'
                        ? 'bi-filetype-xlsx'
                        : 'bi-filetype-xls'
                    } doc-file-type bi`}></i>
                </div>
                <div className="ir-chat-doc">{truncatedFileName}</div>
                <div className="ir-chat-doc">.{fileExtension}</div>
                {/* <a href={data.documents} download>
                    <i className="bi bi-download"></i>
                </a> */}
            </div>
        );
    }

    if (data.audio) {
        renderContent = (
            <div className="ir-audio-container">
                <audio controls>
                    <source src={data.audio} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            </div>
        );
}

    if (data.messageType === 'question' && data.question) {
        renderContent = (
            <div className="question-preview">
                {data.question.images?.length > 0 && (
                    <img
                        src={data.question.images[0]}
                        alt="Question Image"
                        style={{ height: options.height, width: 'auto' }}
                    />
                )}
                <p className="question-message">
                    <strong>Question:</strong> {data.question.message}
                </p>
            </div>
        );
    }

    if (data.messageType === 'poll') {
        renderContent = (
            <div className="poll-preview">
                <i className="bi bi-bar-chart"></i> <span>Poll</span>
            </div>
        );
    }

    return <div className="asset-render">{renderContent}</div>;
};

export default MiniAssetRender;

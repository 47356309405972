import React, { useEffect, useState } from "react";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import "./ImageEditorModal.scss";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";

const ImageEditorModal = ({
  isOpen,
  onClose,
  onSave,
  uploadEditedImage,
  closeImageGalleryModal,
}) => {
  const { chatImageEditURL, setChatImageEditedResult } = useModalContext();
  const [editedImage, setEditedImage] = useState(null);
  const [isImageSaved, setIsImageSaved] = useState(false);

  useEffect(() => {
    if (chatImageEditURL) {
      fetchImageAsBlob(chatImageEditURL);
    }
  }, [chatImageEditURL]);

  // Fetch the image as a Blob from the given URL
  // const fetchImageAsBlob = async (url) => {
  //     try {
  //         const response = await fetch(url);
  //         const blob = await response.blob();
  //         const blobUrl = URL.createObjectURL(blob);
  //         setEditedImage(blobUrl);
  //         setIsImageSaved(false);
  //     } catch (error) {
  //         console.error("Error fetching image as blob:", error);
  //     }
  // };

  const fetchImageAsBlob = async (url) => {
    try {
      const response = await fetch(url, {
        headers: {
          "Cache-Control": "no-cache",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      setEditedImage(blobUrl);
      setIsImageSaved(false);
    } catch (error) {
      console.error("Error fetching image as blob:", error);
    }
  };

  // Reset the image editor to clear the current edited image
  const resetEditor = () => {
    setEditedImage(null);
    setIsImageSaved(false);
  };

  // Handle save button in FilerobotImageEditor
  const handleSave = (editedResult) => {
    const blobUrl = editedResult.imageBase64;
    setChatImageEditedResult(blobUrl);
    onSave(blobUrl);
    // resetEditor();
    setIsImageSaved(true);
  };

  return (
    <div
      className={`image-edit-chat-modal ${
        isOpen ? "image-edit-chat-modal--open" : ""
      }`}
    >
      <button
        className="image-edit-chat-modal__close-btn"
        onClick={() => {
          onClose();
          resetEditor();
        }}
      >
        X
      </button>

      <div className="image-edit-chat-modal__content">
        {/* Close button for the modal */}

        {/* Send button to upload the edited image */}

        <button
          className="image-edit-chat-upload-btn"
          onClick={() => {
            uploadEditedImage();
            resetEditor();
            onClose();
            closeImageGalleryModal();
          }}
          disabled={!isImageSaved}
        >
          Send
        </button>

        {/* Filerobot Image Editor */}
        {editedImage && (
          <FilerobotImageEditor
            source={editedImage}
            onSave={(editedResult) => handleSave(editedResult)}
            // onClose={() => {
            //   onClose();
            //   resetEditor(); // Clear the editor on close
            // }}
            defaultSavedImageType="jpeg" // Always save as JPEG
            defaultSavedImageQuality={1} // Set quality to 80%
            Pen={{
              selectAnnotationAfterDrawing: true,
            }}
            tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
            Rotate={{ angle: 90, componentType: "slider" }}
            Crop={{ noPresets: true, ratio: "custom" }}
          />
        )}
      </div>
    </div>
  );
};

export default ImageEditorModal;

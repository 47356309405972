import React, { useState, useEffect, useContext } from "react";
import axios from "../../api/axios";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./MyProfile.scss";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import UpdateUserModal from "./UpdateUserModal";
import Swal from "sweetalert2";
import PurchaseHistory from "./PurchaseHistory";
import { useNavigate } from "react-router-dom";
import ProfilePicUpdate from "./ProfilePicUpdate";
import { RootStore } from "../../store/store";
import { Link } from "react-router-dom";
import FavoriteCourses from "./FavoriteCourses";
import FavoriteCases from "./FavoriteCases";

const MyProfile = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("jwtTokenApi1");
  const {
    sendVerifyEmailLink,
    userTransactionData,
    courseTransactionDetails,
    userId,
  } = useModalContext();
  const [userData, setUserData] = useState(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [updateProfilePicModal, setUpdateProfilePicModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [updateProfilePic, setUpdateProfilePic] = useState(null);
  const [profileThumbnail, setProfileThumbnail] = useState("");
  const [updateProfileInputData, setUpdateProfileInputData] = useState({});
  const [store, setStore] = useContext(RootStore);
  const [isLoading, setIsLoading] = useState(false);
  const [tenCourseTransactions, setCoursetransactions] = useState([]);
  const [latestCourseTransaction, setlatestCourseTransaction] = useState();
  const [latestTransaction, setLatestTransaction] = useState({
    productName: "",
    status: "",
  });
  const [allLatestTransaction, setAllLatestTransaction] = useState([]);
  const [favoriteCourses, setFavoriteCourses] = useState([]);
  const [accordianOpen, setAccordianOpen] = useState({});
  const { userStore } = store;

  const [caseTransactions, setCaseTransactions] = useState([]);
  const [latestCaseTransaction, setLatestCaseTransaction] = useState();

  const handleOpenUpdateUserModal = () => {
    setUpdateUserModal(true);
  };
  if (!isLoggedIn) {
    navigate("/signin");
  }

  function getInitials(name) {
    const words = name?.split(" ");
    const initials = words?.map((word) => word.charAt(0).toUpperCase());
    const initialsString = initials?.join("");
    return initialsString;
  }

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          "https://backend.ir4u.info/api/v1/user/profile",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );

         // Save profile data in userStore
      userStore.profile = response?.data;
        console.log("User profile from my account", response.data);
        setUserData(response?.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getUserProfile();
  }, [updateUserModal]);

  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";

  function convertUTCToLocalAndFormat(utcTimeString) {
    if (utcTimeString) {
      const utcDateTime = new Date(utcTimeString);

      const localTimeZoneOffset = new Date().getTimezoneOffset();

      const localDateTime = new Date(
        utcDateTime.getTime() - localTimeZoneOffset * 60000
      );

      const localTimeFormat = localDateTime.toISOString().slice(0, 16);
      const inputDate = new Date(localTimeFormat);
      const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const outputString = inputDate.toLocaleString("en-US", options);

      return outputString;
    } else {
      return;
    }
  }
  const fetchFavoriteCourses = async () => {
    try {
      const response = await axios.get(
        `https://courses.ir4u.info/api/favorites/${userId}`
      );
      setFavoriteCourses(response.data);
      console.log("All fav courses:", response.data);
    } catch (error) {
      console.error("Error fetching favorite courses:", error);
    }
  };

  useEffect(() => {
    fetchFavoriteCourses();
  }, [userId]);

  const checkoutHandler = async (userId, courseId) => {
    const data = {
      userID: userId,
      CourseID: courseId,
      userEmail: userData.email,
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/failed`,
    };
    console.log("Data from checkout:", data);

    const token = localStorage.getItem("jwtTokenApi1");

    try {
      const response = await axios.post(
        "https://courses.ir4u.info/api/course/create-checkout-session",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        const sessionUrl = response.data.paymentDetails.sessionURL;
        window.location.href = sessionUrl;
      }
    } catch (error) {
      console.log(error, "error while buying the course");
    }
  };

  const handleAccordianToggle = (courseId) => {
    setAccordianOpen((prevOpen) => ({
      ...prevOpen,
      [courseId]: !prevOpen[courseId] || false,
    }));
  };
  const updateProfilePicHandle = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      setUpdateProfilePic(imageUrl);
      setUpdateProfilePicModal(true);
    }
    setSelectedFile(selectedFile);
  };
  const updateProfilePicCloseModalHandle = () => {
    setSelectedFile([]);
    setUpdateProfilePic("");
    setProfileThumbnail("");
    setUpdateProfilePicModal(false);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

const upldateProfileSubmitFormHandle = async (e) => {
    e.preventDefault();

    // console.log('userId', userId);

    // Create payload with updated profile fields
    const updateProfilePayload = {
      firstname: updateProfileInputData.firstname || userStore.profile.firstname,
      lastname: updateProfileInputData.lastname || userStore.profile.lastname,
      email: updateProfileInputData.email || userStore.profile.email,
      password: updateProfileInputData.password || "",
    };

    // Set loading state
    setIsLoading(true);

    try {
      // API call to update profile
      const response = await axios({
        url: `https://backend.ir4u.info/api/v1/user/${userId}`,
        method: "PUT",
        data: updateProfilePayload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
        },
      });

      // Success handling
      if (response?.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Your profile has been updated.",
          icon: "success",
        });

        // Update the profile in the store with new data
        setStore({
          ...store,
          userStore: {
            ...userStore,
            profile: {
              ...userStore.profile,
              ...updateProfilePayload,
            },
          },
        });

        // Clear the input data after successful update
        setUpdateProfileInputData({});
        setUpdateProfilePicModal(false);
      }
    } catch (error) {
      console.error("Error updating profile:", error.message);
      Swal.fire({
        title: "Error!",
        text: "There was an issue updating your profile. Please try again.",
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    const fetchTransactionDetails = async () => {
      if (userId) {
        try {
          const transactionDetails = await courseTransactionDetails(userId);
          const firstTenTransactions = transactionDetails.slice(0, 10);
          setCoursetransactions(firstTenTransactions);
          setlatestCourseTransaction(transactionDetails[0]);
        } catch (error) {
          console.error("Error fetching transaction details:", error);
        }
      }
    };

    const fetchCaseTransactions = async () => {
      try {
        const token = localStorage.getItem("jwtTokenApi1");
        const response = await axios.get(
          `https://case.ir4u.info/api/v1/user/case/transaction/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const caseTransactionData = response.data;
        console.log("caseTransactionData", caseTransactionData);
        const firstTenTransactions = caseTransactionData.slice(0, 10);
        setCaseTransactions(firstTenTransactions);
        setLatestCaseTransaction(caseTransactionData[0]);
      } catch (error) {
        console.error("Error fetching case transactions:", error);
      }
    };

    fetchTransactionDetails();
    fetchCaseTransactions();
  }, [userId]);

  useEffect(() => {
    if (tenCourseTransactions.length > 0 || caseTransactions.length > 0) {
      const allTransactions = [
        ...tenCourseTransactions,
        ...userTransactionData,
        ...caseTransactions,
      ];
      allTransactions.sort(
        (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
      );
      const latestTenTransactions = allTransactions.slice(0, 10);
      setAllLatestTransaction(latestTenTransactions);
    } else {
      setAllLatestTransaction(userTransactionData);
    }
    // console.log('those are the coursetransaction', tenCourseTransactions);
    // console.log('those are the userTransaction', userTransactionData);
  }, [userTransactionData, tenCourseTransactions, caseTransactions]);

//   console.log("this is the latest 10 transactions", allLatestTransaction);

  const [lastTransaction] = userTransactionData;
//   console.log(lastTransaction);
//   console.log(latestCourseTransaction);
//   console.log("latestTransaction:", latestTransaction);

  useEffect(() => {
    if (lastTransaction && latestCourseTransaction && latestCaseTransaction) {
      const newLatest =
        new Date(lastTransaction.createdOn) >
        new Date(latestCourseTransaction.createdOn)
          ? new Date(lastTransaction.createdOn) >
            new Date(latestCaseTransaction.createdOn)
            ? lastTransaction
            : latestCaseTransaction
          : new Date(latestCourseTransaction.createdOn) >
            new Date(latestCaseTransaction.createdOn)
          ? latestCourseTransaction
          : latestCaseTransaction;
      setLatestTransaction({
        productName: newLatest.productName,
        status: newLatest.status.toUpperCase(),
      });
    } else if (lastTransaction && latestCourseTransaction) {
      const newLatest =
        new Date(lastTransaction.createdOn) >
        new Date(latestCourseTransaction.createdOn)
          ? lastTransaction
          : latestCourseTransaction;
      setLatestTransaction({
        productName: newLatest.productName,
        status: newLatest.status.toUpperCase(),
      });
    } else if (lastTransaction && latestCaseTransaction) {
      const newLatest =
        new Date(lastTransaction.createdOn) >
        new Date(latestCaseTransaction.createdOn)
          ? lastTransaction
          : latestCaseTransaction;
      setLatestTransaction({
        productName: newLatest.productName,
        status: newLatest.status.toUpperCase(),
      });
    } else if (latestCourseTransaction && latestCaseTransaction) {
      const newLatest =
        new Date(latestCourseTransaction.createdOn) >
        new Date(latestCaseTransaction.createdOn)
          ? latestCourseTransaction
          : latestCaseTransaction;
      setLatestTransaction({
        productName: newLatest.productName,
        status: newLatest.status.toUpperCase(),
      });
    } else if (lastTransaction) {
      setLatestTransaction({
        productName: lastTransaction.productName,
        status: lastTransaction.status.toUpperCase(),
      });
    } else if (latestCourseTransaction) {
      setLatestTransaction({
        productName: latestCourseTransaction.productName,
        status: latestCourseTransaction.status.toUpperCase(),
      });
    } else if (latestCaseTransaction) {
      setLatestTransaction({
        productName: latestCaseTransaction.productName,
        status: latestCaseTransaction.status.toUpperCase(),
      });
    }
  }, [lastTransaction, latestCourseTransaction, latestCaseTransaction]);

  return (
    <>
      <Header />
      <div className="ir-profile-main-container">
        <div className="ir-profile-inner-main-container">
          <div className="ir-ws-padding-lr-132 ir-profile-header-main-container">
            <div className="ir-profile-header-inner-container">
              <div className="ir-profile-head-title-container">
                <h3 className="ir-ws-app-color ir-ws-text-capitalize ir-ws-font-700">
                  My Account
                </h3>
              </div>
              <div className="ir-profile-head-card-main-container ir-ws-position-relative">
                <div className="ir-profile-head-card-inner-container ir-ws-flex">
                  <div className="ir-profile-pic-container">
                    <div className="ir-profile-head-details-container ir-ws-position-absolute">
                      <div className="ir-profile-head-name">
                        {userData?.firstname} {userData?.lastname}
                      </div>
                      <div className="ir-profile-head-email">
                        {userData?.email}
                      </div>
                    </div>
                  </div>

                  <div className="ir-profile-head-letter-container">
                    <div className="ir-profile-head-letter">
                      {getInitials(userData?.firstname)}
                      {getInitials(userData?.lastname)}
                    </div>
                  </div>
                </div>


                  <div className="ir-profile-pic-inner-container ir-ws-position-absolute">
                      <img
                        src={require("../../assets/images/profileDP.png")}
                        className="ir-profile-pic"
                        alt="Profile pic"
                      />
                  </div>

                {/* {!isLoading && (
                  <div className="ir-profile-pic-inner-container ir-ws-position-absolute">
                    {userStore?.profile?.image === "" ? (
                      <img
                        src={require("../../assets/images/profileDP.png")}
                        className="ir-profile-pic"
                        alt="Profile pic"
                      />
                    ) : (
                      <img
                        // src={require("../../assets/images/profileDP.jpeg")}
                        src={userStore?.profile?.image}
                        className="ir-profile-pic"
                        alt="Profile pic"
                      />
                    )}
                  </div>
                )} */}

                {!isLoading && (
                  <div className="avatar-xs p-0 rounded-circle profile-photo-edit ir-ws-myprofile-pencil-container">
                    <input
                      id="profile-img-file-input"
                      type="file"
                      className="ir-ws-myprofile-input"
                      accept="image/png, image/jpeg"
                      name="file"
                      onChange={updateProfilePicHandle}
                    />
                    <label
                      htmlFor="profile-img-file-input"
                      className="profile-photo-edit avatar-xs form-label ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-ws-profile-pic-label"
                    >
                      <span className="avatar-title rounded-circle bg-light text-body ir-ws-profile-pic-span">
                        <i className="bi bi-camera ir-ws-myprofile-pencil-icon"></i>
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="ir-ws-padding-lr-132 ir-ws-padding-tb-50 ir-profile-info-details-main-container">
            <div className="ir-profile-info-details-inner-container ir-ws-flex">
              <div className="ir-profile-acc-details-main-container">
                <h3 className="ir-ws-app-color ir-profile-info-heading">
                  Account Details
                </h3>
                <div className="ir-profile-info-details-container ir-ws-flex">
                  <div className="ir-profile-info-content">
                    <h4 className="ir-profile-content-heading"> Username</h4>
                    <div className="ir-profile-content">
                      {userData?.username}
                    </div>
                    <h4 className="ir-profile-content-heading">Full Name</h4>
                    <div className="ir-profile-content">
                      {userData?.firstname} {userData?.lastname}
                    </div>
                    <h4 className="ir-profile-content-heading">Email</h4>
                    <div className="ir-profile-content"> {userData?.email}</div>
                  </div>

               {userData?.isEmailVerified ? (
                    <p style={{ marginTop: '10px' }}>Email Verified</p>
                    ) : (
                    <button
                        style={{ marginTop: '10px' }}
                        className="ir-ws-app-color ir-ws-verify-email-text"
                        onClick={() => {
                        sendVerifyEmailLink(userData?.username);
                        }}
                    >
                        Verify Email
                    </button>
                    )}


                  {!isAdmin && userData !== null && (
                    <div className="ir-ws-default-btn-container ir-profile-info-btn-container">
                      <button
                        className="ir-ws-default-btn ir-ws-app-bg"
                        onClick={handleOpenUpdateUserModal}
                      >
                        <span>Update Accounts Details</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="ir-profile-acc-details-main-container">
                <h3 className="ir-ws-app-color ir-profile-info-heading">
                  Billing Information
                </h3>
                <div className="ir-profile-info-details-container ir-ws-flex">
                  <div className="ir-profile-info-content">
                    <h4 className="ir-profile-content-heading">Address</h4>
                    <div className="ir-profile-content">
                      4600 Encino Avenue, Los Angeles, <br />
                      91356, California, <br />
                      United States.
                    </div>
                  </div>
                  <div className="ir-ws-default-btn-container ir-profile-info-btn-container">
                    <button className="ir-ws-default-btn ir-ws-app-bg">
                      <span>Update Billing Details</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="ir-profile-acc-details-main-container">
                <h3 className="ir-ws-app-color ir-profile-info-heading">
                  Current Transaction Status
                </h3>
                <div className="ir-profile-info-details-container ir-ws-flex">
                  <div className="ir-profile-info-content">
                    <h4 className="ir-profile-content-heading">Product Name</h4>
                    {/* <div className="ir-profile-content">
                      {latestTransaction?.productName || "Loading..."}
                    </div> */}
                    <div className="ir-profile-content">
                      {allLatestTransaction[0]?.productName ||
                        "No Product purchased"}
                    </div>
                  </div>
                  {/*
                  <div>
                    <p>Transaction Status</p> */}
                  {/* <div>

                  </div> */}
                  <div className="ir-ws-default-btn-container ir-profile-info-btn-container">
                    <p>Transaction Status</p>
                    <div
                      className={`ir-ws-profile-status-container ${
                        allLatestTransaction[0]?.status.toLowerCase() ===
                        "success"
                          ? "success-status-bg"
                          : allLatestTransaction[0]?.status.toLowerCase() ===
                            "pending"
                          ? "pending-status-bg"
                          : allLatestTransaction[0]?.status.toLowerCase() ===
                            "cancelled"
                          ? "cancel-status-bg"
                          : ""
                      }`}
                    >
                      {/* <p> {latestTransaction?.status || "Loading..."}</p> */}
                      <p> {allLatestTransaction[0]?.status || "Loading..."}</p>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="ir-ws-padding-lr-132 ir-profile-purchasing-main-container">
            <div className="ir-ws-transaction-inner-container">
              <h3 className="ir-ws-app-color ir-transactions-heading">
                Transactions
              </h3>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Tax Invoice Number</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Download</th>
                  </tr>
                </thead>
                <tbody>
                  {allLatestTransaction?.map((transac) => (
                    <tr key={transac.transactionId || transac.transactionID}>
                      <td>{convertUTCToLocalAndFormat(transac.updatedOn)}</td>
                      <td>{transac.transactionId || transac.transactionID}</td>
                      <td>{transac.productName}</td>
                      {transac.productPaymentDetails?.price || transac.price ? (
                        <td>
                          {transac.productPaymentDetails?.price ||
                            transac.price}
                        </td>
                      ) : (
                        <td>null</td>
                      )}
                      <td
                        className={
                          transac.status.toLowerCase() === "success"
                            ? "success-status"
                            : transac.status.toLowerCase() === "pending"
                            ? "pending-status"
                            : "failed-status"
                        }
                      >
                        {transac.status}
                      </td>
                      {transac.invoiceURL || transac.invoiceUrl ? (
                        <td>
                          <a
                            href={transac.invoiceURL || transac.invoiceUrl}
                            className="ir-ws-profile-invoice-text"
                          >
                            Invoice
                          </a>
                        </td>
                      ) : (
                        <td>No Data</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="ir-ws-padding-lr-132  ir-ws-padding-tb-50 ir-profile-transaction-main-container">
            <PurchaseHistory />
          </div>

          {/* Favorite Courses */}
          <div className="ir-ws-purchase-history-conatiner ir-ws-padding-lr-132 mb-3r">
            <p className="ir-ws-purchase-history-title-text">
              Favorite Courses
            </p>
            {!isLoading && (
              <div className="ir-ws-purchase-history-box">
                <ul className="ir-ws-purchase-history-title-container ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                  <li>Title</li>
                  <li>Instructor name</li>
                  <li>more details</li>
                </ul>
                <div className="ir-ws-purchase-history-details-container">
                  {favoriteCourses?.map((course) => (
                    <div
                      className="ir-ws-purchase-history-details-box"
                      key={course.courseId}
                    >
                      <ul className="ir-ws-purchase-history-title-details-container ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                        <li>{course?.courseDetails?.formData?.title}</li>
                        <li>
                          {course?.courseDetails?.formData?.instructorName}
                        </li>
                        <li
                          onClick={() => handleAccordianToggle(course.courseId)}
                        >
                          <span
                            className={`${
                              !accordianOpen[course.courseId]
                                ? "ir-ws-purchase-accordian-dropdown-inactive"
                                : "ir-ws-purchase-accordian-dropdown-active"
                            }`}
                          >
                            {!accordianOpen[course.courseId] ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="ir-wws-purchase-history-dropdown"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="ir-wws-purchase-history-dropdown"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                                />
                              </svg>
                            )}
                          </span>
                        </li>
                      </ul>
                      {accordianOpen[course.courseId] && (
                        <>
                          <ul className="ir-ws-purchase-history-accordian-title ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                            <li>Course Price</li>
                            {/* <li>Status</li> */}
                            <li>Purchase Link</li>
                            <li>Course Link</li>
                          </ul>
                          <ul className="ir-ws-purchase-history-accordian-details ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                            <li>
                              {"$" +
                                " " +
                                course?.courseDetails?.formData?.coursePrice}
                            </li>
                            <li
                              onClick={() =>
                                checkoutHandler(course.userId, course.courseId)
                              }
                              className="ir-ws-purchase-history-copy-passcode-conatiner ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-fav-case-buy-link"
                            >
                              Buy Now
                            </li>
                            <li className="ir-ws-purchase-history-copy-passcode-conatiner ir-ws-flex ir-ws-justify-center ir-ws-align-center">
                              <Link to={`/course/${course.courseId}`}>
                                View Course
                              </Link>
                            </li>
                          </ul>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="ir-ws-padding-lr-132  ir-ws-padding-tb-50 ir-profile-transaction-main-container">
            <FavoriteCases />
          </div>

          <div className="ir-profile-footer"></div>
        </div>
      </div>

      <Footer />
      {updateUserModal && (
        <UpdateUserModal
          updateUserModal={updateUserModal}
          setUpdateUserModal={setUpdateUserModal}
        />
      )}
      {updateProfilePicModal && (
        <ProfilePicUpdate
          updateProfilePic={updateProfilePic}
          updateProfilePicCloseModalHandle={updateProfilePicCloseModalHandle}
          upldateProfileSubmitFormHandle={upldateProfileSubmitFormHandle}
          setUpdateProfilePicModal={setUpdateProfilePicModal}
          setProfileThumbnail={setProfileThumbnail}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default MyProfile;
